import { loadImage } from '@training/utils/loadImage'
import { twMerge } from 'tailwind-merge'
import { useCallback, useEffect, useState } from 'react'

interface FitImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  minWidth: number
  className?: string
  minHeightClassName?: string
}

/**
 * Renders an image that fits within the specified minimum width and height.
 * If the image is smaller than the minimum width, it will have a minimum height applied.
 * @param {string} props.src - The source URL for the image.
 * @param {number} props.minWidth - The minimum width for the image.
 * @param {string} [props.minHeightClassName='min-h-[70%]'] - The class name for the minimum height of the image.
 * @returns {JSX.Element} - The rendered image element.
 */
export const FitImage = ({
  src,
  minWidth,
  className,
  minHeightClassName = 'min-h-[70%]',
  alt,
  ...rest
}: FitImageProps) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 })

  const prepareImage = useCallback(async () => {
    const image = await loadImage(src, false)

    setImageDimensions({
      width: image.width,
      height: image.height,
    })
  }, [src])

  useEffect(() => {
    prepareImage()
  }, [prepareImage])

  const isLoaded = imageDimensions.width > 0 && imageDimensions.height > 0

  return isLoaded ? (
    <img
      src={src}
      className={twMerge(
        `${imageDimensions.width < minWidth ? minHeightClassName : ''}`,
        className
      )}
      alt={alt}
      {...rest}
    />
  ) : null
}

interface AnnotationImageProps {
  thumbnail: string
  stackedEffect: boolean
  handleAnnotationCardClick?: () => void
  className?: string
}
const AnnotationImage = ({
  thumbnail,
  stackedEffect,
  handleAnnotationCardClick,
  className,
}: AnnotationImageProps) =>
  stackedEffect ? (
    <div
      role='button'
      onClick={() => handleAnnotationCardClick?.()}
      onKeyDown={() => handleAnnotationCardClick?.()}
      tabIndex={0}
      aria-label='annotation-thumbnail-stacked'
      className={twMerge(`grid place-items-center w-full relative h-[198px]`, className)}
      data-testid='annotation-thumbnail-stacked'
    >
      <FitImage
        className='m-0 hover:cursor-pointer object-contain drop-shadow-[0_6px_3px_rgba(0,0,0,0.3)] p-2 bg-white max-h-[198px] max-w-full stacked-paper'
        src={thumbnail}
        minWidth={180}
        alt=''
      />
    </div>
  ) : (
    <div
      role='button'
      onClick={() => handleAnnotationCardClick?.()}
      onKeyDown={() => handleAnnotationCardClick?.()}
      tabIndex={0}
      aria-label='annotation-thumbnail'
      className={className}
    >
      <img
        data-testid='annotation-thumbnail'
        src={thumbnail}
        alt=''
        className='hover:cursor-pointer h-48 object-contain w-full'
      />
    </div>
  )

export default AnnotationImage
