import {
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
  TextArea,
  TextInput,
  Typography,
  useSnackbar,
} from '@htaic/cue'
import { useCreateProject, useUpdateProject } from '@training/apis/projects/requests'
import { projectFormCharLimits } from '@training/constants'
import { getApiError } from '@training/helpers/getApiErrors'
import charsLeft from '@training/utils/charsLeft'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { CreateProject, Project, UpdateProject } from '@training/types'
import { useState, useMemo, useCallback, useEffect } from 'react'

interface ProjectModalProps {
  title?: string
  onClose: () => void
  onProjectSave?: (id: string) => void
  project?: Partial<Project>
  isUpdate?: boolean
  isNoteUpdate?: boolean
}

type ErrorMessages = { [key: string]: string } | undefined

const ProjectModal = ({
  title,
  onClose,
  onProjectSave,
  project,
  isUpdate = false,
  isNoteUpdate = false,
}: ProjectModalProps) => {
  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const { mutateAsync: createProject, isPending: isLoadingCreate } = useCreateProject()
  const { mutateAsync: updateProject, isPending: isLoadingUpdate } = useUpdateProject()
  const [projectName, setProjectName] = useState<string>(project?.name ?? '')
  const [projectNotes, setProjectNotes] = useState<string>(project?.notes ?? '')
  const [projectObjectName, setProjectObjectName] = useState<string | null>(
    project?.version?.[0].objClasses?.[0]?.objectClass.name ?? null
  )

  const projectNameCharsLeft = charsLeft(projectName.length, projectFormCharLimits.nameLimit)
  const projectNotesCharsLeft = charsLeft(projectNotes.length, projectFormCharLimits.notesLimit)

  const projectObjectNameCharsLeft = charsLeft(
    projectObjectName?.length ?? projectName.length,
    projectFormCharLimits.objectNameLimit
  )

  const [projectCreateUpdateError, setProjectCreateUpdateError] = useState<string>('')

  const showSnackBar = useSnackbar()

  const formatForObjectName = useCallback((value: string) => {
    return value
      .slice(0, projectFormCharLimits.objectNameLimit)
      .replace(/["'!@#$%^&*()<>|;/_+ ]/gi, '')
  }, [])

  useEffect(() => {
    if (project?.name) {
      setProjectName(project?.name)
    }
    if (project?.name && !project?.version?.[0].objClasses?.[0]?.objectClass.name) {
      setProjectObjectName(formatForObjectName(project?.name) ?? null)
    }
  }, [formatForObjectName, project?.name, project?.version])

  const projectApiErrors: ErrorMessages = useMemo(
    () => ({
      'The project name already exists': `Names must be unique. ${projectName} has already been taken, please choose a different name for your project`,
      'Uniqueness violation': `Names must be unique. ${projectName} has already been taken, please choose a different name for your project`,
      'name should not be empty': `Name cannot be empty`,
    }),
    [projectName]
  )

  const onCloseHandler = useCallback(() => {
    setProjectName(project?.name ?? '')
    setProjectNotes(project?.notes ?? '')
    setProjectObjectName(project?.version?.[0].objClasses?.[0]?.objectClass.name ?? null)
    onClose()
  }, [onClose, project?.name, project?.notes, project?.version])

  const handleNewProject = async () => {
    if (projectName.trim() === '') return
    const createProjectData: CreateProject = {
      name: projectName.trim(),
      notes: projectNotes,
      objectClassName: formatForObjectName(projectObjectName ?? projectName),
      createdBy: userId,
      orgId,
    }

    setProjectCreateUpdateError('')

    try {
      const res = await createProject(createProjectData)
      onClose()
      onProjectSave?.(res.id)
    } catch (error: any) {
      setProjectCreateUpdateError(getApiError(error.response.data.message, projectApiErrors))
    }
  }

  const handleUpdateProject = async () => {
    if (!project?.id) return
    if (projectName.trim() === '') return
    const updateProjectData: UpdateProject = {
      id: project.id,
      name: projectName.trim(),
      notes: projectNotes,
      objectClassName: formatForObjectName(projectObjectName ?? projectName),
      updatedBy: userId,
      orgId,
    }

    setProjectCreateUpdateError('')

    try {
      await updateProject(updateProjectData)
      onClose()
      showSnackBar({ message: 'Project updated.', status: 'success' })
    } catch (error: any) {
      console.error('Error updating project.', error)
      setProjectCreateUpdateError(getApiError(error.response.data.message, projectApiErrors))
    }
  }

  const handleProjectNameChange = useCallback((value: string) => {
    setProjectName(value)
  }, [])

  const handleObjectNameChange = useCallback(
    (value: string) => {
      const objectClassName = formatForObjectName(value)
      setProjectObjectName(objectClassName ?? null)
    },
    [formatForObjectName]
  )

  const handleObjectNameBlur = () => {
    handleObjectNameChange(projectObjectName ?? projectName)
  }

  return (
    <ModalContent data-testid='newProject-modal'>
      <ModalHeader closable>{title ?? project?.name}</ModalHeader>
      <div className='p-2 text-sm font-normal'>
        {!isNoteUpdate ? (
          <>
            <div className='flex justify-end mb-1'>
              <Typography variant='body2' className='text-graph-red'>
                * Required
              </Typography>
            </div>
            <div className='grid grid-cols-12 gap-2'>
              <Typography variant='body2' className='row-span-2 col-span-2 w-full text-left'>
                <span className='text-graph-red'>*</span>
                Name:
              </Typography>
              <div className='col-span-10'>
                <TextInput
                  data-testid='textInput-name'
                  containerClassName='bg-neutral-grey-15'
                  className='bg-neutral-grey-15'
                  onChange={(e) => {
                    handleProjectNameChange(e.target.value)
                    if (projectCreateUpdateError !== '') setProjectCreateUpdateError('')
                  }}
                  value={projectName}
                  maxLength={projectFormCharLimits.nameLimit}
                />
                <Typography
                  variant='body2'
                  className='text-neutral-grey-10 dark:text-neutral-grey-7 mt-1'
                >
                  {projectNameCharsLeft} characters left.
                </Typography>
              </div>
            </div>
          </>
        ) : null}
        <div className='grid grid-cols-12 gap-5'>
          <Typography variant='body2' className='row-span-2 col-span-2 w-full text-left'>
            Notes:
          </Typography>
          <div className='col-span-10'>
            <TextArea
              data-testid='textInput-notes'
              className='resize-none h-[120px] border border-solid border-neutral-grey-13'
              onChange={(e) => {
                setProjectNotes(e.target.value)
                if (projectCreateUpdateError !== '') setProjectCreateUpdateError('')
              }}
              value={projectNotes}
              maxLength={projectFormCharLimits.notesLimit}
            />
            <Typography variant='body2' className='text-neutral-grey-10 dark:text-neutral-grey-7'>
              {projectNotesCharsLeft} characters left.
            </Typography>
          </div>
        </div>
        {!isNoteUpdate ? (
          <>
            <div className='flex flex-col items-center mb-3'>
              <Typography
                variant='body2'
                className='w-full text-neutral-grey-10 dark:text-neutral-grey-7'
              >
                Detected objects will show up on your VMS with the following name.
              </Typography>
            </div>
            <div className='grid grid-cols-12 gap-5'>
              <Typography variant='body2' className='col-span-2 w-full text-left'>
                Object name:
              </Typography>
              <div className='col-span-10'>
                <TextInput
                  data-testid='textInput-object-name'
                  containerClassName='bg-neutral-grey-15'
                  className='bg-neutral-grey-15'
                  onChange={(e) => {
                    handleObjectNameChange(e.target.value)
                    if (projectCreateUpdateError !== '') setProjectCreateUpdateError('')
                  }}
                  onBlur={handleObjectNameBlur}
                  value={
                    isUpdate
                      ? (projectObjectName ?? '')
                      : formatForObjectName(projectObjectName ?? projectName)
                  }
                  maxLength={projectFormCharLimits.objectNameLimit}
                />
                <Typography
                  variant='body2'
                  className='text-neutral-grey-10 dark:text-neutral-grey-7 mt-1'
                >
                  {projectObjectNameCharsLeft} characters left.
                </Typography>
              </div>
            </div>
          </>
        ) : null}
        {projectCreateUpdateError !== '' ? (
          <div className='flex items-center text-neutral-grey-3'>{projectCreateUpdateError}</div>
        ) : null}
      </div>
      <ModalFooter className='flex justify-center gap-2'>
        <Button
          data-testid='close-modal-button'
          variant='outlined'
          color='neutral'
          onClick={onCloseHandler}
        >
          Cancel
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={projectName.trim() === '' || isLoadingCreate || isLoadingUpdate}
          onClick={isUpdate ? handleUpdateProject : handleNewProject}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default ProjectModal
