import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios'
import { VideoFilePayload, VideoUploadPayload } from '@training/apis/types'
import { VideoFile } from '@training/types'
import { endpoints } from '@training/constants'

export const assetsApiUrl = endpoints.VITE_ASSETS_API ?? 'http://localhost:3015'

export function useGetProjectVideos(projectId: string | undefined) {
  const getData = async () => {
    const { data } = await axios.get<VideoFile[]>(`${assetsApiUrl}/video-files/v1`, {
      params: { projectId },
    })

    return data
  }

  return useQuery({
    queryKey: ['getProjectVideos', projectId],
    queryFn: () => getData(),
    enabled: !!projectId,
  })
}

export function useGetVideoThumbnails(videoId: string | undefined) {
  const getData = async () => {
    const { data } = await axios.get<VideoFile>(`${assetsApiUrl}/video-files/v1/${videoId}`)
    return data
  }

  return useQuery({
    queryKey: ['getVideoThumbnails', videoId],
    queryFn: () => getData(),
    enabled: !!videoId,
  })
}

export function useUpdateVideo() {
  const updateVideo = async (payload: VideoFilePayload) => {
    const { data } = await axios.patch<VideoFile>(`${assetsApiUrl}/video-files/v1/${payload.id}`, {
      fileName: payload.fileName,
      updatedBy: payload.updatedBy,
    })
    return data
  }
  return useMutation({
    mutationKey: ['updateVideo'],
    mutationFn: (payload: VideoFilePayload) => updateVideo(payload),
    onError: (err: AxiosError) => err,
  })
}

export function useDeleteVideo() {
  const deleteVideo = async (params: { id: string }) => {
    const { data } = await axios.delete(`${assetsApiUrl}/video-files/v2/${params.id}`)
    return data
  }
  return useMutation({
    mutationKey: ['deleteVideo'],
    mutationFn: (params: { id: string }) => deleteVideo(params),
    onError: (err: AxiosError) => err,
  })
}

export function useFileUpload() {
  const getData = async (payload: VideoUploadPayload) => {
    const { organizationId, projectId, createdBy, datasetType, file, progressCallback } = payload
    const formData = new FormData()

    formData.append('organizationId', organizationId)
    formData.append('projectId', projectId)
    formData.append('createdBy', createdBy)
    formData.append('datasetType', datasetType)
    formData.append('file', file)

    const { data } = await axios.post<VideoFile, AxiosResponse<VideoFile>, FormData>(
      `${assetsApiUrl}/video-files/v1`,
      formData,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressCallback) {
            progressCallback(progressEvent)
          }
        },
      }
    )
    return data
  }

  return useMutation({
    mutationKey: ['useFileUpload'],
    mutationFn: (payload: VideoUploadPayload) => getData(payload),
  })
}
