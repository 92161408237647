import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import {
  Button,
  Typography,
  SearchBox,
  Icon,
  ScrollBar,
  Loader,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from '@htaic/cue'
import ProjectList from '@training/pages/ProjectsList/ProjectsList'
import ProjectModal from '@training/pages/ProjectsList/ProjectModal'
import { projectsSortOptions } from '@training/constants'
import { debounce, isEmpty, orderBy } from 'lodash'
import { useAppState } from '@training/hooks/useAppState'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { useGetProjects } from '@training/apis/projects/requests'
import { useModalState } from '@training/hooks/useModal'
import { useNavigate } from 'react-router-dom'

const DEFAULT_SORT_OPTION = projectsSortOptions[0]

const Projects = () => {
  const orgId = useMinimeState((state) => state.orgId)

  const getProjects = useGetProjects(orgId, {
    enabled: false,
  })
  const projectsSubscription = useMemo(() => getProjects.data ?? [], [getProjects.data])

  const createProjectFF = useFeatureFlags().CREATE_PROJECT

  const refetchProjectsRef = useRef(false)
  useEffect(() => {
    if (refetchProjectsRef.current) return
    getProjects.refetch()
    refetchProjectsRef.current = true
  }, [getProjects])

  const {
    projectsSortingOption,
    setProjectsSortingOption,
    projectsSearchQuery: searchText,
    setProjectsSearchQuery,
  } = useAppState((state) => ({
    projectsSortingOption: state.projectsSortingOption,
    setProjectsSortingOption: state.setProjectsSortingOption,
    projectsSearchQuery: state.projectsSearchQuery,
    setProjectsSearchQuery: state.setProjectsSearchQuery,
  }))

  const [filter, setFilter] = useState(searchText)

  const filteredProjects = useMemo(
    () =>
      filter
        ? projectsSubscription.filter((project) =>
            project.name.toLowerCase().includes(filter.toLowerCase())
          )
        : projectsSubscription,
    [projectsSubscription, filter]
  )

  const sortOption = useMemo(
    () =>
      projectsSortOptions.find((option) => option.id === projectsSortingOption) ??
      DEFAULT_SORT_OPTION,
    [projectsSortingOption]
  )

  const filteredProjectsSorted = useMemo(() => {
    if (sortOption.sortProperty === 'updatedAt') {
      return orderBy(
        filteredProjects,
        [(filteredProject) => filteredProject.version[0]?.updatedAt],
        [sortOption.sortDirection]
      )
    }
    return orderBy(
      filteredProjects,
      [(filteredProject) => String(filteredProject[sortOption.sortProperty]).toLowerCase()],
      [sortOption.sortDirection]
    )
  }, [filteredProjects, sortOption])

  const onSortSelect = useCallback(
    (value: string) => {
      const selectedOption =
        projectsSortOptions.find(
          (option) => option.value === (value ?? DEFAULT_SORT_OPTION.value)
        )! || DEFAULT_SORT_OPTION

      setProjectsSortingOption(selectedOption.id)
    },
    [setProjectsSortingOption]
  )

  const debouncedSearch = debounce((text: string) => {
    setFilter(text)
  }, 500)

  const navigate = useNavigate()

  const saveProjectHandler = (id: string) => {
    navigate(`project/${id}`)
  }

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const openProjectModalHandler = () => {
    openModal({
      content: (
        <ProjectModal
          title='Project name and notes'
          onClose={closeModal}
          onProjectSave={(id) => saveProjectHandler(id)}
        />
      ),
      size: 'lg',
    })
  }

  return getProjects.isPending ? (
    <div
      className='flex items-center justify-center h-full grow'
      aria-live='polite'
      aria-label='Loading projects'
      aria-busy
    >
      <Loader
        size='xxxlarge'
        iconName='LoadingThin'
        className='text-primary dark:text-neutral-grey-0'
      />
    </div>
  ) : (
    <>
      <div className='py-3 px-4 flex items-start justify-between h-[56px] w-full bg-neutral-grey-15'>
        <div className='flex items-center'>
          <div className='flex items-center'>
            <Typography variant='heading2' tag='span' className='text-left text-semantic-primary'>
              Projects
            </Typography>
          </div>
        </div>
        <div className='flex items-center gap-5 align-middle'>
          <SearchBox
            containerClassName='w-[280px] dark:bg-neutral-grey-12'
            className='dark:bg-neutral-grey-12'
            placeholder='Search'
            onChange={(e) => {
              setProjectsSearchQuery(e.target.value)
              debouncedSearch(e.target.value)
            }}
            value={searchText}
            showClearTextButton
          />

          <Select onValueChange={onSortSelect} value={sortOption.value}>
            <SelectTrigger
              className='w-56'
              data-testid='sort-projects-dropdown'
              aria-label='Sort projects'
            >
              <SelectValue placeholder='Sort projects' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {projectsSortOptions.map((item) => (
                  <SelectItem key={item.id} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {createProjectFF ? (
            <Button
              color='primary'
              variant='contained'
              data-testid='create-project-button'
              className='min-w-[32px] h-8 !px-0 !leading-none'
              aria-label='Create a new project'
              onClick={openProjectModalHandler}
              disabled={!projectsSubscription}
            >
              +
            </Button>
          ) : null}
        </div>
      </div>

      <div className='p-5 pt-0 bg-neutral-grey-15' data-testid='projects-list'>
        <div className='flex flex-1 h-[calc(100vh-(3.5rem+3rem+1.75rem))] rounded border border-neutral-grey-12 align-middle justify-center'>
          <ScrollBar size='normal' className='pr-2'>
            {isEmpty(filteredProjectsSorted) && !getProjects.isPending && searchText ? (
              <div className='grow flex items-center justify-center'>
                <Typography className='text-xl text-center dark:text-semantic-secondary'>
                  No projects found matching your search criteria
                </Typography>
              </div>
            ) : null}
            {projectsSubscription?.length === 0 ? (
              <div className='flex items-center justify-center flex-col h-full'>
                <Icon
                  name='Info'
                  size='xxxlarge'
                  className='text-neutral-grey-9 dark:text-neutral-grey-0 mb-4'
                />
                <Typography
                  variant='subhead1'
                  font='secondary'
                  className='text-neutral-grey-9 dark:text-neutral-grey-0 mb-5'
                >
                  No Projects
                </Typography>
                <Button onClick={openProjectModalHandler}>Create your first project</Button>
              </div>
            ) : (
              <ProjectList projects={filteredProjectsSorted ?? []} />
            )}
          </ScrollBar>
        </div>
      </div>
    </>
  )
}

export default Projects
