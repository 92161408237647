import VideoPlayer from '@training/components/VideoPlayer'
import { ComponentProps } from 'react'

export const getVideoOptions = (source?: { url: string; type: string }) =>
  ({
    autoplay: false,
    responsive: true,
    inactivityTimeout: 0,
    controls: true,
    playbackRates: [0.1, 0.5, 1, 1.25, 1.5, 2],
    preload: 'auto',
    bigPlayButton: false,
    userActions: { doubleClick: false },
    errorDisplay: false,
    sources: source?.url
      ? [
          {
            src: source.url,
            type: source.type,
          },
        ]
      : [],
    html5: {
      nativeAudioTracks: true,
      nativeVideoTracks: true,
      vhs: {
        experimentalBufferBasedABR: true,
      },
    },
    muted: true,
  }) as ComponentProps<typeof VideoPlayer>['options']
