const useFormattedDateTime = (
  date: Date | string | undefined,
  format: Intl.DateTimeFormatOptions,
  locale = 'en-us'
) => {
  const formattedDateTime = date ? new Date(date) : new Date()
  return formattedDateTime.toLocaleString(locale, format)
}

export default useFormattedDateTime
