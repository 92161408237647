import { Button, ModalContent, ModalFooter, ModalHeader, Typography } from '@htaic/cue'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { Link } from 'react-router-dom'

export const TrainingModal = () => {
  const orgId = useMinimeState((state) => state.orgId)
  return (
    <ModalContent data-testid='training-modal'>
      <ModalHeader>Training in progress</ModalHeader>
      <Typography variant='caption' className='pt-5'>
        AI model training in progress. Once complete, it will be applied to all uploaded clips for
        review. <br /> <br />
        <strong>This process will take approximately 1 hour</strong>, depending on how many
        detection boxes are in the training collection. <br /> <br />
        While processing, you cannot work on this project but can work on other projects. You will
        be notified for review upon completion, and this window will close automatically.
      </Typography>
      <ModalFooter>
        <Link to={`/?orgId=${orgId}`}>
          <Button color='primary' variant='outlined'>
            Go to Projects
          </Button>
        </Link>
      </ModalFooter>
    </ModalContent>
  )
}
