import { downloadProjectModel } from '@training/apis/projects/requests'
import { useCallback, useMemo, useState } from 'react'
import download from 'js-file-download'
import { useSnackbar } from '@htaic/cue'
import { ProjectVersion } from '@training/types'
import { PROJECT_COMPILED_STATUS } from '@training/constants'

export const useDownloadModel = (projectVersions: ProjectVersion[]) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const showSnackBar = useSnackbar()

  const projectVersionId = projectVersions?.find(
    (version) => version.compiledStatus === PROJECT_COMPILED_STATUS.compiled
  )?.id

  const downloadHandler = useCallback(() => {
    setIsDownloading(true)
    showSnackBar({
      message: `Model file started downloading`,
      status: 'info',
    })
    downloadProjectModel(projectVersionId ?? '')
      .then((file: any) => {
        const disposition = file.headers.get('content-disposition')
        let filename = `${projectVersionId}-model.tar.gz`
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        download(file.data, `${filename}`)
        showSnackBar({
          message: `Model file downloaded successfully`,
          status: 'success',
        })
        setIsDownloading(false)
      })
      .catch((error) => {
        showSnackBar({
          message: `Model File download failed: ${error.response?.statusText ?? error.message}`,
          status: 'error',
        })
        setIsDownloading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectVersionId])
  return useMemo(() => ({ downloadHandler, isDownloading }), [downloadHandler, isDownloading])
}
