import { Button, Typography, ModalContent, ModalFooter, ModalHeader } from '@htaic/cue'
import { useEffect, useMemo, useState } from 'react'
import DarkMode from '@training/assets/dark_mode.png'
import LightMode from '@training/assets/light_mode.png'
import SyncWithBrowserMode from '@training/assets/browser_mode.png'
import { Theme, themes, useMinimeState } from '@training/hooks/useMinimeState'
import { useGetVersion } from '@training/apis/projects/requests'
import {
  update_user_user_settings_by_pk,
  flushUserCache,
  UserSettingsThemeEnum,
} from '@htaic/anaheim-graphql-library'
import { useGetAvailableLicenses } from '@training/apis/licenses/requests'
import { filter, sortBy } from 'lodash'
import dayjs from 'dayjs'
import { twMerge } from 'tailwind-merge'
import { ThemeCardApp } from '../ThemeCardApp'

interface PersonalSettingsModalProps {
  onClose: () => void
}

const Separator = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div
    className={twMerge(`h-[1px] bg-neutral-grey-15 dark:bg-neutral-grey-12 mt-8 mb-9`, className)}
    {...props}
  />
)

export const PersonalSettingsModalApp = ({ onClose }: PersonalSettingsModalProps) => {
  const [selectedTheme, setSelectedTheme] = useState<Theme>(themes.LIGHT)

  const theme = useMinimeState((state) => state.theme)
  const setTheme = useMinimeState((state) => state.setTheme)

  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const availableLicenses = useGetAvailableLicenses()

  const handleModeClick = (newTheme: Theme) => {
    setSelectedTheme(newTheme)
  }

  const handleClick = async () => {
    try {
      await update_user_user_settings_by_pk(
        { theme: selectedTheme as UserSettingsThemeEnum },
        { id: userId }
      )
      await flushUserCache()
      setTheme(selectedTheme)
      onClose()
    } catch (error) {
      console.error('Error in updating theme', error)
    }
  }

  useEffect(() => {
    setSelectedTheme(theme)
  }, [theme])

  const getVersion = useGetVersion(orgId)

  const [activeLicense] = useMemo(
    () =>
      filter(
        sortBy(availableLicenses.data, (license) => license.expiryDate),
        (license) =>
          license.licenseStatus === 'ACTIVE' &&
          dayjs().isAfter(license.activeDate) &&
          dayjs().isBefore(license.expiryDate)
      ),
    [availableLicenses.data]
  )

  const activeLicenseDaysLeft = useMemo(
    () => dayjs(activeLicense?.expiryDate).diff(dayjs().startOf('day'), 'day'),
    [activeLicense]
  )

  return (
    <ModalContent data-testid='personal-settings-modal'>
      <ModalHeader closable>Personal settings</ModalHeader>
      <div className='flex flex-col pt-5'>
        <div className='grid grid-cols-[9.5rem,1fr] gap-x-14'>
          <Typography>Color mode</Typography>
          <div className='flex justify-between gap-x-3'>
            <ThemeCardApp
              selected={selectedTheme === themes.DARK}
              themeSrc={DarkMode}
              themeName='Dark mode'
              themeMode={themes.DARK}
              handleClick={() => handleModeClick(themes.DARK)}
            />
            <ThemeCardApp
              selected={selectedTheme === themes.LIGHT}
              themeSrc={LightMode}
              themeName='Light mode'
              themeMode={themes.LIGHT}
              handleClick={() => handleModeClick(themes.LIGHT)}
            />
            <ThemeCardApp
              selected={selectedTheme === themes.SYSTEM}
              themeSrc={SyncWithBrowserMode}
              themeName='Sync with browser'
              themeMode={themes.SYSTEM}
              handleClick={() => handleModeClick(themes.SYSTEM)}
            />
          </div>
        </div>
        <Separator />
        <div className='grid grid-cols-[9.5rem,1fr] gap-x-14'>
          <Typography>Software version</Typography>
          <Typography className='text-semantic-primary' data-testid='software-version'>
            {getVersion.data?.Version ?? ''}
          </Typography>
        </div>
        <Separator />
        <div className='grid grid-cols-[9.5rem,1fr] gap-x-14'>
          <Typography>FLEX AI License</Typography>
          <div className='flex flex-col gap-3 min-h-14'>
            {activeLicense ? (
              <>
                <Typography>{activeLicense.licenseKey}</Typography>{' '}
                <Typography>
                  <span className='mr-9'>Date</span>{' '}
                  {dayjs(activeLicense.activeDate).format('MMM D, YYYY')} –{' '}
                  {dayjs(activeLicense.expiryDate).format('MMM D, YYYY')} ({activeLicenseDaysLeft}{' '}
                  day{activeLicenseDaysLeft === 1 ? '' : 's'} left)
                </Typography>
              </>
            ) : (
              <Typography>
                {availableLicenses.isPending ? 'Loading...' : 'No active license found'}
              </Typography>
            )}
          </div>
        </div>
        <Separator className='mb-0' />
      </div>
      <ModalFooter className='justify-end'>
        <Button color='primary' variant='contained' onClick={handleClick}>
          OK
        </Button>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
