import React, { useRef, useCallback, useState, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, useSnackbar } from '@htaic/cue'
import {
  useDeleteProject,
  useGetProjects,
  useProjectTraining,
  useUpdateProject,
} from '@training/apis/projects/requests'
import { Project, UpdateProject } from '@training/types'
import ObjectInputHeader from '@training/pages/Project/Header/ObjectInputHeader'
import LoadingOverlay from '@training/components/LoadingOverlay'
import { useAppState } from '@training/hooks/useAppState'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import { projectViewModes } from '@training/constants'
import { useTraining } from '@training/hooks/useTraining'
import { twMerge } from 'tailwind-merge'
import { useMutationState } from '@tanstack/react-query'
import { useVideoPlayer } from '@training/hooks/useVideoPlayer'
import { useGetProjectVideos } from '@training/apis/videoFiles/requests'
import { useGetProjectVersionAnnotationCount } from '@training/apis/annotations/requests'
import { useMinimeState } from '@training/hooks/useMinimeState'
import AnnotateTab from './Project/AnnotateTab/AnnotateTab'

const NewProject = () => {
  const orgId = useMinimeState((state) => state.orgId)

  const getProjects = useGetProjects(orgId, {
    enabled: false,
  })
  const projectsSubscription = useMemo(() => getProjects.data ?? [], [getProjects.data])

  const { id: projectId } = useParams<{ id: string }>()

  const userId = useMinimeState((state) => state.userId)

  const navigate = useNavigate()
  const showSnackBar = useSnackbar()

  const setConfidenceLevelValue = useAppState((state) => state.setConfidenceLevelValue)

  useEffect(() => {
    return () => {
      setConfidenceLevelValue(null)
    }
  }, [setConfidenceLevelValue])

  const currentProject = useMemo(
    () => projectsSubscription?.find((project: Project) => project.id === projectId),
    [projectsSubscription, projectId]
  )

  const setProjectViewMode = useAppState((state) => state.setProjectViewMode)
  const isTrained = useVideoPlayer()((state) => state.isTrained)

  const { data: projectVideos = [] } = useGetProjectVideos(projectId as string)

  const setIsTrained = useVideoPlayer()((state) => state.setIsTrained)

  const projectVersion = currentProject?.version[0]

  useEffect(() => {
    const hasTrainedClip = !!projectVideos.find((clip) => !!clip.verifiedJsonUrl)

    setIsTrained(hasTrainedClip)
  }, [setIsTrained, projectVideos])

  useEffect(() => {
    if (isTrained) {
      setProjectViewMode(
        projectVersion?.status === 'annotated' ? projectViewModes.IMPROVE : projectViewModes.REVIEW
      )
      return
    }

    setProjectViewMode(projectViewModes.INITIAL)
  }, [isTrained, setProjectViewMode, projectVersion?.status])

  const [accumulatedChanges, setAccumulatedChanges] = useState<{
    name?: string
    notes?: string
  }>({
    name: undefined,
    notes: undefined,
  })

  const [isUpdating, setIsUpdating] = useState(false)

  const deleteProjectMutation = useDeleteProject()
  const projectTrainingMutation = useProjectTraining(projectVersion?.id ?? '')
  const projectUpdateMutation = useUpdateProject()

  const objClassId = currentProject?.version[0]?.objClasses[0]?.id

  const isTraining = useMemo(
    () => !projectTrainingMutation.isPending && projectVersion?.status === 'in_training',
    [projectTrainingMutation.isPending, projectVersion?.status]
  )

  const enableFiveAnnotationsTrain = useFeatureFlags().FF_ENABLE_TRAINING_ON_FIVE_ANNOTATIONS

  const { data: annotationCount = 0 } = useGetProjectVersionAnnotationCount(
    projectVersion?.id ?? ''
  )

  const canBeTrained = enableFiveAnnotationsTrain ? annotationCount >= 5 : annotationCount > 0

  const projectViewMode = useAppState((state) => state.projectViewMode)

  const disabledButtons = useMemo(
    () =>
      isTraining ||
      projectTrainingMutation.isPending ||
      (!canBeTrained && projectViewMode !== projectViewModes.REVIEW),
    [canBeTrained, isTraining, projectTrainingMutation.isPending, projectViewMode]
  )

  const { handleStartTraining } = useTraining(projectVersion?.id ?? '')

  const updateProject = useCallback(
    async (updatedProject: UpdateProject, current: Project) => {
      try {
        await projectUpdateMutation.mutateAsync(updatedProject)
        showSnackBar({ message: 'Update complete.', status: 'success' })
      } catch (error: any) {
        setAccumulatedChanges({ name: current.name, notes: current.notes })
        showSnackBar({ message: error.response.data.message, status: 'error' })
      }
    },
    [projectUpdateMutation, showSnackBar]
  )

  const debouncedUpdate = useRef(
    debounce((updated, current) => updateProject(updated, current), 1000)
  ).current

  const handleOnChange = (newValues: { [key: string]: string }) => {
    setAccumulatedChanges((prevChanges) => ({ ...prevChanges, ...newValues }))
    setIsUpdating(true)
  }

  useEffect(() => {
    setAccumulatedChanges({ name: currentProject?.name, notes: currentProject?.notes })
  }, [currentProject?.name, currentProject?.notes])

  useEffect(() => {
    const updatedProject: UpdateProject = {
      id: currentProject?.id ?? '',
      updatedBy: userId,
      name: accumulatedChanges.name ?? '',
      notes: accumulatedChanges.notes,
      orgId,
      objectClassName:
        currentProject?.version?.[0].objClasses?.[0]?.objectClass.name ??
        currentProject?.name ??
        '',
    }
    if (updatedProject?.name.trim() === '') return
    updatedProject.name = updatedProject.name.trim()
    if (isUpdating) {
      debouncedUpdate(updatedProject, currentProject)
      setIsUpdating(false)
    }
  }, [currentProject, debouncedUpdate, accumulatedChanges, isUpdating, orgId, userId])

  const handleDeleteProject = useCallback(
    async (deleteProjectId: string) => {
      await deleteProjectMutation.mutateAsync(
        { id: deleteProjectId },
        {
          onSuccess: () => {
            showSnackBar({ message: 'Project deleted.', status: 'success' })
            navigate('/training')
          },
          onError: () => {
            console.error('Error deleting project:', Error)
            showSnackBar({ message: 'The project could not be deleted.', status: 'error' })
          },
        }
      )
    },
    [deleteProjectMutation, navigate, showSnackBar]
  )

  const isTightening = useMutationState({
    filters: { mutationKey: ['tightenAnnotations', orgId, projectId], status: 'pending' },
    select: (mutation) => mutation.state.status === 'pending',
  })[0]

  if (getProjects.isPending) {
    return (
      <Typography tag='span' variant='body2' className='dark:text-semantic-secondary m-3'>
        Loading...
      </Typography>
    )
  }

  return (
    <div
      className={twMerge(
        'relative flex flex-col h-full bg-neutral-grey-15',
        isTightening && '[&_*]:pointer-events-none cursor-wait'
      )}
      data-testid='new-project-container'
    >
      <div className='relative'>
        <ObjectInputHeader
          project={currentProject}
          onSaveProject={handleOnChange}
          onDeleteProject={
            projectVersion?.status !== 'in_training' ? handleDeleteProject : undefined
          }
          disabledButtons={disabledButtons}
          onStartTraining={handleStartTraining}
        />
      </div>
      <div className='relative h-full'>
        <LoadingOverlay isLoading={projectTrainingMutation.isPending} text='Loading ...' />
        <LoadingOverlay isLoading={isTraining} text='Training in Progress' />
        <div className='pr-4 z-1'>
          <AnnotateTab
            objClassId={objClassId}
            projectVersions={currentProject?.version ?? []}
            projectVersionId={projectVersion?.id}
            projectVersionStatus={projectVersion?.status}
            isTrained={isTrained}
          />
        </div>
      </div>
    </div>
  )
}

export default NewProject
