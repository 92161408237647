import { useState, useEffect, useMemo } from 'react'
import { Typography } from '@htaic/cue'
import { useGetThumbnailsAndAnnotation } from '@training/apis/training-assets/requests'
import { useVideoPlayer, useVideoPlayerInstance } from '@training/hooks/useVideoPlayer'
import { VideoAnnotation, useVideoAnnotations } from '@training/hooks/useVideoAnnotations'
import { scaleRect } from '@training/utils/scaleRect'
import { cropImage } from '@training/utils/cropImage'
import { loadImage } from '@training/utils/loadImage'
import { filter, head, isNull } from 'lodash'
import { twMerge } from 'tailwind-merge'
import { useAppState } from '@training/hooks/useAppState'
import { isImproveViewMode } from '@training/constants'
import type { Dimensions } from '@training/types'
import { FitImage } from '../AnnotationImage'

type AnnotationThumbnailType = 'annotation' | 'inference' | 'incorrect'
interface AnnotationThumbnailProps extends React.ComponentPropsWithoutRef<'img'> {
  imageSrc: string
  annotationType?: AnnotationThumbnailType
}

const AnnotationThumbnail = (props: AnnotationThumbnailProps) => {
  const { imageSrc, annotationType = 'annotation', className, ...rest } = props

  const annotationTypeStyles = {
    annotation: 'border-4 border-graph-sapphire',
    inference: 'border-4 border-status-success',
    incorrect: 'border-4 border-status-error',
  }

  return (
    <FitImage
      src={imageSrc}
      className={twMerge(
        'object-contain border-solid max-w-full',
        annotationTypeStyles[annotationType],
        className
      )}
      minWidth={180}
      minHeightClassName='min-h-[6rem]'
      alt='annotation-thumbnail'
      {...rest}
    />
  )
}

interface AnnotationThumbnailsProps {
  isTrained?: boolean
  projectId?: string
  clipId?: string
  className?: string
  clipDimensions?: Dimensions
}

export const AnnotationThumbnails = (props: AnnotationThumbnailsProps) => {
  const { isTrained = false, projectId, clipId, className, clipDimensions } = props

  const { data: projectAnnotations = [] } = useGetThumbnailsAndAnnotation(
    { projectId: projectId as string },
    !isTrained
  )

  const isPlaying = useVideoPlayer()((state) => state.isPlaying)
  const isSeeking = useVideoPlayer()((state) => state.isSeeking)
  const isReady = useVideoPlayer()((state) => state.isReady)
  const isMetadataReady = useVideoPlayer()((state) => state.isMetadataReady)

  const getCurrentFrame = useVideoPlayer()((state) => state.getCurrentFrame)
  const confidenceLevelValue = useAppState((state) => state.confidenceLevelValue)

  const { captureFrame, getDimensions } = useVideoPlayerInstance()((state) => ({
    captureFrame: state.captureFrame,
    getDimensions: state.getDimensions,
  }))

  const currentAnnotations = useVideoAnnotations()((state) => state.annotations)

  const scaledFrameAnnotations = useMemo(() => {
    if (!isTrained || isPlaying || isSeeking || !isReady || !isMetadataReady) {
      return []
    }

    const videoDimensions = getDimensions()
    const frameNumber = getCurrentFrame().rounded

    const scaledAnnotations: VideoAnnotation[] = []
    currentAnnotations.forEach((annotation) => {
      if (annotation.frameStart !== frameNumber) return

      scaledAnnotations.push(
        scaleRect(videoDimensions.displayedSize, videoDimensions.resolution, annotation)
      )
    })

    return scaledAnnotations
  }, [
    isPlaying,
    isSeeking,
    isReady,
    isTrained,
    currentAnnotations,
    getCurrentFrame,
    getDimensions,
    isMetadataReady,
  ])

  const [frameSnippets, setFrameSnippets] = useState<{ snippet: string; annotationId: string }[]>(
    []
  )

  // update frame snippets when scaled annotations change
  useEffect(() => {
    if (scaledFrameAnnotations.length === 0) return

    const frameCaptureUrl = captureFrame(clipDimensions!)?.toDataURL('image/png')
    if (!frameCaptureUrl) return

    loadImage(frameCaptureUrl).then((baseImage) => {
      const scaledSnippets = scaledFrameAnnotations.map((frameAnnotation) => ({
        snippet: cropImage(baseImage, frameAnnotation),
        annotationId: frameAnnotation.id,
      }))

      setFrameSnippets(scaledSnippets)
    })
  }, [scaledFrameAnnotations, captureFrame, clipDimensions])

  const displayedThumbnails: {
    image: string
    annotationId?: string
    timeOffset: number
  }[] = useMemo(() => {
    if (isSeeking || isPlaying || !isReady) {
      return []
    }

    const currentFrame = getCurrentFrame().rounded

    if (scaledFrameAnnotations.length > 0) {
      return frameSnippets.map((snippet) => ({
        image: snippet.snippet,
        annotationId: snippet.annotationId,
        timeOffset: currentFrame,
      }))
    }

    // API images don't differentiate between annotations created before or after training so they're not useful for trained projects
    if (isTrained) return []

    const annotations = projectAnnotations
      .filter((annotation) => {
        const firstFrame = annotation.images[0].timeOffset
        const lastFrame = annotation.images[annotation.images.length - 1].timeOffset
        return (
          firstFrame <= currentFrame && lastFrame >= currentFrame && clipId === annotation.videoId
        )
      })
      .map((annotation) => annotation.images)

    return head(annotations) || []
  }, [
    clipId,
    isSeeking,
    isPlaying,
    isTrained,
    isReady,
    scaledFrameAnnotations.length,
    getCurrentFrame,
    projectAnnotations,
    frameSnippets,
  ])

  const projectViewMode = useAppState((state) => state.projectViewMode)

  const filteredThumbnails = useMemo(
    () =>
      filter(displayedThumbnails, (thumbnail) => {
        const frameAnnotation = scaledFrameAnnotations.find(
          (annotation) => annotation.id === thumbnail.annotationId
        )

        if (
          !isNull(confidenceLevelValue) &&
          frameAnnotation?.type === 'inference' &&
          frameAnnotation?.confidence &&
          frameAnnotation.confidence * 100 < confidenceLevelValue
        ) {
          return false
        }

        if (isImproveViewMode(projectViewMode) && frameAnnotation?.type === 'inference') {
          return false
        }

        return true
      }),
    [displayedThumbnails, scaledFrameAnnotations, confidenceLevelValue, projectViewMode]
  )

  if (filteredThumbnails.length === 0) {
    return (
      <div className='flex items-center justify-center min-h-36'>
        <Typography
          variant='caption'
          className='text-neutral-grey-9 text-center m-auto w-3/4 [text-wrap:balance]'
        >
          Detections from this keyframe to be added will appear here
        </Typography>
      </div>
    )
  }

  return (
    <div className={twMerge('mx-auto gap-4 flex flex-col', className)}>
      {filteredThumbnails.map((thumbnail, index) => {
        const frameAnnotation = scaledFrameAnnotations.find(
          (annotation) => annotation.id === thumbnail.annotationId
        )

        return (
          <div
            data-testid='annotation-thumbnail'
            className='flex items-center justify-center'
            key={thumbnail.image + index}
          >
            <AnnotationThumbnail
              annotationType={frameAnnotation?.type || (isTrained ? 'inference' : 'annotation')}
              className='max-h-48'
              imageSrc={thumbnail.image}
            />
          </div>
        )
      })}
    </div>
  )
}
