import { Project } from '@training/types'
import ProjectCard from './ProjectCard'

interface ProjectListProps {
  projects: Project[]
}

const ProjectList = (props: ProjectListProps) => {
  let { projects } = props
  if (!projects) {
    projects = []
  }
  return (
    <div
      data-testid='project-list'
      className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-3'
    >
      {projects.map((project) => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </div>
  )
}

export default ProjectList
