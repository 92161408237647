import { Typography } from '@htaic/cue'
import { AnnotateAreaActions } from '@training/pages/Project/AnnotateTab/AnnotateAreaActions'
import { TimelinePlayer } from '@training/pages/Project/VideoViewer/TimeLinePlayer/TimeLinePlayer'

export const VideoViewerPlaceholder = ({ overlayMessage }: { overlayMessage?: string }) => {
  return (
    <>
      <AnnotateAreaActions
        isComparisonMode={false}
        title={''}
        isPlaceholder
        hasInferenceErrors={false}
      />
      <div className='mt-2 relative'>
        <div className='bg-neutral-grey-14 w-full aspect-video text-center content-center flex items-center'>
          <Typography
            tag='span'
            variant='body2'
            className='m-auto text-center dark:text-semantic-secondary'
          >
            {overlayMessage}
          </Typography>
        </div>
        <div className='pointer-events-none grayscale opacity-70'>
          <TimelinePlayer duration={0} />
        </div>
      </div>
    </>
  )
}
