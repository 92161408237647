import { Button, Typography } from '@htaic/cue'
import { CircularProgress } from '@mui/material'
import { usePopper } from '@training/hooks/usePopper'
import { useTestingHelper } from '@training/hooks/useTestingHelper'
import { useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

interface ReviewModeActionsProps {
  isDownloadDisabled: boolean
  isDisabled?: boolean
  isDownloading: boolean
  onDowloadClick: () => void
  onImproveClick: () => void
  onRecompileClick: () => void
  isRecompiling: boolean
  isRecompileHidden: boolean
  isUsingPreviousVersionForDownload: boolean
}

export const ReviewModeActions = (props: ReviewModeActionsProps) => {
  const { setPopper } = usePopper()
  const { getTestIdProps } = useTestingHelper('review-mode-actions')

  const {
    isDisabled,
    isDownloading,
    onDowloadClick,
    onImproveClick,
    isDownloadDisabled,
    onRecompileClick,
    isRecompiling,
    isRecompileHidden,
    isUsingPreviousVersionForDownload,
  } = props

  const onDownloadMouseEnter = useCallback<
    NonNullable<React.ComponentProps<'div'>['onMouseEnter']>
  >(
    (e) => {
      e.stopPropagation()
      if (isDownloadDisabled) {
        setPopper({
          id: 'info-popover',
          anchorEl: e.currentTarget,
          placement: 'left',
          content: (
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              Please wait, the download file for your object detection model is being compiled.
            </Typography>
          ),
          className: 'w-56',
          closable: false,
        })
      } else if (isUsingPreviousVersionForDownload) {
        setPopper({
          id: 'info-popover',
          anchorEl: e.currentTarget,
          placement: 'left',
          content: (
            <Typography variant='body2' className='text-white text-sm'>
              Download the last trained model. Any data added after the last training will not be
              included.
            </Typography>
          ),
          className: 'w-56',
          closable: false,
        })
      } else {
        setPopper({
          id: 'info-popover',
          anchorEl: e.currentTarget,
          placement: 'left',
          content: (
            <Typography variant='body2' className='text-white text-sm'>
              Download the last trained model.
            </Typography>
          ),
          className: 'w-56',
          closable: false,
        })
      }
    },
    [isDownloadDisabled, isUsingPreviousVersionForDownload, setPopper]
  )

  return (
    <div className='flex gap-2 col-span-2 ml-auto'>
      <Button
        color='secondary'
        aria-label='Recompile model for this project'
        title='Recompile verified model for this project'
        onClick={onRecompileClick}
        disabled={isRecompiling}
        className={twMerge(
          'w-[169px] h-8 bg-transparent disabled:bg-transparent',
          !isRecompileHidden || isRecompiling ? '' : 'hidden'
        )}
        startIcon={
          isRecompiling ? (
            <div className='p-2'>
              <CircularProgress size={17} thickness={2} color='inherit' />
            </div>
          ) : null
        }
      >
        Recompile
      </Button>
      <div
        onMouseEnter={onDownloadMouseEnter}
        onMouseLeave={() => setPopper(null)}
        role='button'
        tabIndex={0}
      >
        <Button
          color='secondary'
          onClick={onDowloadClick}
          disabled={isDisabled || isDownloadDisabled}
          className='min-w-40'
          startIcon={
            isDownloading ? <CircularProgress size={17} thickness={2} color='inherit' /> : null
          }
          {...getTestIdProps('download-button')}
        >
          {isDownloading ? null : 'Download'}
        </Button>
      </div>

      <Button
        color='primary'
        variant='contained'
        {...getTestIdProps('train-button')}
        aria-label='train'
        size='small'
        className='min-w-40'
        disabled={isDisabled}
        onClick={onImproveClick}
      >
        Improve
      </Button>
    </div>
  )
}
