import { useGetDevices } from '@training/apis/devices/requests'
import {
  useGetOncloudClips,
  useGetOnCloudTags,
  usePostOnCloudMedia,
} from '@training/apis/oncloud/requests'
import dayjs from 'dayjs'

import { useSelection } from '@training/hooks/useSelection'
import { getTimeWithTimezone } from '@training/utils/dateFormats'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { useCallback, useMemo, useState } from 'react'
import { ONCLOUD_FLEXAI_TAG, responseStatus, VMS_ITEMS_PER_PAGE } from '@training/constants'
import { formatMillisecondsToMinutes } from '@training/utils/videoTimeConverter'
import {
  Button,
  Checkbox,
  Loader,
  ModalFooter,
  ScrollBar,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Skeleton,
  Typography,
  usePagination,
  useSnackbar,
} from '@htaic/cue'
import { noop, partition } from 'lodash'
import { twMerge } from 'tailwind-merge'
import { ClipVideoCard, ClipVideoCardSkeleton } from './ClipVideoCard'
import { ClipsPagination } from './ClipsPagination'

interface OnCloudTabProps {
  onClose: () => void
  projectId: string
  onVideoUploaded?: () => void
  isTrained: boolean
  onVerifyVideo: (videoFileUUID: string) => Promise<void>
}

export const OnCloudTab = ({
  onClose,
  projectId,
  onVideoUploaded,
  isTrained,
  onVerifyVideo,
}: OnCloudTabProps) => {
  const orgId = useMinimeState((state) => state.orgId)
  const devices = useGetDevices(orgId)

  const [selectedLocationValue, setSelectedLocationValue] = useState<string>('')

  const locationItems = useMemo(() => {
    const locations = new Map(
      devices.data?.map((device) => [device.location.locationId, device.location.locationName])
    )

    const result: { id: string; value: string; label: string }[] = []
    locations.forEach((value, key) => {
      result.push({
        id: key,
        value,
        label: value,
      })
    })
    return result
  }, [devices.data])

  const selectedLocationId = useMemo(
    () => locationItems.find((item) => item.value === selectedLocationValue)?.id,
    [locationItems, selectedLocationValue]
  )

  const [currentPage, setCurrentPage] = useState(1)

  const onCloudTags = useGetOnCloudTags()

  const flexAITag = useMemo(() => {
    return onCloudTags.data?.find((tag) => tag.name === ONCLOUD_FLEXAI_TAG)
  }, [onCloudTags.data])

  const onCloudClips = useGetOncloudClips(
    {
      orgId,
      locationId: selectedLocationId,
      size: VMS_ITEMS_PER_PAGE,
      page: currentPage,
      tagId: flexAITag?.tagId,
    },
    { enabled: !!selectedLocationId && !!flexAITag?.tagId }
  )

  const deviceByDeviceId = useMemo(
    () => new Map(devices.data?.map((device) => [device.deviceId, device])),
    [devices.data]
  )

  const clipList = useMemo(() => {
    return (
      onCloudClips.data?.clips.map((clip) => {
        const duration = formatMillisecondsToMinutes(clip.durationMs)

        return {
          ...clip,
          duration,
          isDisabled: Number(duration.substring(0, 2)) > 10,
        }
      }) ?? []
    )
  }, [onCloudClips.data?.clips])

  const {
    selectedItems: selectedClips,
    isIndeterminate,
    isAllSelected,
    onItemSelect,
    onSelectAll,
    setSelectedItems: setSelectedClips,
  } = useSelection({
    items: clipList,
    getId: (clip) => clip.clipId,
    isDisabled: (clip) => clip.isDisabled,
  })

  const onLocationSelect = useCallback<
    NonNullable<React.ComponentProps<typeof Select>['onValueChange']>
  >(
    (value) => {
      setSelectedLocationValue(value)
      setSelectedClips(new Map())
    },
    [setSelectedClips]
  )

  const totalPageCount = onCloudClips.data?.totalPages ?? 1

  const pageNumbers = usePagination({
    currentPage,
    siblingsCount: 1,
    totalPageCount,
  })

  const areClipsLoading = !!selectedLocationId && onCloudClips.isPending && !!flexAITag?.tagId

  const hasClips = selectedLocationId && !areClipsLoading && clipList.length > 0

  const hasNoClips = selectedLocationId && !areClipsLoading && clipList.length === 0

  const postOnCloudMedia = usePostOnCloudMedia()

  const showSnackBar = useSnackbar()

  const onUploadMedia = async () => {
    const clips = Array.from(selectedClips.values())

    try {
      const responses = await Promise.all(
        clips.map(async (clip) => {
          const response = await postOnCloudMedia.mutateAsync({
            projectId,
            url: clip.clip.url,
          })
          return response
        })
      )

      const [mediaWithErrors, mediaUploaded] = partition(
        responses,
        (r) => r.status !== responseStatus.CREATED
      )

      if (mediaWithErrors.length > 0) {
        showSnackBar({
          message: `Error uploading ${mediaWithErrors?.length} ${
            mediaWithErrors?.length > 1 ? 'videos' : 'video'
          }`,
          status: 'error',
        })
      }

      showSnackBar({
        message: `${mediaUploaded?.length} ${
          mediaUploaded?.length > 1 ? 'videos were' : 'video was'
        } uploaded successfully`,
        status: 'success',
      })

      if (isTrained && mediaUploaded?.length > 0) {
        await Promise.all(
          mediaUploaded.map(async (item) => {
            const videoId = item.videoUploadResponse.id
            if (videoId) {
              await onVerifyVideo(`${videoId}.mp4`)
            }
          })
        )
      }

      onVideoUploaded?.()

      setSelectedClips(new Map())

      onClose()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('OnCloud upload failed: ', error)
      showSnackBar({
        message: 'Error uploading videos',
        status: 'error',
      })
    }
  }

  const renderClips = () => {
    if (hasNoClips) {
      return (
        <Typography variant='body1' className='mt-14 mb-7'>
          We couldn’t find any video clips tagged with flex_ai for this location.
        </Typography>
      )
    }

    if (areClipsLoading) {
      return (
        <ScrollBar className='grid grid-cols-2 gap-3 gap-x-6 mb-7 max-h-[37svh]'>
          {Array.from({ length: 6 }).map((_, i) => (
            <ClipVideoCardSkeleton key={i} />
          ))}
        </ScrollBar>
      )
    }

    if (hasClips) {
      return (
        <ScrollBar className='grid grid-cols-2 gap-3 gap-x-6 mb-7 max-h-[37svh]'>
          {clipList.map((clip) => {
            // TODO: use timezone from device
            const localizedTime = getTimeWithTimezone(dayjs.unix(clip.startTime).toDate())

            const deviceName = deviceByDeviceId.get(clip.deviceId)?.deviceName

            return (
              <ClipVideoCard
                key={clip.clipId}
                duration={clip.duration}
                name={`${deviceName || 'Unknown Device'} - ${localizedTime}`}
                thumbnailUrl={clip.thumbnail.url}
                isDisabled={clip.isDisabled}
                isSelected={selectedClips.has(clip.clipId)}
                onClick={clip.isDisabled ? noop : () => onItemSelect(clip)}
                data-testid='video-card-oncloud'
              />
            )
          })}
        </ScrollBar>
      )
    }

    return null
  }

  return (
    <>
      <div className='px-5 pt-11 pb-7 mb-6'>
        <Typography variant='body1' className='text-semantic-primary mb-6'>
          {onCloudTags.isError
            ? 'Unable to access OnCloud at this moment. Try again in a few minutes.'
            : 'Import video clips shorter than 10 minutes.'}
        </Typography>
        <div className='flex justify-center'>
          {onCloudTags.isPending ? <Loader size='large' /> : null}
          {onCloudTags.isSuccess ? (
            <Select onValueChange={onLocationSelect} value={selectedLocationValue}>
              <SelectTrigger
                className='w-80'
                data-testid='dropdown-devices'
                aria-label='Select location'
              >
                <SelectValue placeholder='Select location' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {devices.isPending ? (
                    <div className='mx-3.5 py-2 flex flex-col gap-4 w-3/4'>
                      <Skeleton className='h-4' />
                      <Skeleton className='h-4' />
                      <Skeleton className='h-4' />
                    </div>
                  ) : (
                    locationItems.map((item) => (
                      <SelectItem key={item.id} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))
                  )}
                </SelectGroup>
              </SelectContent>
            </Select>
          ) : null}
        </div>

        <div className={twMerge('flex flex-col', (hasClips || areClipsLoading) && 'mt-6')}>
          {renderClips()}
          {hasClips || areClipsLoading ? (
            <div className='grid grid-cols-3'>
              <div className='text-nowrap'>
                <Checkbox
                  label='Select all from this page'
                  checked={isAllSelected}
                  disabled={areClipsLoading}
                  indeterminate={isIndeterminate}
                  onChange={(e: React.BaseSyntheticEvent) => onSelectAll(e.target.checked)}
                />
              </div>
              <ClipsPagination
                onPreviousPageClick={() =>
                  setCurrentPage((prev) => (prev - 1 === 0 ? prev : prev - 1))
                }
                onNextPageClick={() =>
                  setCurrentPage((prev) => (prev === totalPageCount ? prev : prev + 1))
                }
                currentPage={currentPage}
                pageNumbers={pageNumbers}
                onPageChange={setCurrentPage}
              />
              <div />
            </div>
          ) : null}
        </div>
      </div>
      <ModalFooter className='justify-end'>
        {postOnCloudMedia.isPending ? (
          <div className='mx-auto mb-5'>
            <Typography variant='body2'>Adding OnCloud video clips...</Typography>
          </div>
        ) : (
          <>
            <Button color='neutral' variant='contained' onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!selectedClips.size}
              color='primary'
              variant='contained'
              onClick={onUploadMedia}
              data-testid='button-upload-media'
            >
              Select
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  )
}
