import { useState, useMemo, useCallback } from 'react'

type UseSelectionProps<T> = {
  items: T[]
  getId: (item: T) => string
  isDisabled?: (item: T) => boolean
}
/**
 * A hook to manage selection state for a list of items.
 *
 * @param items - The list of items to select from.
 * @param getId - A function to get the unique identifier for each item.
 * @param isDisabled - A function to determine if an item is disabled, won't count in the selection state.
 * @returns An object containing the selected items, the indeterminate state, and the all selected state.
 */
export const useSelection = <T>({
  items,
  getId,
  isDisabled = () => false,
}: UseSelectionProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<Map<string, T>>(new Map())

  const isIndeterminate = useMemo(() => {
    const filteredItems = items.filter((item) => !isDisabled(item))
    const isInPageWithSelections = filteredItems.some((item) => selectedItems.has(getId(item)))
    const hasSelectedAll = filteredItems.every((item) => selectedItems.has(getId(item)))

    return isInPageWithSelections && !hasSelectedAll && selectedItems.size > 0
  }, [items, selectedItems, getId, isDisabled])

  const isAllSelected = useMemo(() => {
    if (items.length === 0) return false

    const filteredItems = items.filter((item) => !isDisabled(item))
    const hasSelectedAll = filteredItems.every((item) => selectedItems.has(getId(item)))

    return hasSelectedAll && selectedItems.size >= filteredItems.length
  }, [items, selectedItems, getId, isDisabled])

  const onItemSelect = useCallback(
    (item: T) => {
      const itemId = getId(item)
      if (selectedItems.has(itemId)) {
        setSelectedItems((prev) => {
          const newSelectedItems = new Map(Array.from(prev))
          newSelectedItems.delete(itemId)
          return newSelectedItems
        })
        return
      }
      setSelectedItems((prev) => new Map(Array.from(prev)).set(itemId, item))
    },
    [getId, selectedItems]
  )

  const onSelectAll = useCallback(
    (selected: boolean) => {
      const filteredItems = items.filter((item) => !isDisabled(item))

      if (selected) {
        // Add all items to the selected list
        setSelectedItems(
          (prev) => new Map([...prev, ...new Map(filteredItems.map((item) => [getId(item), item]))])
        )
        return
      }

      setSelectedItems(
        (prev) =>
          new Map(
            Array.from(prev).filter(
              ([itemId]) => !filteredItems.some((item) => getId(item) === itemId)
            )
          )
      )
    },
    [items, isDisabled, getId]
  )

  return {
    selectedItems,
    isIndeterminate,
    isAllSelected,
    onItemSelect,
    onSelectAll,
    setSelectedItems,
  }
}
