import {
  Button,
  Typography,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  useSnackbar,
} from '@htaic/cue'
import { useFeatureFlags, useFeatureFlagsState } from '@training/hooks/useFeatureFlags'
import { map } from 'lodash'

interface PersonalSettingsModalProps {
  onClose: () => void
}

export const FeatureFlagsModal = ({ onClose }: PersonalSettingsModalProps) => {
  const featureFlags = useFeatureFlags()
  const toggleFeatureFlag = useFeatureFlagsState((state) => state.toggleFeatureFlag)

  const showSnackbar = useSnackbar()

  return (
    <ModalContent>
      <ModalHeader closable>
        <div className='flex flex-col'>
          <Typography variant='heading2'>Feature Flags</Typography>
          <Typography variant='body2' className='text-semantic-secondary'>
            Changes are kept locally and per session
          </Typography>
        </div>
      </ModalHeader>
      <div className='flex flex-col pt-6'>
        <div className='flex'>
          <div className='grid grid-cols-2 gap-x-5 gap-y-2'>
            {map(featureFlags, (flagValue, flagKey: keyof typeof featureFlags) => (
              <div
                className='flex [&>label]:justify-between [&>label]:w-full w-full lowercase dark:text-semantic-primary'
                key={flagKey}
              >
                <Switch
                  data-testid={`feature-flag-${flagKey}`}
                  labelPlacement='start'
                  label={flagKey}
                  id={flagKey}
                  inputProps={{ id: flagKey }}
                  onChange={() => {
                    if (!featureFlags.DEV_FF_MANAGEMENT) {
                      showSnackbar({
                        message: 'Environment not configured to change feature flags',
                        status: 'error',
                      })
                      return
                    }

                    toggleFeatureFlag(flagKey)
                  }}
                  checked={flagValue}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalFooter className='justify-end pt-6 pb-8'>
        <Button className='ml-2' variant='contained' color='neutral' onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
