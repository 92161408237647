import * as React from 'react'
import { IconButton } from '@mui/material'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import { usePopper } from '@training/hooks/usePopper'
import { useMutationState } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import {
  type SelectBoxItemProps,
  Button,
  Icon,
  Loader,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
  Switch,
  Typography,
} from '@htaic/cue'
import { Foi } from '@training/apis/types'
import {
  useSyncVideoPlayerInstances,
  useVideoPlayer,
  useVideoPlayerInstance,
} from '@training/hooks/useVideoPlayer'
import { isNaN, isNil, isUndefined, noop, round } from 'lodash'
import { MAX_REFERENCE_POINTS, projectViewModes } from '@training/constants'
import {
  VideoAnnotation,
  useSaveVideoAnnotations,
  useVideoAnnotations,
} from '@training/hooks/useVideoAnnotations'
import { useAppState } from '@training/hooks/useAppState'
import { twMerge } from 'tailwind-merge'
import { secondsToFormattedTime } from '@training/utils/videoTimeConverter'
import { ReferencePoint } from '@training/types'
import { useProjectCompareStore } from '@training/hooks/useCompareMode'
import { useParams } from 'react-router-dom'
import { useModalState } from '@training/hooks/useModal'
import { startMeasure } from '@training/utils/performance'
import {
  useAddReferencePoint,
  useDeleteReferencePoint,
  useGetReferencePoints,
} from '@training/apis/reference-points/requests'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { getClosestNumber } from '@training/utils/getClosestNumber'
import { KeyBoardLedgeListener, KeyBoardLedgeModal } from '../../KeyBoardLedge'
import { useAnnotationFrameFilter } from '../useAnnotationFrameFilter'
import { TimelineThumbnails } from './TimeLineThumbnails'
import {
  ReferencePointButton,
  ReferencePointInfoButton,
  ReferencePointMarker,
} from './ReferencePoints'

interface PlaybackRateOptionsProps extends SelectBoxItemProps {
  key: string
}
export interface TimelinePlayerProps {
  duration: number
  isPlaying?: boolean
  onPlay?: () => void
  onReplay?: () => void
  onForward?: () => void
  onProgressChange?: (newProgress: number) => void
  onPlaybackRateChange?: (newProgress: number) => void
  initialMarkers?: TimelineMarker[]
  onMarkerClick?: (progress: number) => void
  onTighten?: () => void
  className?: string
  thumbnailsList?: Array<{ timeSeconds: number; url: string }>
  userGeneratedAnnotations?: VideoAnnotation[]
  suggestedFrames?: Foi[]
  hideControls?: boolean
  onNextSuggestedFrame?: () => void
  onPreviousSuggestedFrame?: () => void
  videoFileId?: string
}

export const TimelinePlayer = (props: TimelinePlayerProps) => {
  const {
    duration,
    isPlaying,
    onPlay,
    onForward,
    onReplay,
    onProgressChange,
    onPlaybackRateChange,
    initialMarkers = [],
    onMarkerClick,
    onTighten,
    className,
    thumbnailsList = [],
    userGeneratedAnnotations,
    suggestedFrames,
    hideControls,
    onNextSuggestedFrame,
    onPreviousSuggestedFrame,
    videoFileId,
  } = props

  const shouldDisableSaveKey = useModalState((state) => state.isOpen)

  const playbackRateOptions: PlaybackRateOptionsProps[] = React.useMemo(
    () => [
      { key: '1', label: '0.1x', value: '0.1x' },
      { key: '2', label: '0.5x', value: '0.5x' },
      { key: '3', label: '1x', value: '1x' },
      { key: '4', label: '1.25x', value: '1.25x' },
      { key: '5', label: '1.5x', value: '1.5x' },
      { key: '6', label: '2x', value: '2x' },
    ],
    []
  )
  const progressBarRef = React.useRef<HTMLDivElement | null>(null)
  const [hoverTimeStamp, setHoverTimeStamp] = React.useState<number>()

  const [hasUserGeneratedAnnotations, setHasUserGeneratedAnnotations] = React.useState(false)

  const displayThumbnailsList: Array<{
    timeSeconds: number
    url: string
    isPlaceholder?: boolean
  }> = [
    { timeSeconds: -1, url: '', isPlaceholder: true },
    { timeSeconds: -1, url: '', isPlaceholder: true },
    ...thumbnailsList,
  ]

  const getVisibleThumbnails = (timeStamp: number) => {
    if (!thumbnailsList || isNaN(timeStamp)) {
      return []
    }

    let index = displayThumbnailsList.findIndex((thumbNail) => thumbNail.timeSeconds === timeStamp)

    const noExactTimeStampMatch = index === -1
    if (noExactTimeStampMatch) {
      const closestTimeStamp = getClosestNumber(
        timeStamp,
        displayThumbnailsList.map((t) => t.timeSeconds)
      )

      index = displayThumbnailsList.findIndex(
        (thumbnails) => thumbnails.timeSeconds === closestTimeStamp
      )
    }

    const start = Math.max(0, index - 2)
    const end = Math.min(displayThumbnailsList.length, index + 8)

    return displayThumbnailsList?.slice(start, end)
  }

  const visibleThumbnails =
    isNil(hoverTimeStamp) || isNaN(hoverTimeStamp) ? [] : getVisibleThumbnails(hoverTimeStamp)

  const instanceRef = useVideoPlayerInstance()((state) => state.instanceRef)
  const getDuration = useVideoPlayerInstance()((state) => state.getDuration)

  const getCurrentFrame = useVideoPlayer()((state) => state.getCurrentFrame)
  const currentTime = useVideoPlayer()((state) => state.currentTime)
  const isSeeking = useVideoPlayer()((state) => state.isSeeking)

  const currentTimeRounded = React.useMemo(() => {
    return round(currentTime, 3)
  }, [currentTime])

  const onProgressBarOut = () => {
    setHoverTimeStamp(undefined)
  }

  const shouldWaitAnimationRef = React.useRef(false)

  const handleProgressBarHover = React.useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      const progressBar = progressBarRef.current

      if (!progressBar || shouldWaitAnimationRef.current) return
      const progressBarRect = progressBar.getBoundingClientRect()
      const clickX = event.clientX - progressBarRect.left
      const progressPercentage = (clickX / progressBar.offsetWidth) * 100
      const newProgress = Math.min(Math.max(progressPercentage, 0), 100)
      const newTimeStamp = Math.ceil((duration * newProgress) / 100)
      if (isUndefined(thumbnailsList?.find((t) => t.timeSeconds === newTimeStamp))) {
        // find the closest timeseconds
        const closestTimeStamp = getClosestNumber(
          newTimeStamp,
          thumbnailsList.map((t) => t.timeSeconds)
        )

        setHoverTimeStamp(closestTimeStamp)
      } else {
        setHoverTimeStamp(newTimeStamp)
      }
    },
    [duration, thumbnailsList]
  )

  const progress = React.useMemo(
    () => (getDuration() ? currentTime / getDuration() : 0) * 100,
    [currentTime, getDuration]
  )

  const isComparisonMode = useVideoPlayer()((state) => state.isComparisonMode)

  const isPreviousReady = useVideoPlayer('current')((state) => state.isReady)

  const [playbackRateOption, setPlaybackRateOption] = React.useState(playbackRateOptions[2].value)

  const handlePauseClick = () => {
    onPlay?.()
  }
  const setComparisonMode = useVideoPlayer()((state) => state.setComparisonMode)
  const { syncCurrentTime, play } = useSyncVideoPlayerInstances()

  const { id: projectId } = useParams()
  const addProjectCompareMode = useProjectCompareStore((state) => state.addProjectCompareMode)

  const handleComparisonModeChange = () => {
    addProjectCompareMode({ id: projectId ?? '', compare: !isComparisonMode })
    setComparisonMode(!isComparisonMode)
  }

  React.useEffect(() => {
    const syncAndPlay = async () => {
      if (isComparisonMode && isPreviousReady) {
        syncCurrentTime()
        if (isPlaying) {
          await play()
        }
      }
    }

    syncAndPlay()
  }, [isComparisonMode, isPlaying, isPreviousReady, play, syncCurrentTime])

  const openModal = useModalState((state) => state.openModal)

  const onShortcutsModalOpen = () => {
    openModal({
      size: 'lg',
      content: <KeyBoardLedgeModal />,
    })
  }

  const handleMarkerClick = (time: number) => {
    const newProgress = (time / duration) * 100

    onMarkerClick?.(newProgress)

    onProgressChange?.(newProgress)
  }
  const handleThumbnailTileClick = (time: number) => {
    const newProgress = (time / duration) * 100
    onProgressChange?.(newProgress)
    setHoverTimeStamp(time)
  }

  const showReferencePointFeature = useFeatureFlags().REFERENCE_POINTS
  const selectedReferencePointRef = React.useRef<HTMLButtonElement | null>(null)
  const { setPopper } = usePopper()

  const setSelectedReferencePointRef = React.useCallback(
    (time: number) => {
      requestAnimationFrame(() => {
        selectedReferencePointRef.current = document.getElementById(
          `reference-point-${time}`
        ) as HTMLButtonElement
      })
    },
    [selectedReferencePointRef]
  )

  const addReferencePoint = useAddReferencePoint(videoFileId ?? '')
  const getReferencePoints = useGetReferencePoints(videoFileId ?? '')
  const referencePoints = React.useMemo(() => getReferencePoints.data ?? [], [getReferencePoints])

  const userId = useMinimeState((state) => state.userId)

  const onAddReferencePoint = React.useCallback(async () => {
    if (referencePoints.length >= MAX_REFERENCE_POINTS) return

    const hasReferencePoint = referencePoints.find(
      (referencePoint) => round(referencePoint.timelineInSeconds, 3) === currentTimeRounded
    )
    if (hasReferencePoint) return

    await addReferencePoint.mutateAsync({
      timelineInSeconds: currentTimeRounded,
      createdBy: userId,
    })
    await getReferencePoints.refetch()

    setSelectedReferencePointRef(currentTimeRounded)
  }, [
    referencePoints,
    addReferencePoint,
    userId,
    getReferencePoints,
    setSelectedReferencePointRef,
    currentTimeRounded,
  ])

  const deleteReferencePoint = useDeleteReferencePoint(videoFileId ?? '')

  const handleRemoveReferencePoint = React.useCallback(async () => {
    const referencePointId = referencePoints.find(
      (referencePoint) => referencePoint.timelineInSeconds === currentTimeRounded
    )?.id

    if (!referencePointId) return
    setPopper(null)

    await deleteReferencePoint.mutateAsync({ id: referencePointId })
    await getReferencePoints.refetch()
  }, [referencePoints, deleteReferencePoint, getReferencePoints, setPopper, currentTimeRounded])

  function findPreviousReferencePoint(points: ReferencePoint[], currentTimestamp: number) {
    const sortedReferencePoints = points.toSorted(
      (a, b) => b.timelineInSeconds - a.timelineInSeconds
    )

    return sortedReferencePoints.find((m) => m.timelineInSeconds < +currentTimestamp.toFixed(3))
  }

  const findNextReferencePoints = (points: ReferencePoint[], currentTimestamp: number) => {
    const sortedReferencePoints = points.toSorted(
      (a, b) => a.timelineInSeconds - b.timelineInSeconds
    )
    return sortedReferencePoints.find((m) => m.timelineInSeconds > +currentTimestamp.toFixed(3))
  }

  const onReverseReferencePointJump = () => {
    if (currentTime > 0) {
      const previousKeyframeTime = findPreviousReferencePoint(referencePoints, currentTime)
      if (!isNil(previousKeyframeTime)) {
        setSelectedReferencePointRef(previousKeyframeTime.timelineInSeconds)
        handleMarkerClick(previousKeyframeTime.timelineInSeconds)
        instanceRef.current?.pause()
      }
    }
  }

  const onForwardReferencePointJump = () => {
    if (referencePoints.length > 0) {
      const nextKeyframeTime = findNextReferencePoints(referencePoints, currentTime)
      if (!isNil(nextKeyframeTime)) {
        setSelectedReferencePointRef(nextKeyframeTime.timelineInSeconds)
        handleMarkerClick(nextKeyframeTime.timelineInSeconds)
        instanceRef.current?.pause()
      }
    }
  }

  const handleDropdownOnChange = React.useCallback(
    (value: string) => {
      setPlaybackRateOption(value)
      const newPlaybackRate = Number(value.slice(0, -1))
      onPlaybackRateChange?.(newPlaybackRate)
    },
    [onPlaybackRateChange]
  )

  const autosaveAnnotationsFeature = useFeatureFlags().ANNOTATION_AUTOSAVE
  const tightenAnnotationsFeature = useFeatureFlags().ANNOTATION_TIGHTEN
  const modifiedAnnotations = useVideoAnnotations()((state) => state.modifiedAnnotations)
  const annotationsToTighten = useVideoAnnotations()((state) => state.annotationsToTighten)

  const { triggerSave, requestSave } = useSaveVideoAnnotations()

  const comparePlayerEnabled = useAppState((state) => state.comparePlayerEnabled)

  const showReferencePointRemovePopper = React.useCallback<
    NonNullable<React.ComponentProps<'button'>['onClick']>
  >(
    (e) => {
      e.stopPropagation()
      setPopper({
        id: 'remove-popover',
        anchorEl: selectedReferencePointRef.current,
        placement: 'bottom',
        closable: false,
        content: (
          <>
            <Typography variant='body2' font='secondary' className='text-white text-xs mb-3'>
              Are you sure you want to remove this reference point?
            </Typography>
            <div className='flex justify-between gap-2'>
              <div className='w-auto'>
                <Button
                  data-testid='remove-reference-point-yes-button'
                  size='small'
                  color='primary'
                  className='bg-transparent text-primary-500 border-1 border-primary-500 border-solid border'
                  onClick={handleRemoveReferencePoint}
                >
                  Yes
                </Button>
              </div>
              <div className='w-auto'>
                <Button
                  size='small'
                  color='secondary'
                  className='bg-transparent text-white'
                  onClick={() => {
                    setPopper(null)
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          </>
        ),
        className: 'w-56',
      })
    },
    [setPopper, handleRemoveReferencePoint]
  )

  const objectNotPresentButtonRef = React.useRef<HTMLButtonElement | null>(null)

  const onObjectNotPresentSave = () => {
    triggerSave(true, true)
    setPopper(null)
  }

  const onObjectNotPresentClick = () => {
    setPopper({
      id: 'object-not-present-popover',
      anchorEl: objectNotPresentButtonRef.current,
      placement: 'bottom',
      content: (
        <>
          <Typography variant='body2' font='secondary' className='text-white text-xs mb-3'>
            Are you sure the object is not found on this frame?
          </Typography>
          <div className='flex justify-between gap-2'>
            <div className='w-auto'>
              <Button
                data-testid='remove-reference-point-yes-button'
                size='small'
                color='primary'
                className='bg-transparent text-primary-500 border-primary-500 border-solid border'
                onClick={onObjectNotPresentSave}
                disabled={hasUserGeneratedAnnotations}
              >
                Yes
              </Button>
            </div>
            <div className='w-auto'>
              <Button
                size='small'
                color='secondary'
                className='bg-transparent text-white'
                onClick={() => {
                  setPopper(null)
                }}
              >
                No
              </Button>
            </div>
          </div>
        </>
      ),
      className: 'w-56',
    })
  }

  const tightenMutationStatus = useMutationState({
    filters: { mutationKey: ['tightenAnnotations'] },
    select: (mutation) => mutation.state.status,
  })

  const tightenMutationLastStatus = React.useMemo(
    () => tightenMutationStatus?.[tightenMutationStatus.length - 1],
    [tightenMutationStatus]
  )
  const tightenMutationSuccess = tightenMutationLastStatus === 'success'
  const tightenMutationPending = tightenMutationLastStatus === 'pending'

  const disableSave = requestSave || tightenMutationPending || modifiedAnnotations.size === 0
  const disableTighten =
    tightenMutationPending || annotationsToTighten.length === 0 || requestSave || isPlaying

  const showTightenInfoHandler: React.ComponentProps<'button'>['onMouseEnter'] = (e) => {
    if (annotationsToTighten.length > 0 || !tightenMutationSuccess) return

    setPopper({
      id: 'tighten-info-popover',
      anchorEl: e.currentTarget,
      placement: 'bottom',
      content: (
        <Typography variant='body2' font='secondary' className='text-white text-xs'>
          All detection boxes tightened.
        </Typography>
      ),
      className: '',
      closable: false,
    })
  }

  const showSaveInfoHandler: React.ComponentProps<'button'>['onMouseEnter'] = (e) => {
    if (modifiedAnnotations.size > 0) return

    setPopper({
      id: 'save-info-popover',
      anchorEl: e.currentTarget,
      placement: 'bottom',
      content: (
        <Typography variant='body2' font='secondary' className='text-white text-xs'>
          All changes saved.
        </Typography>
      ),
      className: '',
      closable: false,
    })
  }

  const projectViewMode = useAppState((state) => state.projectViewMode)

  const confidenceLevelValue = useAppState((state) => state.confidenceLevelValue)

  const objectNotPresentIsSet =
    !!suggestedFrames?.find(
      (x) => x.frameNumber === getCurrentFrame().rounded && x.isSavedToAnnotations
    ) && !hasUserGeneratedAnnotations

  const filterAnnotationsInFrame = useAnnotationFrameFilter({ liveUpdate: false })

  // check if there are user generated annotations in the current frame only on pause for performance
  React.useEffect(() => {
    if ((!isPlaying || !isSeeking) && (userGeneratedAnnotations?.length || 0) > 0) {
      const userGeneratedAnnotationsInFrame = filterAnnotationsInFrame({
        annotations: userGeneratedAnnotations || [],
        confidenceLevelValue,
        projectViewMode,
        isComparisonMode,
      })
      setHasUserGeneratedAnnotations(userGeneratedAnnotationsInFrame.length > 0)
    } else {
      setHasUserGeneratedAnnotations(false)
    }
  }, [
    isPlaying,
    isSeeking,
    filterAnnotationsInFrame,
    confidenceLevelValue,
    projectViewMode,
    isComparisonMode,
    userGeneratedAnnotations,
  ])

  const renderControls = () => {
    if (hideControls) return null

    const isOnReferencePoint = referencePoints?.find(
      (referencePoint) => referencePoint.timelineInSeconds === currentTimeRounded
    )

    return (
      <div className='flex items-center mr-4 mt-2 relative'>
        <IconButton
          className='p-0 pr-0.5'
          size='small'
          onClick={onReverseReferencePointJump}
          disabled={referencePoints.length === 0}
        >
          <Icon
            name='ReverseJump'
            size='normal'
            className={twMerge(
              'text-icon-default',
              referencePoints.length === 0 && 'text-neutral-grey-13'
            )}
          />
        </IconButton>
        <IconButton className='p-0 px-0.5' size='small' onClick={onReplay}>
          <Icon name='Reverse0_5xSpeed' size='normal' className='text-icon-default' />
        </IconButton>
        {isPlaying ? (
          <IconButton
            className='p-0 px-0.5'
            size='small'
            disableRipple={false}
            title='Close'
            onClick={handlePauseClick}
            data-testid='pause-button'
          >
            <Icon name='Pause' size='normal' className='text-icon-default' />
          </IconButton>
        ) : (
          <IconButton
            className='p-0 px-0.5'
            data-testid='play-button'
            size='small'
            disableRipple={false}
            title='Close'
            onClick={onPlay}
          >
            <Icon name='Play' size='normal' className='text-icon-default' />
          </IconButton>
        )}
        <IconButton className='p-0 px-0.5' size='small' onClick={onForward}>
          <Icon name='Forward05xSpeed' size='normal' className='text-icon-default' />
        </IconButton>
        <IconButton
          className='p-0 pl-0.5'
          size='small'
          onClick={onForwardReferencePointJump}
          disabled={referencePoints.length === 0}
        >
          <Icon
            name='ForwardJump'
            size='normal'
            className={twMerge(
              'text-icon-default',
              referencePoints.length === 0 && 'text-neutral-grey-13'
            )}
          />
        </IconButton>
        {duration ? (
          <div className='flex items-center mx-2'>
            <Typography variant='caption' className='dark:text-semantic-secondary'>
              {secondsToFormattedTime(currentTime)}/
            </Typography>
            <Typography variant='caption' className='dark:text-semantic-secondary'>
              {secondsToFormattedTime(duration)}
            </Typography>
          </div>
        ) : (
          <div className='flex items-center mx-2 '>
            <Typography variant='caption' className='dark:text-semantic-secondary'>
              00:00:00/00:00:00
            </Typography>
          </div>
        )}
        <Select
          onValueChange={handleDropdownOnChange}
          value={onPlaybackRateChange ? String(playbackRateOption ?? '') : ''}
        >
          <SelectTrigger
            className='w-16 bg-transparent pr-1'
            data-testid='dropdown-devices'
            aria-label='Select speed'
          >
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {(onPlaybackRateChange ? playbackRateOptions : []).map((item) => (
                <SelectItem key={item.id} value={String(item.value ?? '')}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        {showReferencePointFeature && (
          <>
            <ReferencePointButton
              mode={isOnReferencePoint ? 'remove' : 'add'}
              hasMaxReferencePoints={referencePoints.length >= MAX_REFERENCE_POINTS}
              onAddReferencePoint={onAddReferencePoint}
              onRemoveReferencePoint={showReferencePointRemovePopper}
            />
            <ReferencePointInfoButton />
          </>
        )}
        {comparePlayerEnabled && projectViewMode === projectViewModes.REVIEW && (
          <Switch
            label='Single/Compare Player'
            labelPlacement='start'
            checkedText='Compare'
            className='w-[115px] text-neutral-grey-7 dark:text-semantic-secondary text-[12px] [&+span]:mr-1 [&+span]:text-[12px]'
            onChange={handleComparisonModeChange}
            uncheckedText='Single Player'
            checked={isComparisonMode}
            variant='outlined'
          />
        )}
      </div>
    )
  }

  return (
    <div
      data-testid='timeline-player'
      className={twMerge(
        `flex mt-2 mb-4 flex-col z-10`,
        projectViewMode === projectViewModes.IMPROVE ? 'mt-0' : '',
        visibleThumbnails.length === 0 && 'z-0',
        className
      )}
    >
      <div className='flex flex-col w-full group'>
        {hideControls ? null : (
          <div
            className={twMerge(
              `rounded w-full relative flex items-center flex-col mb-1 -mt-[9.5rem]`
            )}
            onMouseLeave={onProgressBarOut}
            role='button'
            tabIndex={0}
          >
            <div
              id='thumbnail-preview-container'
              className={twMerge(`w-full h-32`)}
              onMouseMove={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              role='button'
              tabIndex={0}
            >
              <AnimatePresence mode='wait'>
                {visibleThumbnails.length === 0 ? (
                  <motion.div
                    key='thumbnail-preview-slider-placeholder'
                    className={twMerge('-z-50 w-full pointer-events-none')}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 1, transition: { duration: 0.01 } }}
                    onAnimationStart={() => {
                      shouldWaitAnimationRef.current = false
                    }}
                  />
                ) : (
                  <motion.div
                    onAnimationStart={() => {
                      shouldWaitAnimationRef.current = true
                    }}
                    onAnimationComplete={() => {
                      shouldWaitAnimationRef.current = false
                    }}
                    key='thumbnail-preview-slider'
                    initial='hidden'
                    exit='hidden'
                    animate='show'
                    variants={{
                      hidden: {
                        opacity: 0,
                        y: 50,
                        transition: {
                          duration: 0.2,
                        },
                      },
                      show: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          duration: 0.15,
                        },
                      },
                    }}
                    style={{
                      backgroundColor: 'rgba(64,64,64, 0.7)',
                    }}
                    id='thumbnail-preview-slider'
                    className='w-full backdrop-blur aspect-video h-32 flex overflow-hidden gap-2 p-2'
                  >
                    <TimelineThumbnails
                      thumbnails={visibleThumbnails}
                      currentHoverTimeStamp={hoverTimeStamp}
                      onThumbnailClick={handleThumbnailTileClick}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div
              className='relative w-full z-10 bg-neutral-grey-15 pt-2'
              onMouseMove={thumbnailsList.length > 0 ? handleProgressBarHover : noop}
              role='button'
              tabIndex={0}
            >
              <Slider
                value={progress}
                max={100}
                min={0}
                step={0.05}
                className='w-full'
                size='medium'
                track={false}
                onChange={(_, value) => {
                  onProgressChange?.(value as number)
                }}
                classes={{
                  thumb: 'h-2.5 w-2.5 shadow-none z-10',
                  rail: 'text-neutral-grey-13',
                  root: 'py-4',
                }}
                ref={progressBarRef}
              />
              <div className='absolute top-0 w-full'>
                {initialMarkers?.map((marker, index) => {
                  const isOnMarker = marker.frame === getCurrentFrame().rounded
                  return (
                    <button
                      aria-label='Marker button'
                      type='button'
                      tabIndex={0}
                      key={index}
                      data-testid={`marker-${marker.id}`}
                      className='bg-transparent p-0 border-0 cursor-pointer absolute rounded w-3 top-3.5 z-20 active:border-none focus:border-none'
                      style={{ left: `calc(${marker.start}% - 6px)` }}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleMarkerClick(marker.time)
                        setSelectedReferencePointRef(marker.time)
                      }}
                      onKeyDown={(event) => {
                        event.stopPropagation()
                        if (event.key === 'Enter' || event.key === ' ') {
                          handleMarkerClick(marker.time)
                        }
                      }}
                    >
                      <span
                        className={`h-6 w-0.5 block ${
                          isOnMarker ? 'bg-primary' : 'bg-graph-sapphire'
                        }  m-auto`}
                      />
                    </button>
                  )
                })}

                {referencePoints?.map((point) => {
                  const progressRf = (round(point.timelineInSeconds, 3) / duration) * 100

                  return (
                    <ReferencePointMarker
                      ref={selectedReferencePointRef}
                      key={point.timelineInSeconds}
                      data-testid='reference-point'
                      id={`reference-point-${point.timelineInSeconds}`}
                      style={{ left: `calc(${progressRf}% - 3px)` }}
                      onClick={(event) => {
                        event.stopPropagation()
                        // close the popper if it is open on another reference point
                        setPopper(null)
                        handleMarkerClick(point.timelineInSeconds)
                        selectedReferencePointRef.current = event.currentTarget
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={twMerge('flex justify-between', hideControls ? '' : 'mb-3')}>
        {renderControls()}
        <div className='ml-auto flex items-center gap-4'>
          {projectViewMode === projectViewModes.IMPROVE ? (
            <Button
              title='Object Not Present'
              onClick={onObjectNotPresentClick}
              variant='contained'
              color='neutral'
              ref={objectNotPresentButtonRef}
              disabled={objectNotPresentIsSet || hasUserGeneratedAnnotations}
              data-testid='object-not-present-button'
            >
              Object not present
            </Button>
          ) : null}
          {tightenAnnotationsFeature && projectViewMode !== projectViewModes.REVIEW && (
            <span
              onMouseEnter={showTightenInfoHandler}
              onMouseLeave={() => {
                setPopper(null)
              }}
              className='inline-block'
              role='button'
              tabIndex={0}
            >
              <Button
                variant='outlined'
                color='neutral'
                className={twMerge('bg-inherit', tightenMutationPending && '!text-neutral-grey-3')}
                disabled={disableTighten || objectNotPresentIsSet}
                onClick={() => {
                  onTighten?.()
                }}
                data-testid='tighten-button'
              >
                {tightenMutationPending ? (
                  <span
                    className='flex justify-between'
                    aria-live='polite'
                    aria-label='Tightenting annotations'
                    aria-busy={tightenMutationPending}
                  >
                    <Loader /> <span className='ml-1'>Tightening</span>
                  </span>
                ) : (
                  <span>Tighten</span>
                )}
              </Button>
            </span>
          )}
          {!autosaveAnnotationsFeature && projectViewMode !== projectViewModes.REVIEW && (
            <span
              onMouseEnter={showSaveInfoHandler}
              onMouseLeave={() => {
                setPopper(null)
              }}
              className='inline-block'
              role='button'
              tabIndex={0}
            >
              <Button
                variant='outlined'
                color='primary'
                className='bg-none'
                disabled={disableSave}
                onClick={() => {
                  startMeasure('save-annotations-payload')

                  triggerSave(true)
                }}
              >
                {requestSave ? 'Saving' : 'Save'}
              </Button>
            </span>
          )}
        </div>
      </div>
      <div className='mt-2 flex flex-row items-center'>
        <IconButton className='w-7 h-7 cursor-pointer' size='small' onClick={onShortcutsModalOpen}>
          <Icon name='PatternAuto' size='small' className='text-icon-default' />
        </IconButton>
        <Typography
          variant='subhead2'
          className=' cursor-pointer text-neutral-grey-0 dark:text-semantic-secondary'
          data-testid='keyboard-shortcuts-button'
          onClick={onShortcutsModalOpen}
        >
          Keyboard Shortcuts
        </Typography>
      </div>
      <KeyBoardLedgeListener
        onPlay={onPlay}
        onReplay={onReplay}
        onForward={onForward}
        onForwardReferencePoint={onForwardReferencePointJump}
        onReverseReferencePoint={onReverseReferencePointJump}
        onNextSuggestedFrame={onNextSuggestedFrame}
        onPreviousSuggestedFrame={onPreviousSuggestedFrame}
        onSave={() => {
          if (disableSave || shouldDisableSaveKey) return
          startMeasure('save-annotations-payload')
          triggerSave(true)
        }}
        enabledEvents={
          projectViewMode === projectViewModes.IMPROVE
            ? ['save', 'previousSuggestedFrame', 'nextSuggestedFrame']
            : [
                'play',
                'replay',
                'forward',
                'forwardReferencePoint',
                'reverseReferencePoint',
                'save',
              ]
        }
        onTighten={() => {
          if (disableTighten) return
          onTighten?.()
        }}
      />
    </div>
  )
}

interface TimelineMarker {
  id: string
  start: number
  end: number
  time: number
  frame: number
}
