import {
  Pagination,
  PaginationButton,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  usePagination,
} from '@htaic/cue'

interface ClipsPaginationProps {
  onPreviousPageClick: () => void
  onNextPageClick: () => void
  pageNumbers: ReturnType<typeof usePagination>
  currentPage: number
  onPageChange: (page: number) => void
}

export const ClipsPagination = (props: ClipsPaginationProps) => {
  const { onPreviousPageClick, onNextPageClick, pageNumbers, currentPage, onPageChange } = props

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            disabled={currentPage === 1}
            className='dark:bg-transparent'
            onClick={onPreviousPageClick}
          />
        </PaginationItem>
        {pageNumbers.map((page, index) =>
          page === '...' ? (
            <PaginationItem key={index}>
              <PaginationButton className='cursor-default pointer-events-none'>
                {page}
              </PaginationButton>
            </PaginationItem>
          ) : (
            <PaginationItem key={index}>
              <PaginationButton isActive={page === currentPage} onClick={() => onPageChange(page)}>
                {page}
              </PaginationButton>
            </PaginationItem>
          )
        )}
        <PaginationItem>
          <PaginationNext
            disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
            className='dark:bg-transparent'
            onClick={onNextPageClick}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}
