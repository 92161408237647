export function startMeasure(eventName: string) {
  if (!performance?.mark) {
    return
  }

  try {
    performance.mark(`${eventName}_started`)
  } catch (error) {
    console.error(`[Performance] Failed to startMeasure ${eventName}`, error)
  }
}

export function stopMeasure(eventName: string) {
  if (!performance?.mark) {
    return
  }

  try {
    const started = `${eventName}_started`
    const completed = `${eventName}_completed`
    const measured = `${eventName}_measured`

    performance.mark(completed)
    const measure = performance.measure(measured, started, completed)
    console.log(`${eventName}_ms`, `${measure.duration}ms = ${measure.duration / 1000}s`)

    performance.clearMarks(started)
    performance.clearMarks(completed)
    performance.clearMeasures(measured)
  } catch (error) {
    console.error(`[Performance] Failed to stopMeasure ${eventName}`, error)
  }
}
