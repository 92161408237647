import { round } from 'lodash'

export const frameToFormattedTime = (frameNumber: number, frameRate: number) => {
  const seconds = Math.floor(frameNumber / frameRate)
  const minutes = Math.floor(seconds / 60)
  const milliseconds = Math.floor((frameNumber / frameRate - seconds) * 100)

  const secondsFormatted = String(seconds % 60).padStart(2, '0')
  const minutesFormatted = String(minutes).padStart(2, '0')
  const millisecondsFormatted = String(milliseconds).padStart(2, '0')

  return `${minutesFormatted}:${secondsFormatted}.${millisecondsFormatted}`
}

export const frameToSeconds = (frameNumber: number, frameRate: number, decimals?: number) => {
  return round(frameNumber / frameRate, decimals ?? 2)
}

export const secondsToFormattedTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const secondsFormatted = String(Math.floor(seconds % 60)).padStart(2, '0')
  const minutesFormatted = String(minutes).padStart(2, '0')
  const millisecondsFormatted = String(Math.floor((seconds - Math.floor(seconds)) * 100)).padStart(
    2,
    '0'
  )

  return `${minutesFormatted}:${secondsFormatted}.${millisecondsFormatted}`
}

export const formatMillisecondsToMinutes = (milliseconds: number): string => {
  const minutes = Math.floor(milliseconds / 60000).toString()
  const seconds = ((milliseconds % 60000) / 1000).toFixed(0).padStart(2, '0')
  return `${minutes.padStart(2, '0')}:${seconds}`
}
