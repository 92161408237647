import { useQuery } from '@tanstack/react-query'
import { endpoints } from '@training/constants'
import axios from 'axios'
import { GetDevicesResponse } from '../types'

export const devicesApiUrl = endpoints.VITE_DEVICES_API

export function useGetDevices(orgId: string) {
  const getData = async () => {
    const { data } = await axios.get<GetDevicesResponse>(`${devicesApiUrl}/v1`, {
      params: { orgId },
    })
    return data
  }
  return useQuery({
    queryKey: ['getDevices'],
    queryFn: () => getData(),
    enabled: !!orgId,
    gcTime: 1000 * 60 * 5,
  })
}
