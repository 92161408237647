import { queryClient } from '@training/apis/query-client'
import { useGetSystemIds } from '@training/apis/waveSync/requests'
import { useCallback, useEffect, useState } from 'react'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

export const useWaveSyncSessionStore = create<{
  username: string
}>()(
  persist(
    devtools(() => ({
      username: '',
    })),
    {
      name: 'wave-sync-session',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export const useWaveSyncSession = () => {
  const getSystems = useGetSystemIds({ enabled: false })

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const { username } = useWaveSyncSessionStore()

  const switchUser = useCallback((newUsername: string) => {
    useWaveSyncSessionStore.setState({ username: newUsername })
  }, [])

  useEffect(() => {
    if (getSystems.data?.email) {
      setIsLoggedIn(true)
      switchUser(getSystems.data?.email)
    }
  }, [getSystems.data?.email, switchUser])

  const logout = useCallback(() => {
    setIsLoggedIn(false)
    switchUser('')
    queryClient.resetQueries({ queryKey: ['getSystemIds'] })
  }, [setIsLoggedIn, switchUser])

  return {
    isLoggedIn,
    logout,
    username,
    switchUser,
  }
}
