import { useMemo, useRef, useCallback, useEffect, useState } from 'react'
import { useSnackbar } from '@htaic/cue'
import VideoViewer from '@training/pages/Project/VideoViewer/VideoViewer'
import { ProjectVersion, VideoFile } from '@training/types'
import { getVideoOptions } from '@training/utils/getVideoOptions'
import {
  useCreateAnnotations,
  useDeleteAnnotation,
  useGetAnnotationMarkersV2,
  useGetProjectVersionAnnotationCount,
  useGetVideoAnnotations,
  useGetVideoMetadata,
  useUpdateAnnotations,
} from '@training/apis/annotations/requests'
import { matchPath, useParams } from 'react-router-dom'
import { filter, groupBy, head, isEmpty, isNil, orderBy, round } from 'lodash'
import { toAnnotationConfigList } from '@training/utils/toAnnotationConfigList'
import type { AnnotationMarker, CreateAnnotationsPayload } from '@training/apis/types'
import { VideoViewerPlaceholder } from '@training/components/VideoViewerPlaceholder'
import { useQueryClient } from '@tanstack/react-query'
import { useVideoPlayer, useVideoPlayerInstance } from '@training/hooks/useVideoPlayer'
import {
  ModifiedAnnotation,
  VideoAnnotation,
  useSaveVideoAnnotations,
  useVideoAnnotations,
} from '@training/hooks/useVideoAnnotations'
import { cropImage, normalizeRectangle } from '@training/utils/cropImage'
import { loadImage } from '@training/utils/loadImage'
import { scaleRect } from '@training/utils/scaleRect'
import { useAppState } from '@training/hooks/useAppState'
import { twMerge } from 'tailwind-merge'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import {
  endpoints,
  isImproveViewMode,
  projectViewModes,
  verificationLabelStatus,
} from '@training/constants'
import {
  assetsApiQueryKeys,
  useModifyVerificationFile,
} from '@training/apis/training-assets/requests'

import { useGetVideoThumbnails } from '@training/apis/videoFiles/requests'
import { frameToSeconds } from '@training/utils/videoTimeConverter'
import { useProjectCompareStore } from '@training/hooks/useCompareMode'
import { useStartProjectValidation } from '@training/apis/projects/requests'
import { startMeasure, stopMeasure } from '@training/utils/performance'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { convertGeneratedAnnotations } from '@training/utils/convertGeneratedAnnotations'
import { AnnotationThumbnails } from './AnnotationThumbnails'
import { AnnotateAreaActions } from './AnnotateAreaActions'

interface AnnotateAreaProps {
  clip: VideoFile
  objClassId?: string
  projectVersionId: string
  projectVersions: ProjectVersion[]
  lastTrainedProjectVersionId?: string
  overlayMessage?: string
}

const extractAnnotationIds = (annotations: AnnotationMarker[]) => {
  return new Set(
    annotations.flatMap((item) =>
      item.objectLocations.map((location) => location.id ?? location.locId)
    )
  )
}

const AnnotateArea = ({
  clip,
  objClassId,
  projectVersionId,
  projectVersions,
  overlayMessage,
  lastTrainedProjectVersionId,
}: AnnotateAreaProps) => {
  const queryClient = useQueryClient()
  const { id: projectId } = useParams()

  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const createAnnotations = useCreateAnnotations(projectId)
  const deleteAnnotation = useDeleteAnnotation(projectId!)
  const updateAnnotations = useUpdateAnnotations(projectId)
  const { refetch: refetchIsTrainable } = useGetProjectVersionAnnotationCount(projectVersionId)

  // untrained annotations
  const getPreAnnotationMarkers = useGetAnnotationMarkersV2(
    lastTrainedProjectVersionId || projectVersionId,
    clip.verifiedJsonUrl && clip.id
  )
  const getAnnotationMarkers = useGetAnnotationMarkersV2(projectVersionId, clip.id, {
    keepPreviousData: true,
  })

  const suggestedFrames = useMemo(
    () => orderBy(getPreAnnotationMarkers.data?.foi, (foi) => foi.frameNumber, ['asc']),
    [getPreAnnotationMarkers.data?.foi]
  )

  // trained annotations
  const getPreVideoAnnotations = useGetVideoAnnotations(
    clip.prevVerifiedJsonUrl ? clip.prevVerifiedJsonUrl : undefined
  )
  const getVideoAnnotations = useGetVideoAnnotations(
    clip.verifiedJsonUrl ? clip.verifiedJsonUrl : undefined
  )
  const verifiedJsonUrl = useMemo(
    () =>
      matchPath(
        { path: '/training-assets/verification-labels/v1/:verificationLabelId/json' },
        clip.verifiedJsonUrl ? new URL(clip.verifiedJsonUrl).pathname : ''
      ),
    [clip.verifiedJsonUrl]
  )

  const showSnackBar = useSnackbar()

  const getVideoMetadata = useGetVideoMetadata(clip)
  const shouldRequestMetadata = !clip.width || !clip.height || !clip.frameRate
  const metadata = useMemo(
    () =>
      shouldRequestMetadata
        ? getVideoMetadata.data
        : { resolution: { width: clip.width, height: clip.height }, frameRate: clip.frameRate },
    [clip.width, clip.height, clip.frameRate, getVideoMetadata.data, shouldRequestMetadata]
  )

  const inferenceAnnotations = useMemo(
    () => convertGeneratedAnnotations(getVideoAnnotations.data || {}),
    [getVideoAnnotations.data]
  )
  const userGeneratedAnnotations = useMemo(() => {
    const currentVersionAnnotations = filter(
      getAnnotationMarkers.data?.annotations,
      (annotation) => !!annotation.canDeleteLocation
    )
    return toAnnotationConfigList(currentVersionAnnotations)
  }, [getAnnotationMarkers.data])

  const projectViewMode = useAppState((state) => state.projectViewMode)

  const isVideoTrained = useVideoPlayer()((state) => state.isTrained)

  const combinedAnnotations =
    isVideoTrained && projectVersions[0].status === 'annotated'
      ? inferenceAnnotations.concat(userGeneratedAnnotations)
      : inferenceAnnotations

  const convertedAnnotations =
    clip.verifiedJsonUrl && projectViewMode === projectViewModes.REVIEW
      ? combinedAnnotations
      : userGeneratedAnnotations

  const convertedPreAnnotations = useMemo(
    () =>
      clip.prevVerifiedJsonUrl
        ? convertGeneratedAnnotations(getPreVideoAnnotations.data || {})
        : [],
    [getPreVideoAnnotations.data, clip.prevVerifiedJsonUrl]
  )

  const setFrameRate = useVideoPlayer()((state) => state.setFrameRate)
  const setVideoFilename = useVideoPlayer()((state) => state.setVideoFilename)
  const setComparePlayerEnabled = useAppState((state) => state.setComparePlayerEnabled)
  const setPlaying = useVideoPlayer()((state) => state.setPlaying)
  const clearModifiedAnnotations = useVideoAnnotations()((state) => state.clearModifiedAnnotations)
  const clearAnnotationsToTighten = useVideoAnnotations()(
    (state) => state.clearAnnotationsToTighten
  )

  useEffect(() => {
    return () => {
      clearModifiedAnnotations()
      clearAnnotationsToTighten()
    }
  }, [clearModifiedAnnotations, clearAnnotationsToTighten])

  useEffect(() => {
    setComparePlayerEnabled(!!clip.prevVerifiedJsonUrl)
  }, [clip.prevVerifiedJsonUrl, setComparePlayerEnabled])

  useEffect(() => {
    setFrameRate(metadata?.frameRate ?? 0)
  }, [metadata?.frameRate, setFrameRate])

  useEffect(() => {
    // Used by "tighten" payload
    setVideoFilename(clip.storageFileName)
  }, [clip.storageFileName, orgId, projectId, setVideoFilename])

  useEffect(() => {
    setPlaying(false)
  }, [setPlaying, clip.id])

  const setDesiredTime = useVideoPlayer()((state) => state.setDesiredTime)
  const frameRate = useVideoPlayer()((state) => state.frameRate)

  const hasPendingSuggestedFrame = !!suggestedFrames.find(
    (suggestedFrame) => !suggestedFrame.isSavedToAnnotations
  )

  const getCurrentFrame = useVideoPlayer()((state) => state.getCurrentFrame)

  // set the desired time to the first pending suggested frame
  useEffect(() => {
    const isInSuggestedFrame = suggestedFrames.find(
      (suggestedFrame) => suggestedFrame.frameNumber === getCurrentFrame().rounded
    )
    if (
      projectViewMode !== projectViewModes.IMPROVE ||
      suggestedFrames.length === 0 ||
      isInSuggestedFrame
    ) {
      return
    }

    if (hasPendingSuggestedFrame) {
      const pendingSuggestedFrame = suggestedFrames.find(
        (suggestedFrame) => !suggestedFrame.isSavedToAnnotations
      )?.frameNumber

      if (isNil(pendingSuggestedFrame)) return

      const timestamp = frameToSeconds(pendingSuggestedFrame, frameRate, 3)
      setDesiredTime(timestamp)
    } else {
      setDesiredTime(
        frameToSeconds(suggestedFrames[suggestedFrames.length - 1].frameNumber, frameRate, 3)
      )
    }
    // No need to run this effect when suggestedFrames changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setDesiredTime,
    frameRate,
    hasPendingSuggestedFrame,
    projectViewMode,
    getCurrentFrame,
    suggestedFrames.length,
  ])

  const videoFrameRef = useRef<HTMLCanvasElement>()

  const getDimensions = useVideoPlayerInstance()((state) => state.getDimensions)
  const getCurrentTime = useVideoPlayerInstance()((state) => state.getCurrentTime)

  const setComparisonMode = useVideoPlayer()((state) => state.setComparisonMode)
  const isComparisonMode = useVideoPlayer()((state) => state.isComparisonMode)

  const projectsCompareMode = useProjectCompareStore((state) => state.projectsCompareMode)

  useEffect(() => {
    setComparisonMode(false)
  }, [setComparisonMode, clip.id])

  useEffect(() => {
    if (projectViewMode === projectViewModes.REVIEW && !!clip.prevVerifiedJsonUrl) {
      const isProjectCompareMode = projectsCompareMode.get(projectId!)
      if (isProjectCompareMode !== undefined) {
        setComparisonMode(isProjectCompareMode)
      } else {
        setComparisonMode(true)
      }
    }
  }, [projectViewMode, clip.prevVerifiedJsonUrl, setComparisonMode, projectId, projectsCompareMode])

  const captureFrame = useVideoPlayerInstance()((state) => state.captureFrame)

  const getPayload = useCallback(
    async ({
      frameNumber,
      frameAnnotations,
    }: {
      frameNumber: number
      frameAnnotations: VideoAnnotation[]
    }) => {
      const videoDimensions = getDimensions()
      const dimensions = metadata?.resolution || videoDimensions.resolution

      const frameCaptureUrl =
        videoFrameRef.current?.toDataURL('image/png') ??
        captureFrame(dimensions)?.toDataURL('image/png')

      if (!frameCaptureUrl) throw new Error('Could not capture frame')

      const baseImage = await loadImage(frameCaptureUrl)

      const scaledFrameAnnotations = frameAnnotations.map((annotation) =>
        scaleRect(videoDimensions.displayedSize, dimensions, annotation)
      )

      // filter out placeholder annotation for "Object Not Present" case
      const validAnnotations = scaledFrameAnnotations.filter(
        (annotation) => annotation.width > 0 && annotation.height > 0
      )

      const frameSnippets = validAnnotations.map((frameAnnotation) =>
        cropImage(baseImage, frameAnnotation)
      )

      const currentTime = getCurrentTime()
      return {
        org_id: orgId,
        project_id: projectId!,
        user_id: userId,
        image_width: metadata?.resolution.width ?? 0,
        image_height: metadata?.resolution.height ?? 0,
        image_type: 'image/png',
        thumbnails: frameSnippets,
        thumbnails_width: 60,
        thumbnails_height: 60,
        thumbnails_type: 'image/png',
        timeline_in_seconds: currentTime,
        source_video: {
          video_id: clip.id,
          timeline_marker: frameNumber,
        },
        object_locations: validAnnotations.map((annotation, index) => {
          const { x, y, width, height } = normalizeRectangle(annotation)

          const coordinates = [
            [x, y], // bottom left
            [x + width, y], // bottom right
            [x + width, y + height], // top right
            [x, y + height], // top left
            [x, y], // bottom left again to close the polygon
          ]

          return {
            obj_class_id: objClassId ?? '563ae6c6-2d9a-11ee-be56-0242ac120002',
            id: objClassId ?? '563ae6c6-2d9a-11ee-be56-0242ac120002',
            thumbnail: frameSnippets[index],
            geometry: {
              type: 'Polygon',
              coordinates,
            },
          }
        }),
        foi_id:
          projectViewMode === projectViewModes.IMPROVE
            ? suggestedFrames.find((item) => item.frameNumber === frameNumber)?.foiId
            : undefined,
      } satisfies CreateAnnotationsPayload
    },
    [
      getDimensions,
      getCurrentTime,
      orgId,
      projectId,
      userId,
      metadata?.resolution,
      clip.id,
      projectViewMode,
      suggestedFrames,
      objClassId,
      captureFrame,
    ]
  )

  const modifiedAnnotations = useVideoAnnotations()((state) => state.modifiedAnnotations)

  const modifiedAnnotationsList = useMemo(
    () => Array.from(modifiedAnnotations.values()),
    [modifiedAnnotations]
  )

  const annotationsToTighten = useVideoAnnotations()((state) => state.annotationsToTighten)

  const groupOperations = useCallback((annotations: ModifiedAnnotation[]) => {
    const adds: ModifiedAnnotation[] = []
    const updates: ModifiedAnnotation[] = []
    const deletes: ModifiedAnnotation[] = []

    annotations.forEach((annotation) => {
      switch (annotation.type) {
        case 'add':
          adds.push(annotation)
          break
        case 'update':
          updates.push(annotation)
          break
        case 'delete':
          deletes.push(annotation)
          break
        default:
      }
    })

    return {
      adds,
      updates,
      deletes,
    }
  }, [])

  const modifyVerificationFile = useModifyVerificationFile(
    verifiedJsonUrl?.params.verificationLabelId ?? ''
  )

  const currentAnnotations = useVideoAnnotations()((state) => state.annotations)

  const determineOperation = useCallback(
    async (annotations: VideoAnnotation[], pendingAnnotations: ModifiedAnnotation[]) => {
      const originalIds = extractAnnotationIds(getAnnotationMarkers.data?.annotations ?? [])

      const { adds, updates, deletes } = groupOperations(pendingAnnotations)

      const hasInvalidOperations =
        pendingAnnotations.length !== 0 &&
        adds.length === 0 &&
        updates.length === 0 &&
        deletes.length === 0

      if (hasInvalidOperations) return null

      // each request is for a single frame so we can take the first frameStart
      const frameStart = head(pendingAnnotations)?.item.frameStart ?? getCurrentFrame().rounded

      const frameAnnotations = annotations.filter(
        (annotation) => annotation.frameStart === frameStart
      )

      const frameAnnotationsByType = groupBy(
        frameAnnotations,
        (annotation) => annotation.type
      ) as Record<NonNullable<VideoAnnotation['type']>, VideoAnnotation[] | undefined>

      const getUserAnnotationsToSave = () => {
        // if there are no annotations in the frame, use pending annotations (probably a deletion operation)
        if (isEmpty(frameAnnotationsByType.annotation)) {
          return pendingAnnotations.map((item) => item.item)
        }
        const userAnnotationsIds = new Set(
          (frameAnnotationsByType.annotation ?? []).map((annotation) => annotation.id)
        )
        // merge pending annotations with existing for update, remove deleted annotations to not include them in update
        return (frameAnnotationsByType.annotation ?? []).concat(
          pendingAnnotations
            .filter((item) => !userAnnotationsIds.has(item.item.id) && item.type !== 'delete')
            .map((item) => item.item)
        )
      }
      const modifiedAndFrameAnnotationIds = new Set(
        (frameAnnotationsByType.annotation ?? [])
          .map((annotation) => annotation.annotationId)
          .concat(pendingAnnotations.map((item) => item.item.annotationId))
      )

      const userAnnotationsToSave = getUserAnnotationsToSave()

      const annotationGroup = getAnnotationMarkers.data?.annotations.find((annotation) =>
        annotation.objectLocations.find((location) =>
          modifiedAndFrameAnnotationIds.has(location.locId ?? location.id)
        )
      )

      const payload = await getPayload({
        frameNumber: annotationGroup?.videoTimelineMarker ?? frameStart,
        frameAnnotations: userAnnotationsToSave,
      })

      const isObjectNotPresentOperation = pendingAnnotations.length === 0

      // if there are annotations to create or there are no pending annotations (object not present operation)
      if ((!annotationGroup && adds.length > 0) || isObjectNotPresentOperation) {
        return {
          operation: async () => {
            await createAnnotations.mutateAsync({
              ...payload,
              object_locations: isObjectNotPresentOperation ? [] : payload.object_locations,
            })
          },
          count: adds.length,
        }
      }

      const hasDeletes = originalIds.size > 0 && annotationGroup
      if (hasDeletes) {
        const hasDeletedAll =
          deletes.length === annotationGroup.objectLocations.length &&
          updates.length === 0 &&
          adds.length === 0

        if (hasDeletedAll) {
          return {
            operation: async () => {
              await deleteAnnotation.mutateAsync({ annotationId: annotationGroup.annotationId })
            },
            count: -deletes.length,
          }
        }

        const hasUpdates = adds.length > 0 || deletes.length > 0 || updates.length > 0
        if (hasUpdates) {
          return {
            operation: async () => {
              await updateAnnotations.mutateAsync({
                ...payload,
                annotation_id: annotationGroup?.annotationId,
              })
            },
            count: adds.length - deletes.length,
          }
        }
      }

      return null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getAnnotationMarkers.data,
      groupOperations,
      getCurrentFrame,
      getDimensions,
      getPayload,
      clip.verifiedJsonUrl,
      clip.id,
      modifyVerificationFile,
      createAnnotations,
      projectId,
      getVideoAnnotations,
      deleteAnnotation,
      updateAnnotations,
      projectVersionId,
    ]
  )
  const saveTimeoutRef = useRef<NodeJS.Timeout>()

  const handleSaveAnnotations = useCallback(
    async (annotations: VideoAnnotation[], pendingAnnotations: ModifiedAnnotation[]) => {
      try {
        const result = await determineOperation(annotations, pendingAnnotations)
        if (!result) return

        stopMeasure('save-annotations-payload')

        const { operation } = result

        startMeasure('save-annotations')
        await operation()
        stopMeasure('save-annotations')

        startMeasure('refetch-annotations')
        queryClient.invalidateQueries({
          queryKey: [assetsApiQueryKeys.getAnnotationThumbnails, projectId],
        })
        queryClient.invalidateQueries({
          queryKey: [assetsApiQueryKeys.getProjectVideos, projectId],
        })

        // don't refetch annotations if there is a pending save
        if (!saveTimeoutRef.current) {
          if (clip.verifiedJsonUrl) {
            await getPreAnnotationMarkers.refetch()
          }

          await getAnnotationMarkers.refetch()
          refetchIsTrainable()
        }
        stopMeasure('refetch-annotations')
      } catch (error) {
        console.error('Error trying to save/refetch annotations.', error)
        showSnackBar({
          message: 'Could not complete the operation',
          status: 'error',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      clip.id,
      clip.verifiedJsonUrl,
      determineOperation,
      getAnnotationMarkers,
      getVideoAnnotations,
      getPreAnnotationMarkers,
      projectId,
    ]
  )

  const currentAnnotationsList = useMemo(
    () => Array.from(currentAnnotations.values()),
    [currentAnnotations]
  )

  const autosaveAnnotationsFeature = useFeatureFlags().ANNOTATION_AUTOSAVE

  // save annotations after 2 seconds of inactivity
  useEffect(() => {
    if (modifiedAnnotationsList.length === 0) return

    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)

    const videoFrame = captureFrame(metadata?.resolution ?? getDimensions().resolution)

    if (videoFrame) videoFrameRef.current = videoFrame

    if (!autosaveAnnotationsFeature) {
      return
    }

    const timeout = setTimeout(() => {
      clearModifiedAnnotations()
      clearAnnotationsToTighten()
      saveTimeoutRef.current = undefined
      handleSaveAnnotations(currentAnnotationsList, modifiedAnnotationsList)
    }, 2000)
    saveTimeoutRef.current = timeout
  }, [
    autosaveAnnotationsFeature,
    modifiedAnnotationsList,
    currentAnnotationsList,
    handleSaveAnnotations,
    metadata?.resolution,
    captureFrame,
    clearModifiedAnnotations,
    clearAnnotationsToTighten,
  ])

  const isPlaying = useVideoPlayer()((state) => state.isPlaying)
  const isSeeking = useVideoPlayer()((state) => state.isSeeking)
  const isStartingTraining = useAppState((state) => state.isStartingTraining)
  const thumbnailsFeature = useFeatureFlags().THUMBNAILS_SCRUBBING
  const { data } = useGetVideoThumbnails(thumbnailsFeature ? clip.id : undefined)
  const scrubbedImageFiles = data?.scrubbedImageFiles
  const mediaType = data?.mediaType ?? 'video/mp4'
  const url = `${endpoints.VITE_ASSETS_API}${data?.streamUrl}`
  const playerOptions = useMemo(
    () =>
      getVideoOptions({
        url,
        type: mediaType,
      }),
    [url, mediaType]
  )

  const { requestSave, triggerSave, forceSave } = useSaveVideoAnnotations()

  const projectValidateMutation = useStartProjectValidation(lastTrainedProjectVersionId as string)
  const { videoClipMetadata } = useAppState((state) => ({
    videoClipMetadata: state.videoClipMetadata,
    setVideoClipMetadata: state.setVideoClipMetadata,
  }))
  const [isReapplying, setIsReapplying] = useState(false)

  const onReapplyHandler = async () => {
    try {
      await projectValidateMutation.mutateAsync({
        action: 'start',
        videoFileName: `${clip.id}.mp4`,
      })
      setIsReapplying(true)
      showSnackBar({
        message: `Reapplying model on ${clip.name}.`,
        status: 'info',
      })
      await queryClient.invalidateQueries({ queryKey: ['getProjectVideos'] })
    } catch (error: any) {
      showSnackBar({
        message: `Model failed to apply on ${clip.name}.`,
        status: 'error',
      })
      setIsReapplying(false)
    }
  }

  // listen for save request
  useEffect(() => {
    if ((modifiedAnnotationsList.length === 0 && !forceSave) || !requestSave) return

    clearModifiedAnnotations()

    // remove force so next time it just returns
    triggerSave(true, false)

    document.documentElement.classList.add('cursor-wait')
    document.body.classList.add('pointer-events-none')

    handleSaveAnnotations(currentAnnotationsList, modifiedAnnotationsList).finally(() => {
      triggerSave(false)

      document.documentElement.classList.remove('cursor-wait')
      document.body.classList.remove('pointer-events-none')
    })
  }, [
    forceSave,
    modifiedAnnotationsList,
    currentAnnotationsList,
    handleSaveAnnotations,
    clearModifiedAnnotations,
    requestSave,
    triggerSave,
  ])

  useEffect(() => {
    if (annotationsToTighten.length === 0) return
    clearAnnotationsToTighten()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAnnotationsToTighten])

  // save as soon as the user plays the video or seeks or is starting training
  useEffect(() => {
    if (modifiedAnnotationsList.length === 0) return

    if ((!isStartingTraining && !isSeeking && !isPlaying) || !autosaveAnnotationsFeature) {
      return
    }

    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current)
      saveTimeoutRef.current = undefined
    }

    clearModifiedAnnotations()
    clearAnnotationsToTighten()
    handleSaveAnnotations(currentAnnotationsList, modifiedAnnotationsList)
  }, [
    autosaveAnnotationsFeature,
    isStartingTraining,
    isSeeking,
    isPlaying,
    modifiedAnnotationsList,
    currentAnnotationsList,
    handleSaveAnnotations,
    clearModifiedAnnotations,
    clearAnnotationsToTighten,
  ])

  const hasInferenceErrors = useMemo(() => {
    return (
      (clip.verificationLabels?.filter(
        (vl) => vl.recency === 'current' && vl.status === verificationLabelStatus.ERROR
      ).length > 0 &&
        lastTrainedProjectVersionId !== undefined) ||
      (videoClipMetadata.status === 'inference-error' && videoClipMetadata.videoFileId === clip.id)
    )
  }, [
    clip.id,
    clip.verificationLabels,
    lastTrainedProjectVersionId,
    videoClipMetadata.status,
    videoClipMetadata.videoFileId,
  ])

  const renderContent = () => {
    if (!clip.id) return <VideoViewerPlaceholder overlayMessage={overlayMessage} />

    if (
      // don't show the loading overlay if the user is in the process of saving
      (!requestSave && getAnnotationMarkers.isPending) ||
      getVideoAnnotations.isLoading ||
      getPreVideoAnnotations.isLoading ||
      (shouldRequestMetadata && getVideoMetadata.isPending)
    )
      return <VideoViewerPlaceholder overlayMessage='Loading...' />

    return (
      <>
        <div className={twMerge(`grid gap-4`, isComparisonMode && 'grid-cols-2')}>
          <AnnotateAreaActions
            isComparisonMode={isComparisonMode}
            title={clip.name}
            suggestedFrames={suggestedFrames}
            hasInferenceErrors={hasInferenceErrors && !isReapplying}
            onReapplyModel={onReapplyHandler}
          />
        </div>
        <div className={twMerge(`grid gap-4 grow shrink-0`, isComparisonMode && 'grid-cols-2')}>
          {isComparisonMode && (
            <VideoViewer
              videoFileId={clip.id}
              playerOptions={playerOptions}
              isTrained={isVideoTrained}
              initialAnnotations={convertedPreAnnotations}
              dimensions={metadata?.resolution}
              frameRate={round(Number(metadata?.frameRate ?? 20), 2)}
              isComparisonMode
              disableDrawMode={isComparisonMode || projectViewMode === projectViewModes.REVIEW}
              projectId={projectId}
            />
          )}
          <VideoViewer
            videoFileId={clip.id}
            playerOptions={playerOptions}
            isTrained={isVideoTrained}
            initialAnnotations={convertedAnnotations}
            dimensions={metadata?.resolution}
            frameRate={Number(metadata?.frameRate ?? 20)}
            disableDrawMode={
              (isVideoTrained && !verifiedJsonUrl?.params.verificationLabelId) ||
              projectViewMode === projectViewModes.REVIEW
            }
            thumbnails={scrubbedImageFiles}
            suggestedFrames={suggestedFrames}
            projectId={projectId}
          />
        </div>

        {isComparisonMode && (
          <div className='p-4 border border-dashed rounded-lg border-border-default'>
            <AnnotationThumbnails
              className='flex-row mx-0'
              clipId={clip.id}
              clipDimensions={metadata?.resolution}
              isTrained={!!clip.verifiedJsonUrl}
              projectId={projectId}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div
      data-testid='annotate-area'
      className={twMerge(
        `flex flex-col grow shrink-0 pb-12`,
        isImproveViewMode(projectViewMode) && 'px-8 pt-16'
      )}
    >
      {renderContent()}
    </div>
  )
}

export default AnnotateArea
