import { Icon } from '@htaic/cue'
import { twMerge } from 'tailwind-merge'

interface ProgressIndicatorProps extends React.HTMLAttributes<HTMLButtonElement> {
  completed: boolean
  selected: boolean
}

export const ProgressIndicator = (props: ProgressIndicatorProps) => {
  const { completed, selected, ...rest } = props

  return (
    <button
      {...rest}
      type='button'
      className={twMerge(
        'bg-primary-900 cursor-pointer border-0 p-0 m-0 w-6 h-6 rounded-full text-white inline-flex items-center justify-center',
        completed && 'bg-primary-500',
        selected && 'border-semantic-primary border-2 border-solid'
      )}
    >
      {completed ? <Icon name='Done' className='h-5 text-white' /> : null}
    </button>
  )
}
