import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Icon, Typography, Tag } from '@htaic/cue'
import useFormattedDateTime from '@training/hooks/useFormattedDateTime'
import { Project } from '@training/types'
import { PROJECT_STATUS, PROJECT_COMPILED_STATUS, projectStatusStyles } from '@training/constants'
import { twMerge } from 'tailwind-merge'
import { getProject } from '@training/apis/projects/requests'
import { queryClient } from '@training/apis/query-client'
import { useModalState } from '@training/hooks/useModal'
import ProjectModal from './ProjectModal'

interface ProjectCardProps {
  project: Project
}

const updateProjectStatus = async (projectId: string) => {
  const updatedProject = await getProject(projectId)

  queryClient.setQueryData<Project[]>(['getProjects'], (oldProjects) => {
    if (!oldProjects) return oldProjects

    const { version: updatedVersion = [] } = updatedProject
    const [updatedLastVersion] = updatedVersion

    return oldProjects.map((oldProject) => {
      if (oldProject.id === projectId) {
        const [lastProjectVersion, ...restVersions] = oldProject.version ?? []

        return {
          ...oldProject,
          version: [
            {
              ...lastProjectVersion,
              status: updatedLastVersion.status,
            },
            ...restVersions,
          ],
        }
      }
      return oldProject
    })
  })
}

const ProjectCard = ({ project }: ProjectCardProps) => {
  const { id, name, version, notes } = project

  const navigate = useNavigate()
  const [showNotesModal, setShowNotesModal] = useState(false)

  const downloadModelVersion =
    project.version?.find((v) => v.status === 'trained') ??
    project.version?.[1] ??
    project.version?.[0]

  const isCompileStarted = downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.started

  const loadProject = (projectId: string) => {
    if (projectStatus === 'in_training' || isCompileStarted) {
      updateProjectStatus(id)
    }

    navigate(`project/${projectId}`)
  }

  const cardHasNotes = useMemo(() => notes?.length > 0, [notes])

  const lastProjectVersion = version[0]
  const lastTrainedVersion = useMemo(
    () => version.find((ver) => ver.status === 'trained'),
    [version]
  )

  const projectStatus = useMemo(() => {
    return (lastProjectVersion?.version ?? -1) > 1 && lastProjectVersion?.status === 'init'
      ? 'trained'
      : (lastProjectVersion?.status ?? '')
  }, [lastProjectVersion?.status, lastProjectVersion?.version])

  const projectUpdatedAt = useMemo(
    () => lastTrainedVersion?.updatedAt,
    [lastTrainedVersion?.updatedAt]
  )

  const formattedUpdatedAt = useFormattedDateTime(projectUpdatedAt, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const displayLastTrainedTime = !!lastTrainedVersion

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const handleNotesClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    setShowNotesModal(!showNotesModal)

    openModal({
      size: 'lg',
      content: (
        <ProjectModal
          onClose={() => {
            setShowNotesModal(!showNotesModal)
            closeModal()
          }}
          isUpdate
          isNoteUpdate
          project={project}
        />
      ),
    })
  }

  return (
    <Card
      data-testid='project-card'
      spacing='large'
      title={name}
      onClick={() => loadProject(id)}
      className='text-neutral-grey-1 border border-solid dark:bg-neutral-grey-14 dark:border-neutral-grey-12 dark:hover:bg-neutral-grey-12 dark:hover:border-neutral-grey-10 bg-neutral-grey-16 border-neutral-grey-14 hover:bg-neutral-grey-15 hover:border-neutral-grey-14'
      actions={
        cardHasNotes
          ? [
              <div className='flex items-center' key='notes-button'>
                <button
                  type='button'
                  title={showNotesModal ? 'Hide Notes' : 'Show Notes'}
                  className='border-none bg-transparent cursor-pointer flex items-center'
                  onClick={(e) => handleNotesClick(e)}
                  data-testid='project-card-notes-button'
                >
                  <Icon name='Report' className='text-neutral-grey-0 dark:text-icon-default mr-1' />
                  <Typography variant='caption' className='text-semantic-primary font-bold'>
                    Notes
                  </Typography>
                </button>
              </div>,
            ]
          : undefined
      }
    >
      <div className='grid grid-cols-1'>
        <div className='flex justify-between'>
          <div className='flex justify-start gap-2 items-center overflow-hidden'>
            <Tag
              data-testid='tag'
              size='large'
              label={PROJECT_STATUS[projectStatus]}
              className={twMerge(
                'border border-solid truncate',
                projectStatusStyles?.[projectStatus as keyof typeof projectStatusStyles]
              )}
            />
            {displayLastTrainedTime && (
              <Typography
                variant='caption'
                className='font-Noto-Sans leading-[14px] text-semantic-secondary truncate'
              >
                Last Trained: {formattedUpdatedAt}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ProjectCard
