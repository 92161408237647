export interface Rect {
  x: number
  y: number
  width: number
  height: number
}

export const normalizeRectangle = (rect: Rect) => {
  let { x, y, width, height } = rect

  if (width < 0) {
    x += width // adjust x to be the leftmost point
    width = Math.abs(width) // make width positive
  }

  // Check if height is negative (drawn from bottom to top)
  if (height < 0) {
    y += height // adjust y to be the topmost point
    height = Math.abs(height) // make height positive
  }

  return { x, y, width, height }
}

export function cropImage(image: HTMLImageElement, rect: Rect): string {
  const { x, y, width, height } = normalizeRectangle(rect)

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')

  if (!context) {
    throw new Error('Failed to get canvas context')
  }

  context.drawImage(image, x, y, width, height, 0, 0, width, height)
  return canvas.toDataURL()
}
