import { Password, TextInput, ValidationError } from '@htaic/cue'
import { errorMessages } from '@training/constants'
import { useEffect, useState } from 'react'

interface WaveSyncLoginFormProps {
  onCredentialsChange?: ({ username, password }: { username: string; password: string }) => void
}

export const WaveSyncLoginForm = (props: WaveSyncLoginFormProps) => {
  const { onCredentialsChange } = props
  const [username, setUsername] = useState<string | null>(null)
  const [password, setPassword] = useState<string | null>(null)

  const handleUsernameChange = (value: string) => {
    setUsername(value)
  }

  const handlePasswordChange = (value: string) => {
    setPassword(value)
  }

  useEffect(() => {
    if (onCredentialsChange) {
      onCredentialsChange({ username: username ?? '', password: password ?? '' })
    }
  }, [onCredentialsChange, password, username])

  return (
    <div className='w-[500px] px-[30px] pt-8'>
      <div className='flex items-center justify-end mb-3'>
        <div>
          <span className='text-graph-red'>* Required</span>
        </div>
      </div>
      <div className='flex items-center mb-5'>
        <div className='w-[95px] text-right pr-1 pb-3 dark:text-white'>
          <span className='text-graph-red'>*</span>User ID:
        </div>
        <div className='h-10'>
          <TextInput
            data-testid='textInput-username'
            containerClassName='bg-neutral-grey-15 border-none w-[350px]'
            className='bg-neutral-grey-15 w-[350px]'
            onChange={(e) => handleUsernameChange(e.target.value)}
          />
          <ValidationError
            message={username === '' ? errorMessages.WAVESYNC_USER_ID_IS_REQUIRED : undefined}
          />
        </div>
      </div>
      <div className='flex items-center mb-5'>
        <div className='w-[95px] text-right pr-1 pb-3 dark:text-white'>
          <span className='text-graph-red'>*</span>Password:
        </div>
        <div className='h-10'>
          <Password
            data-testid='textInput-password'
            containerClassName='bg-neutral-grey-15 border-none w-[350px]'
            className='bg-neutral-grey-15 w-[350px]'
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          <ValidationError
            message={password === '' ? errorMessages.WAVESYNC_PASSWORD_IS_REQUIRED : undefined}
          />
        </div>
      </div>
    </div>
  )
}
