import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { endpoints } from '@training/constants'
import { ReferencePoint } from '@training/types'

export const assetsApiUrl = endpoints.VITE_ASSETS_API ?? 'http://localhost:3015'

export const referencePointsQueryKeys = {
  addReferencePoint: ['addReferencePoint'],
  deleteReferencePoint: ['deleteReferencePoint'],
  getReferencePoints: (videoFileId: string) => ['getReferencePoints', videoFileId] as const,
} as const

export function useGetReferencePoints(videoFileId: string) {
  const getData = async () => {
    const { data } = await axios.get<ReferencePoint[]>(
      `${assetsApiUrl}/video-files/v1/${videoFileId}/reference-points`
    )
    return data
  }
  return useQuery({
    queryKey: referencePointsQueryKeys.getReferencePoints(videoFileId),
    queryFn: () => getData(),
    enabled: !!videoFileId,
  })
}

export function useAddReferencePoint(videoFileId: string) {
  const getData = async (payload: Partial<ReferencePoint>) => {
    const { data } = await axios.post(
      `${assetsApiUrl}/video-files/v1/${videoFileId}/reference-points`,
      payload
    )
    return data
  }
  return useMutation({
    mutationKey: referencePointsQueryKeys.addReferencePoint,
    mutationFn: (payload: Partial<ReferencePoint>) => getData(payload),
  })
}

export function useDeleteReferencePoint(videoFileId: string) {
  const deleteReferencePoint = async (params: { id: string }) => {
    const { data } = await axios.delete(
      `${assetsApiUrl}/video-files/v1/${videoFileId}/reference-points/${params.id}`
    )
    return data
  }
  return useMutation({
    mutationKey: referencePointsQueryKeys.deleteReferencePoint,
    mutationFn: (params: { id: string }) => deleteReferencePoint(params),
  })
}
