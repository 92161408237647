import type { VideoAnnotation } from '@training/hooks/useVideoAnnotations'
import { compact, isNil, round } from 'lodash'
import type { TimelineMarker } from '../pages/Project/VideoViewer/VideoViewer'

export function convertFramesToProgress(
  annotations: Pick<VideoAnnotation, 'frameStart' | 'frameEnd' | 'timelineInSeconds' | 'id'>[],
  fps: number,
  duration: number
): TimelineMarker[] {
  const totalFrames = Math.floor(duration * fps)

  const converted = annotations.map((annotation) => {
    if (isNil(annotation.frameStart) || isNil(annotation.frameEnd)) {
      return null
    }
    const timeBasedStartPercentage = (round(annotation.timelineInSeconds ?? 0, 3) / duration) * 100

    const startPercentage = (annotation.frameStart / totalFrames) * 100
    const endPercentage = (annotation.frameEnd / totalFrames) * 100

    return {
      id: annotation.id,
      start: timeBasedStartPercentage || startPercentage,
      end: timeBasedStartPercentage || endPercentage,
      time: annotation.timelineInSeconds ?? annotation.frameStart / fps,
      frame: annotation.frameStart,
    }
  })

  return compact(converted)
}
