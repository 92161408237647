/* eslint-disable no-underscore-dangle */
import type VideoJsPlayer from 'video.js/dist/types/player'
import { create } from 'zustand'
import { createRef, useCallback, useMemo } from 'react'
import { devtools } from 'zustand/middleware'
import { isNaN } from 'lodash'
import type { Dimensions } from '@training/types'

export type VideoType = 'current' | 'candidate'

interface VideoPlayerStore {
  isPlaying: boolean
  isReady: boolean
  currentFrame: {
    rounded: number
    exact: number
  }
  currentTime: number
  playbackRate: string
  frameRate: number
  isSeeking: boolean
  videoType: VideoType
  isComparisonMode: boolean
  desiredTime: number | null
  isMetadataReady: boolean
  setReady: (isReady: boolean) => void
  setMetadataReady: (isMetadataReady: boolean) => void
  setCurrentTime: (time: number) => void
  setPlaying: (isPlaying: boolean) => void
  setCurrentFrame: ({ rounded, exact }: { rounded: number; exact: number }) => void
  setPlaybackRate: (rate: string) => void
  setFrameRate: (frameRate: number) => void
  setVideoFilename: (videoFilename: string) => void
  videoFilename: string
  setSeeking: (isSeeking: boolean) => void
  setComparisonMode: (isComparisonMode: boolean) => void
  getCurrentFrame: () => { rounded: number; exact: number }
  // used to pre-select a time when the video loads
  setDesiredTime: (time: number | null) => void
  isTrained: boolean
  setIsTrained: (isTrained: boolean) => void
}

const createVideoPlayerStore = (videoType: VideoType) => {
  return create<VideoPlayerStore>()(
    devtools((set, get) => ({
      isPlaying: false,
      isReady: false,
      currentFrame: {
        rounded: 0,
        exact: 0.0,
      },
      frameRate: 20,
      currentTime: 0,
      playbackRate: '1x',
      isSeeking: false,
      isComparisonMode: false,
      desiredTime: null,
      isMetadataReady: false,
      getCurrentFrame: () => get().currentFrame,
      setPlaying: (isPlaying) => set({ isPlaying }),
      setCurrentFrame: ({ rounded, exact }) => {
        set({
          currentFrame: { rounded, exact },
        })
      },
      setPlaybackRate: (rate) => set({ playbackRate: rate }),
      setFrameRate: (frameRate) => set({ frameRate }),
      setVideoFilename: (videoFilename) => set({ videoFilename }),
      videoFilename: '',
      isTrained: false,
      setIsTrained: (isTrained) => set({ isTrained }),
      setReady: (isMetadataReady) => set({ isMetadataReady }),
      setMetadataReady: (isReady) => set({ isReady }),
      setCurrentTime: (time) => set({ currentTime: time }),
      setSeeking: (isSeeking) => set({ isSeeking }),
      setComparisonMode: (isComparisonMode) => set({ isComparisonMode }),
      setDesiredTime: (time) => set({ desiredTime: time }),
      videoType,
    }))
  )
}

const videoPlayerStores = {
  current: createVideoPlayerStore('current'),
  candidate: createVideoPlayerStore('candidate'),
}

/**
 * Returns the video player actions and state for the specified video type.
 * @param videoType The type of video to get the player for. Defaults to 'candidate'.
 */
export const useVideoPlayer = (videoType: VideoType = 'candidate') => {
  return videoPlayerStores[videoType]
}

interface PlayerInstanceStore {
  instanceRef: React.MutableRefObject<VideoJsPlayer | null>
  forwardTen: () => void
  replayTen: () => void
  captureFrame: (dimensions: Dimensions) => HTMLCanvasElement | null
  getDimensions: () => {
    resolution: {
      width: number
      height: number
    }
    displayedSize: {
      width: number
      height: number
    }
  }
  play: () => Promise<void>
  pause: () => void
  getDuration: () => number
  getCurrentTime: () => number
  videoType: VideoType
}

/**
 * Captures a frame from a video element and returns it as a canvas.
 * @param videoElement - The HTMLVideoElement to capture the frame from.
 * @param dimensions - The dimensions of the video element. Dimensions must be passed because the native videoWidth and videoHeight properties are not reliable.
 * @returns A canvas containing the captured frame.
 */
const captureVideoFrame = (videoElement: HTMLVideoElement, dimensions: Dimensions) => {
  const canvas = document.createElement('canvas')
  canvas.width = (dimensions?.width || videoElement.videoWidth) ?? 0
  canvas.height = (dimensions?.height || videoElement.videoHeight) ?? 0

  const context = canvas.getContext('2d')
  context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height)
  return canvas
}

const createVideoPlayerInstanceStore = (videoType: VideoType) => {
  const instanceRef =
    createRef<VideoJsPlayer | null>() as React.MutableRefObject<VideoJsPlayer | null>
  instanceRef.current = null

  return create<PlayerInstanceStore>()(
    devtools((_, get) => ({
      instanceRef,
      play: async () => {
        const instance = get().instanceRef.current

        await instance?.play()
      },
      pause: () => {
        const instance = get().instanceRef.current

        instance?.pause()
      },
      getDimensions: () => {
        const videoElement = get().instanceRef.current?.el_.querySelector('video')
        return {
          resolution: {
            width: videoElement?.videoWidth ?? 0,
            height: videoElement?.videoHeight ?? 0,
          },
          displayedSize: {
            width: videoElement?.offsetWidth ?? 0,
            height: videoElement?.offsetHeight ?? 0,
          },
        }
      },
      getDuration: () => {
        const instance = get().instanceRef.current
        const duration = instance?.duration()

        if (isNaN(duration) || !duration) return 0

        return duration
      },
      getCurrentTime: () => {
        const instance = get().instanceRef.current
        const currentTime = instance?.currentTime()

        if (isNaN(currentTime) || !currentTime) return 0

        return currentTime
      },
      forwardTen: () => {
        const instance = get().instanceRef.current

        const currentTime = instance?.currentTime() ?? 0
        instance?.currentTime(currentTime + 10)
      },
      replayTen: () => {
        const instance = get().instanceRef.current

        const currentTime = instance?.currentTime() ?? 0
        instance?.currentTime(currentTime - 10)
      },
      captureFrame: (dimensions: Dimensions) => {
        const videoElement = get().instanceRef.current?.el_.querySelector('video')

        if (!videoElement) {
          return null
        }

        return captureVideoFrame(videoElement, dimensions)
      },
      videoType,
    }))
  )
}

/**
 * Object containing two instances of the video player store:
 * - current: store for the playing video representing the current (previous) model
 * - candidate: store for the playing video representing the candidate (new) model
 */
const videoPlayerInstanceStores = {
  current: createVideoPlayerInstanceStore('current'),
  candidate: createVideoPlayerInstanceStore('candidate'),
}

/**
 * Returns the video player instance for the specified video type.
 * @param videoType The type of video to get the player instance for. Defaults to 'candidate'.
 */
export const useVideoPlayerInstance = (videoType: VideoType = 'candidate') => {
  return videoPlayerInstanceStores[videoType]
}

/**
 * Custom hook that synchronizes actions of two video player instances.
 * @returns An object containing functions to control the synchronized video players.
 */
export const useSyncVideoPlayerInstances = () => {
  const candidatePlayer = useVideoPlayerInstance('candidate')()
  const currentPlayer = useVideoPlayerInstance('current')()

  const play = useCallback(async () => {
    await Promise.all([candidatePlayer.play(), currentPlayer.play()])
  }, [candidatePlayer, currentPlayer])

  const pause = useCallback(() => {
    candidatePlayer.pause()
    currentPlayer.pause()
  }, [candidatePlayer, currentPlayer])

  const forwardTen = useCallback(() => {
    candidatePlayer.forwardTen()
    currentPlayer.forwardTen()
  }, [candidatePlayer, currentPlayer])

  const replayTen = useCallback(() => {
    candidatePlayer.replayTen()
    currentPlayer.replayTen()
  }, [candidatePlayer, currentPlayer])

  /**
   * Sets the current time of both video players.
   * @param time - The time to set the video players to.
   */
  const setCurrentTime = useCallback(
    (time: string | number) => {
      candidatePlayer.instanceRef.current?.currentTime(time)
      currentPlayer.instanceRef.current?.currentTime(time)
    },
    [candidatePlayer, currentPlayer]
  )

  const syncCurrentTime = useCallback(() => {
    const candidateTime = candidatePlayer?.instanceRef.current?.currentTime()

    if (candidateTime) {
      currentPlayer?.instanceRef.current?.currentTime(candidateTime)
      candidatePlayer?.instanceRef.current?.currentTime(candidateTime)
    }
  }, [candidatePlayer?.instanceRef, currentPlayer?.instanceRef])
  /**
   * Sets the playback rate of both video players.
   * @param rate - The playback rate to set the video players to.
   */
  const setPlaybackRate = useCallback(
    (rate: number) => {
      candidatePlayer.instanceRef.current?.playbackRate(rate)
      currentPlayer.instanceRef.current?.playbackRate(rate)
    },
    [candidatePlayer, currentPlayer]
  )

  return useMemo(
    () => ({
      play,
      pause,
      forwardTen,
      replayTen,
      syncCurrentTime,
      setCurrentTime,
      setPlaybackRate,
    }),
    [play, pause, forwardTen, replayTen, syncCurrentTime, setCurrentTime, setPlaybackRate]
  )
}
