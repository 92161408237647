import { create } from 'zustand'

interface CompareMode {
  id: string
  compare: boolean
}

type CompareModeStore = {
  projectsCompareMode: Map<string, boolean>
  addProjectCompareMode: (compareMode: CompareMode) => void
}

export const useProjectCompareStore = create<CompareModeStore>((set, get) => ({
  projectsCompareMode: new Map(),
  addProjectCompareMode: (compareMode) => {
    const newMap = new Map(get().projectsCompareMode).set(compareMode.id, compareMode.compare)
    set({ projectsCompareMode: newMap })
  },
}))
