export const loadImage = (url: string, anonymous = true): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image()
    if (anonymous) {
      image.setAttribute('crossorigin', 'anonymous')
    }
    image.src = url
    image.onload = () => {
      resolve(image)
    }
  })
}
