import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { BoxifyPayload, BoxifyResponse } from '@training/apis/types'
import { endpoints } from '@training/constants'
import { getRedirectUrl } from '@training/utils/getRedirectUrl'
import { redirectWithOrgId } from '@training/utils/redirect'

export const boxifyApiUrl = endpoints.VITE_BOXIFY_API ?? 'http://localhost:8000'

export function useGetTightenAnnotations(orgId: string, projectId: string) {
  const cloudPlatformLoginUrl = getRedirectUrl()
  if (!projectId) {
    throw new Error('Missing required params')
  }
  if (!orgId) {
    redirectWithOrgId(cloudPlatformLoginUrl)
  }
  const getData = async (payload: BoxifyPayload) => {
    const { data } = await axios.post<BoxifyResponse>(
      `${boxifyApiUrl}/v1/assisted/${orgId}/${projectId}`,
      payload
    )
    return data
  }
  return useMutation({
    mutationKey: ['tightenAnnotations', orgId, projectId],
    mutationFn: (payload: BoxifyPayload) => getData(payload),
  })
}
