import { Button, ModalContent, ModalFooter, ModalHeader, Typography } from '@htaic/cue'

interface ConfirmSaveModalProps {
  onDiscard: () => void
  onSave: () => void
}

export const ConfirmSaveModal = (props: ConfirmSaveModalProps) => {
  const { onSave, onDiscard, ...rest } = props

  return (
    <ModalContent data-testid='confirm-save-modal' {...rest}>
      <ModalHeader closable>Save bounding boxes?</ModalHeader>
      <Typography variant='body2' className='py-5 text-center'>
        Your latest annotations will not be added to the database if you do not click Save.
      </Typography>
      <ModalFooter className='flex justify-between'>
        <Button variant='outlined' color='neutral' onClick={onDiscard}>
          Continue Without Saving
        </Button>
        <Button variant='outlined' color='primary' onClick={onSave}>
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
