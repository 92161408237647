import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { endpoints, errorMessages } from '@training/constants'
import { useMinimeState } from '@training/hooks/useMinimeState'
import type {
  BookmarkPayload,
  WaveSyncLoginResponse,
  WaveSyncUploadVideoResponse,
  WaveSyncVideo,
} from '../types'

export const waveSyncApiUrl = endpoints.VITE_WAVESYNC_API ?? 'http://localhost:3018'

interface UseGetSystemIdsOptions {
  enabled?: boolean
}

export const useGetSystemIds = ({ enabled = true }: UseGetSystemIdsOptions = {}) => {
  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const getData = async () => {
    const { data } = await axios.get<WaveSyncLoginResponse>(`${waveSyncApiUrl}/system-ids`, {
      params: { orgId, userId },
    })
    return data
  }
  return useQuery({
    queryKey: ['getSystemIds'],
    queryFn: () => getData(),
    retry: 0,
    enabled,
  })
}

export const useWaveSyncLogout = () => {
  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const getData = async () => {
    if (!orgId || !userId) {
      throw new AxiosError(errorMessages.WAVESYNC_ORG_ID_USER_ID_NOT_FOUND)
    }

    const { data } = await axios.post(`${waveSyncApiUrl}/logout`, {
      orgId,
      userId,
    })
    return data
  }

  return useMutation({
    mutationKey: ['waveSyncLogout'],
    mutationFn: () => getData(),
  })
}

export function useWaveSyncAuthenticate() {
  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const getData = async (
    payload: Partial<{ username: string; password: string; orgId: string; userId: string }>
  ) => {
    const { data } = await axios.post<WaveSyncLoginResponse>(`${waveSyncApiUrl}/login`, {
      ...payload,
      orgId,
      userId,
    })
    return data
  }
  return useMutation({
    mutationKey: ['waveSyncLogin'],
    mutationFn: getData,
  })
}

export function useGetBookmarks(systemId: string) {
  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const getData = async () => {
    const { data } = await axios.get<WaveSyncVideo[]>(
      `${waveSyncApiUrl}/bookmarks/${systemId}/${orgId}/${userId}`
    )
    return data
  }
  return useQuery({
    queryKey: ['getBookmarks', systemId],
    queryFn: getData,
    enabled: !!systemId,
  })
}

export function useMedia() {
  const getData = async (payload: BookmarkPayload) => {
    const { data } = await axios.post<WaveSyncUploadVideoResponse[]>(
      `${waveSyncApiUrl}/media`,
      payload,
      {
        timeout: 120000,
      }
    )
    return data
  }
  return useMutation({
    mutationKey: ['media'],
    mutationFn: getData,
  })
}
