import { useTestingHelper } from '@training/hooks/useTestingHelper'
import CategoryOutlinedIcon from '../assets/categoryoutlined.svg'

const LoadingOverlay = ({
  icon = <CategoryOutlinedIcon />,
  isLoading,
  text,
}: {
  icon?: React.JSX.Element
  isLoading: boolean
  text: string
}) => {
  const { getTestIdProps } = useTestingHelper('loading-overlay')
  return (
    isLoading && (
      <div
        className='absolute w-full h-full bg-neutral-grey-14 bg-opacity-80 z-20 flex items-center justify-center flex-col'
        {...getTestIdProps('container')}
      >
        <div className='animate-pulse'>{icon || 'Loading...'}</div>
        <p
          className='font-medium text-2xl dark:text-semantic-secondary'
          {...getTestIdProps('text')}
        >
          {text}
        </p>
      </div>
    )
  )
}

export default LoadingOverlay
