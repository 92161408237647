import * as React from 'react'
import { Rect, Transformer, Text } from 'react-konva'
import { Node as KonvaNode } from 'konva/lib/Node'
import type { Rect as RectType } from 'konva/lib/shapes/Rect'
import { Stage as KonvaStage } from 'konva/lib/Stage'
import type { KonvaEventObject } from 'konva/lib/Node'
import type { Transformer as TransformerType } from 'konva/lib/shapes/Transformer'
import { VideoAnnotation } from '@training/hooks/useVideoAnnotations'
import { normalizeRectangle } from '@training/utils/cropImage'
import { add, noop } from 'lodash'

const removeDuplicateShapes = (stage: KonvaStage, shapeId: string) => {
  const rects = stage.find((node: KonvaNode) => {
    return shapeId ? node.attrs.id === shapeId : false
  })

  // reverse to get the last one created
  const [_, ...duplicated] = rects.toReversed() ?? []

  duplicated?.forEach((node) => {
    node.hide()
    node.remove()
    node.destroy()
  })
}

export type RectWithId = React.ComponentProps<typeof Rect> & { id: string }

interface AnnotationProps {
  shapeProps: RectWithId
  isSelected: boolean
  onSelect: (shapeId: string) => void
  onChange: (newAttrs: RectWithId) => void
  confidence?: number
  showConfidenceLevel?: boolean
  disabled?: boolean
  stroke?: string
  disabledSelectable?: boolean
}

export const Annotation = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  confidence,
  showConfidenceLevel,
  disabled,
  stroke,
  disabledSelectable,
}: AnnotationProps) => {
  const shapeRef = React.useRef<RectType>(null)
  const transformRef = React.useRef<TransformerType>(null)

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      transformRef.current?.setNode(shapeRef.current!)
      transformRef.current?.getLayer()?.batchDraw()
    }
  }, [isSelected])

  const onMouseEnter = (event: KonvaEventObject<MouseEvent>) => {
    if (disabled && !disabledSelectable) {
      return
    }

    const stage = event.target.getStage()
    if (stage) {
      removeDuplicateShapes(stage, event.target.attrs.id)
      if (disabledSelectable) {
        stage.container().style.cursor = 'pointer'
        return
      }

      stage.container().style.cursor = 'move'
    }
  }

  const onMouseLeave = (event: KonvaEventObject<MouseEvent>) => {
    const stage = event.target.getStage()
    if (stage) {
      stage.container().style.cursor = 'crosshair'
    }
  }

  const adjustBoxPosition = (event: KonvaEventObject<Event>) => {
    const stage = event.target.getStage()
    const stageWidth = stage?.width() ?? 0
    const stageHeight = stage?.height() ?? 0
    const { x, y, width: rectWidth, height: rectHeight } = event.target.getClientRect()

    if (x + rectWidth > stageWidth) event.target.x(stageWidth - rectWidth)
    if (y + rectHeight > stageHeight) event.target.y(stageHeight - rectHeight)

    if (x < 0) event.target.x(0)
    if (y < 0) event.target.y(0)
  }

  return (
    <React.Fragment>
      {showConfidenceLevel && confidence && (
        <Text
          fontSize={16}
          text={`${(Number(confidence) * 100).toFixed(1)}%`}
          shadowColor='#1D2129'
          shadowBlur={1}
          fill='white'
          stroke='black'
          strokeWidth={1}
          fontStyle='700'
          align='right'
          x={add(shapeProps.x ?? 0, shapeProps.width ?? 0) - 40}
          y={(shapeProps.y ?? 0) - 20}
          fillAfterStrokeEnabled
        />
      )}
      <Rect
        fill='transparent'
        stroke={stroke ?? 'blue'}
        onMouseDown={!disabledSelectable && disabled ? noop : () => onSelect(shapeProps.id)}
        ref={shapeRef}
        strokeWidth={5}
        {...shapeProps}
        draggable={!disabledSelectable && !disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        strokeScaleEnabled={false}
        onDragEnd={(event) => {
          if (disabledSelectable) return
          adjustBoxPosition(event)

          onChange({
            ...shapeProps,
            x: event.target.x(),
            y: event.target.y(),
          })
        }}
        onTransformEnd={() => {
          if (disabledSelectable) return

          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current
          const scaleX = node?.scaleX() ?? 1
          const scaleY = node?.scaleY() ?? 1

          // we will reset it back
          node?.scaleX(1)
          node?.scaleY(1)

          const normalizedRect = normalizeRectangle({
            x: node?.x() ?? 0,
            y: node?.y() ?? 0,
            width: (node?.width() ?? 0) * scaleX,
            height: (node?.height() ?? 0) * scaleY,
          } as VideoAnnotation)

          onChange({
            ...shapeProps,
            x: normalizedRect.x,
            y: normalizedRect.y,
            width: Math.max(5, normalizedRect.width),
            height: Math.max(5, normalizedRect.height),
          })
        }}
      />
      {isSelected && (
        <Transformer
          ref={transformRef}
          rotateEnabled={false}
          flipEnabled={false}
          ignoreStroke
          data-testid='canvas-transformer'
          onTransformStart={() => {
            if (disabledSelectable) {
              transformRef.current?.stopTransform()
            }
          }}
          anchorStroke={stroke}
          onMouseEnter={(event) => {
            if (disabledSelectable) {
              const stage = event.target.getStage()
              if (stage) {
                stage.getContent().style.cursor = 'not-allowed'
              }
            }
          }}
          anchorFill={stroke}
          anchorSize={12}
          anchorCornerRadius={10}
          borderEnabled={false}
          onTransformEnd={(event) => adjustBoxPosition(event)}
        />
      )}
    </React.Fragment>
  )
}
