import { Button, Icon, Typography } from '@htaic/cue'
import { useMutationState } from '@tanstack/react-query'
import { referencePointsQueryKeys } from '@training/apis/reference-points/requests'
import { MAX_REFERENCE_POINTS } from '@training/constants'
import { usePopper } from '@training/hooks/usePopper'
import { useVideoPlayer } from '@training/hooks/useVideoPlayer'
import { forwardRef, useCallback } from 'react'

type ReferencePointMarkerProps = React.ComponentProps<'button'>

export const ReferencePointMarker = forwardRef<HTMLButtonElement, ReferencePointMarkerProps>(
  (props, ref) => {
    return (
      <button
        aria-label='Reference point button'
        type='button'
        ref={ref}
        className='absolute top-[42px] w-1.5 h-1.5 p-0 m-0 rotate-45 bg-status-info border-0 cursor-pointer z-20'
        {...props}
      />
    )
  }
)

type ButtonProps = React.ComponentProps<typeof Button>
interface ReferencePointButtonProps extends ButtonProps {
  mode: 'add' | 'remove'
  onAddReferencePoint: ButtonProps['onClick']
  onRemoveReferencePoint: ButtonProps['onClick']
  hasMaxReferencePoints: boolean
}

export const ReferencePointButton = forwardRef<HTMLButtonElement, ReferencePointButtonProps>(
  (props, ref) => {
    const { mode, hasMaxReferencePoints, onAddReferencePoint, onRemoveReferencePoint } = props

    const [addReferencePointStatus] = useMutationState({
      filters: { mutationKey: referencePointsQueryKeys.addReferencePoint, status: 'pending' },
      select: (mutation) => mutation.state.status,
    }).slice(-1)

    const [deleteReferencePointStatus] = useMutationState({
      filters: { mutationKey: referencePointsQueryKeys.deleteReferencePoint, status: 'pending' },
      select: (mutation) => mutation.state.status,
    }).slice(-1)

    const isPlaying = useVideoPlayer()((state) => state.isPlaying)
    const { setPopper } = usePopper()

    const isProcessingReferencePoints =
      addReferencePointStatus === 'pending' || deleteReferencePointStatus === 'pending'

    const showMaxReferencePointsTooltip = useCallback<
      NonNullable<React.ComponentProps<'div'>['onMouseEnter']>
    >(
      (e) => {
        if (!hasMaxReferencePoints) return
        e.stopPropagation()
        setPopper({
          id: 'maxRef-popover',
          anchorEl: e.currentTarget,
          placement: 'bottom',
          content: (
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              {`You have reached the maximum points of reference: ${MAX_REFERENCE_POINTS}`}
            </Typography>
          ),
          className: 'w-56',
          closable: false,
        })
      },
      [hasMaxReferencePoints, setPopper]
    )

    if (mode === 'add') {
      return (
        <div
          onMouseEnter={showMaxReferencePointsTooltip}
          onMouseLeave={() => setPopper(null)}
          role='button'
          tabIndex={0}
        >
          <Button
            data-testid='add-reference-point-button'
            color='secondary'
            className='ml-2 mr-1'
            aria-label='Add Reference Point'
            onClick={onAddReferencePoint}
            ref={ref}
            disabled={hasMaxReferencePoints || isPlaying || isProcessingReferencePoints}
            {...props}
          >
            Add Reference Point
          </Button>
        </div>
      )
    }

    return (
      <Button
        data-testid='remove-reference-point-button'
        color='secondary'
        className='ml-2 mr-1'
        onClick={onRemoveReferencePoint}
        aria-label='Remove Reference Point'
        ref={ref}
        disabled={isProcessingReferencePoints}
        {...props}
      >
        Remove Reference Point
      </Button>
    )
  }
)

export const ReferencePointInfoButton = () => {
  const { setPopper } = usePopper()

  const showReferencePointsInfoTooltip = useCallback<
    NonNullable<React.ComponentProps<'button'>['onMouseEnter']>
  >(
    (e) => {
      e.stopPropagation()
      setPopper({
        id: 'info-popover',
        anchorEl: e.currentTarget,
        placement: 'bottom',
        content: (
          <Typography variant='body2' font='secondary' className='text-white text-xs'>
            A Reference Point allows you to mark important moments for each clip
          </Typography>
        ),

        className: 'w-56',
        closable: false,
      })
    },
    [setPopper]
  )
  return (
    <button
      className='flex items-center justify-center bg-transparent border-none mr-2 p-0'
      type='button'
      aria-label='Info'
      data-testid='info-button'
      onMouseEnter={showReferencePointsInfoTooltip}
      onMouseLeave={() => setPopper(null)}
    >
      <Icon name='Info' className='text-icon-default' />
    </button>
  )
}
