import { Outlet, useSearchParams } from 'react-router-dom'
import {
  Logo,
  Typography,
  AppsCard,
  ProfileCard,
  Button,
  Loader,
  Icon,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from '@htaic/cue'
import React, { Suspense, useCallback, useEffect } from 'react'
import { themes, useMinimeState } from '@training/hooks/useMinimeState'
import { useGetAppLinks } from '@training/hooks/useGetAppLinks'
import { logoutUser } from '@training/graphql/logoutUser'
import { useModalState } from '@training/hooks/useModal'
import useDarkMode from '@training/hooks/useDarkMode'
import DarkThemeLogo from '@training/assets/flexAI_dark.svg?react'
import LightThemeLogo from '@training/assets/flexAI_light.svg?react'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import { endpoints, envVars, storageKeys, urlParams } from '@training/constants'
import { storage } from '@training/utils/storage'
import { formatRedirectUrl, getRedirectUrl } from '@training/utils/getRedirectUrl'
import { getMiniMe, setAppConfig } from '@htaic/anaheim-graphql-library'
import { FeatureFlagsModal } from '@training/components/Modals/FeatureFlagsModal'
import { PersonalSettingsModalApp } from '@training/components/Modals/PersonalSettingsModal'

const removeParam = (param: string) => {
  if (typeof URLSearchParams !== 'undefined') {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
  }
}

const redirectOut = (orgId?: string) => {
  if (envVars.DEV_DISABLE_REDIRECTS) {
    return
  }

  const cloudPlatformLoginUrl = getRedirectUrl()
  window.location.href = formatRedirectUrl(cloudPlatformLoginUrl, orgId)
}

const AppLoader = () => (
  <div
    className='flex items-center justify-center h-full grow'
    aria-live='polite'
    aria-label='Loading projects'
    data-testid='loading-projects'
  >
    <Loader
      size='xxxlarge'
      iconName='LoadingThin'
      className='text-primary dark:text-neutral-grey-0'
    />
  </div>
)

const useSetupOrgId = () => {
  const [searchParams] = useSearchParams()

  const orgIdFromStore = useMinimeState((state) => state.orgId)
  const orgId = orgIdFromStore || searchParams.get('orgId') || ''

  const setOrgId = useMinimeState((state) => state.setOrgId)

  useEffect(() => {
    storage().setItem(storageKeys.ORG_ID, orgId)
    if (!orgIdFromStore) {
      setOrgId(orgId)
    }
    removeParam(urlParams.ORG_ID)
  }, [orgId, orgIdFromStore, setOrgId])

  return orgId
}

export const MainLayout = () => {
  const orgId = useSetupOrgId()

  const setUser = useMinimeState((state) => state.setUser)
  const setTheme = useMinimeState((state) => state.setTheme)
  const setOrgName = useMinimeState((state) => state.setOrgName)

  useEffect(() => {
    if (!orgId) {
      redirectOut()
      return
    }

    async function callMinime() {
      try {
        setAppConfig(endpoints.GRAPHQLURL, undefined, true, orgId)

        const minimeResponse = await getMiniMe()
        if (!minimeResponse) {
          throw new Error('Minime response is empty')
        }

        storage().setItem(storageKeys.USER_ID, minimeResponse?.accountId ?? '')

        sessionStorage.setItem(
          storageKeys.SESSION_TIMEOUT_MINUTES,
          `${minimeResponse?.sessionTimeout}`
        )

        setTheme(minimeResponse?.userSettings?.theme ?? 'light')
        setUser({
          userName: minimeResponse?.username ?? '',
          firstName: minimeResponse?.firstName ?? '',
          lastName: minimeResponse?.lastName ?? '',
          userId: minimeResponse?.accountId ?? '',
        })
        setOrgName(minimeResponse?.orgName ?? '')
      } catch (error) {
        console.error('Error in fetching minime', error)
        redirectOut(orgId)
      }
    }
    callMinime()
  }, [orgId, setOrgName, setTheme, setUser])

  const appsLinks = useGetAppLinks()
  const { firstName, lastName, userName, theme } = useMinimeState()

  const devFFManagement = useFeatureFlags().DEV_FF_MANAGEMENT

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const onPreferencesClick = useCallback(() => {
    openModal({
      size: 'lg',
      content: <PersonalSettingsModalApp onClose={closeModal} />,
    })
  }, [closeModal, openModal])

  const handleLogOut = useCallback(async () => {
    closeModal()
    await logoutUser(false, true)
  }, [closeModal])

  const onLogoutModalOpen = useCallback(() => {
    openModal({
      size: 'sm',
      content: (
        <ModalContent>
          <ModalHeader closable>Logout</ModalHeader>
          <div className='flex py-4'>
            <Typography variant='body2' className='text-center'>
              Are you sure you want to log out of FLEX AI and the Hanwha Cloud Platform?
            </Typography>
          </div>
          <ModalFooter className='flex justify-end'>
            <Button color='primary' variant='contained' onClick={handleLogOut}>
              Logout
            </Button>
            <Button color='secondary' onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      ),
    })
  }, [closeModal, handleLogOut, openModal])

  const onFeatureFlagsModalOpen = useCallback(() => {
    openModal({
      size: 'lg',
      content: <FeatureFlagsModal onClose={closeModal} />,
    })
  }, [closeModal, openModal])

  const handleClick = () => {
    window.open(
      'https://support.hanwhavisionamerica.com/hc/en-us/sections/25223055328283-Flex-AI',
      '_blank',
      'noopener,noreferrer'
    )
  }

  const { darkMode } = useDarkMode(theme)

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', darkMode ? themes.DARK : '')
    if (darkMode) {
      document.body.classList.add(themes.DARK)
    } else {
      document.body.classList.remove(themes.DARK)
    }
  }, [darkMode])

  return (
    <div
      className='flex flex-col min-h-[100svh] bg-neutral-grey-16 text-neutral-grey-0'
      data-testid='container-app'
    >
      <header className='flex justify-between items-center h-12 text-center px-5 py-2'>
        <div className='w-1/3 text-left flex items-center'>
          <Logo data-testid='logo' className='w-7 h-7 mr-6' />
          {theme === themes.DARK ? (
            <DarkThemeLogo
              className='h-7 w-fit'
              title='FLEX AI Logotype'
              data-testid='dark-theme-logo'
            />
          ) : (
            <LightThemeLogo
              className='h-7 w-fit'
              title='FLEX AI Logotype'
              data-testid='light-theme-logo'
            />
          )}
        </div>
        <div className='flex flex-row w-1/3 justify-end items-center'>
          <AppsCard>
            <AppsCard.Trigger />
            <AppsCard.Popper
              containerClassName='z-20'
              appsLinks={appsLinks}
              onHelpClick={handleClick}
            />
          </AppsCard>
          <ProfileCard>
            <ProfileCard.Trigger firstName={firstName} lastName={lastName} />
            <ProfileCard.Content
              containerClassName='z-20'
              firstName={firstName}
              lastName={lastName}
              userName={userName}
            >
              <ProfileCard.Action
                label='Preferences'
                iconLeft={<Icon name='Settings' size='small' />}
                iconRight={<Icon name='ChevronRight' size='small' className='p-1' />}
                onClick={onPreferencesClick}
              />
              {devFFManagement ? (
                <>
                  <ProfileCard.Separator />
                  <ProfileCard.Action
                    label='Feature Flags (Dev Only)'
                    iconLeft={<Icon name='Protocol' size='small' />}
                    onClick={onFeatureFlagsModalOpen}
                  />
                </>
              ) : null}

              <ProfileCard.Separator />
              <ProfileCard.Action
                label='Logout'
                iconLeft={<Icon name='Exiting' size='small' />}
                onClick={onLogoutModalOpen}
              />
            </ProfileCard.Content>
          </ProfileCard>
        </div>
      </header>
      <Suspense fallback={<AppLoader />}>
        <Outlet />
      </Suspense>
    </div>
  )
}
