import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { MvpVerificationPayload, ThumbnailsAndAnnotations } from '@training/apis/types'
import { endpoints } from '@training/constants'

const assetsApiUrl = endpoints.VITE_ASSETS_API ?? 'http://localhost:3010'

export const assetsApiQueryKeys = {
  getAnnotationThumbnails: 'getAnnotationThumbnails',
  getProjectVideos: 'getProjectVideos',
}

export function useGetThumbnailsAndAnnotation(
  { projectId, orderBy }: { projectId: string; orderBy?: string },
  enabled = true
) {
  const getData = async () => {
    const { data } = await axios.get<ThumbnailsAndAnnotations[]>(`${assetsApiUrl}/thumbnails/v2`, {
      params: { projectId, orderBy },
    })
    return data
  }

  return useQuery({
    queryFn: () => getData(),
    queryKey: [assetsApiQueryKeys.getAnnotationThumbnails, projectId, orderBy],
    enabled,
  })
}

export function useModifyVerificationFile(verificationLabelId: string) {
  const getData = async (payload: MvpVerificationPayload) => {
    const { data } = await axios.patch<{
      status: number
      message: string
    }>(`${assetsApiUrl}/mvp/v1/${verificationLabelId}/verification`, payload)
    return data
  }
  return useMutation({
    mutationFn: (payload: MvpVerificationPayload) => getData(payload),
    mutationKey: ['modifyVerificationFile', verificationLabelId],
  })
}
