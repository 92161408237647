import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import {
  AnnotationMarkersResponseV2,
  CreateAnnotationsPayload,
  VideoAnnotationsResponse,
  VideoMetadataResponse,
} from '@training/apis/types'
import { endpoints } from '@training/constants'
import { VideoFile } from '@training/types'
import { applyProxyDomain } from '@training/utils/applyProxyDomain'

export const annotationsApiUrl = endpoints.VITE_ANNOTATOR_API ?? 'http://localhost:3012'

export const annotationsApiQueryKeys = {
  getVideoAnnotations: 'getVideoAnnotations',
  getVideoMetadata: 'getVideoMetadata',
  getAnnotationMarkers: 'getAnnotationMarkers',
  createAnnotations: 'createAnnotations',
  deleteAnnotations: 'deleteAnnotations',
  updateAnnotations: 'updateAnnotations',
  getAnnotationMarkersV2: 'getAnnotationMarkersV2',
}

export function useGetVideoAnnotations(verifiedUrl?: string) {
  const getData = async () => {
    const { data } = await axios.get<VideoAnnotationsResponse>(applyProxyDomain(verifiedUrl!))
    return data
  }
  return useQuery({
    queryKey: [annotationsApiQueryKeys.getVideoAnnotations, verifiedUrl],
    queryFn: () => getData(),
    enabled: !!verifiedUrl,
  })
}

export function useGetVideoMetadata(clip: Pick<VideoFile, 'id' | 'width' | 'height'>) {
  const getData = async () => {
    const { data } = await axios.get<VideoMetadataResponse>(
      `${annotationsApiUrl}/annotations/v2/videofile/metadata/${clip.id}`
    )
    return data
  }
  return useQuery({
    queryKey: [annotationsApiQueryKeys.getVideoMetadata, clip.id],
    queryFn: () => getData(),
    enabled: !!clip.id && !clip.width && !clip.height,
  })
}

/*
 * @deprecated use useGetAnnotationMarkersV2 instead
export function useGetAnnotationMarkers(projectVersionId: string, videoFileId?: string) {
  const getData = async () => {
    const { data } = await axios.get<AnnotationMarker[]>(
      `${annotationsApiUrl}/annotations/v2/video-annotations-markers/${projectVersionId}/${videoFileId}`
    )
    return data
  }

  return useQuery({
    queryKey: [annotationsApiQueryKeys.getAnnotationMarkers, videoFileId],
    queryFn: () => getData(),
    enabled: !!videoFileId,
  })
} */

export const getAnnotationMarkersV2 = async (projectVersionId: string, videoFileId?: string) => {
  const { data } = await axios.get<AnnotationMarkersResponseV2>(
    `${annotationsApiUrl}/annotations/v3/video-annotations-markers/${projectVersionId}/${videoFileId}`
  )
  return data
}

// for frames of interest
export function useGetAnnotationMarkersV2(
  projectVersionId: string,
  videoFileId?: string,
  options: { keepPreviousData?: boolean } = {}
) {
  return useQuery({
    queryKey: [annotationsApiQueryKeys.getAnnotationMarkersV2, projectVersionId, videoFileId],
    queryFn: () => getAnnotationMarkersV2(projectVersionId, videoFileId),
    enabled: !!videoFileId,
    placeholderData: options.keepPreviousData ? keepPreviousData : undefined,
  })
}

export function useCreateAnnotations(projectId?: string) {
  if (!projectId) {
    throw new Error('projectId is required')
  }

  const getData = async (payload: CreateAnnotationsPayload) => {
    const { data } = await axios.post(`${annotationsApiUrl}/annotations/v2/${projectId}`, payload)
    return data
  }

  return useMutation({
    mutationKey: [annotationsApiQueryKeys.createAnnotations, projectId],
    mutationFn: (payload: CreateAnnotationsPayload) => getData(payload),
  })
}

export function useDeleteAnnotation(projectId: string) {
  const getData = async (payload: { annotationId: string }) => {
    await axios.delete(`${annotationsApiUrl}/annotations/v2/${projectId}/${payload.annotationId}`)
  }

  return useMutation({
    mutationKey: [annotationsApiQueryKeys.deleteAnnotations, projectId],
    mutationFn: (payload: { annotationId: string }) => getData(payload),
  })
}

export function useUpdateAnnotations(projectId: string | undefined) {
  if (!projectId) {
    throw new Error('projectId is required')
  }
  const getData = async (payload: CreateAnnotationsPayload) => {
    const { data } = await axios.put(
      `${annotationsApiUrl}/annotations/v2/${projectId}/${payload.annotation_id}`,
      payload
    )
    return data
  }
  return useMutation({
    mutationKey: [annotationsApiQueryKeys.updateAnnotations, projectId],
    mutationFn: (payload: CreateAnnotationsPayload) => getData(payload),
  })
}

export function useGetProjectVersionAnnotationCount(projectVersionId: string) {
  const getData = async () => {
    const { data } = await axios.get<number>(
      `${annotationsApiUrl}/annotations/v2/${projectVersionId}/annotation-count`
    )
    return data
  }

  return useQuery({
    queryKey: ['getProjectVersionAnnotationCount', projectVersionId],
    queryFn: () => getData(),
    enabled: !!projectVersionId,
  })
}
