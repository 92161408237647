export const mimeTypeToExtensionMap = {
  'video/mp4': 'mp4',
  'video/webm': 'webm',
}

export function getExtensionFromMimeType(mimeType: string) {
  return mimeTypeToExtensionMap[mimeType as keyof typeof mimeTypeToExtensionMap] || ''
}

export function appendExtensionToFileName(fileName: string, mimeType: string) {
  const fileExtension = getExtensionFromMimeType(mimeType)
  if (fileExtension) {
    const dotIndex = fileName.lastIndexOf('.')
    if (dotIndex === -1) {
      return `${fileName}.${fileExtension}`
    }
    return `${fileName.substring(0, dotIndex)}.${fileExtension}`
  }
  return fileName
}

export function checkAndRenameFile(clips: { name: string }[], newFileName: string) {
  let newName = newFileName.replace(/\.[^.]+$/, '')
  let count = 0

  function nameExistsInClips(nameToCheck: string) {
    return clips.some((clip) => clip.name === nameToCheck)
  }

  while (nameExistsInClips(newName)) {
    count += 1
    newName = `${newFileName.replace(/\.[^.]+$/, '')}_${count}`
  }
  return newName
}
