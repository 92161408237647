import { BoxifyResponse } from '@training/apis/types'
import { VideoAnnotation } from '@training/hooks/useVideoAnnotations'
import { Dimensions } from '@training/types'
import { scaleRect } from './scaleRect'

export const tightenedAnnotationsList = (
  dimensions: Dimensions,
  canvasMeasures: Dimensions,
  tightenedAnnotations: BoxifyResponse
): VideoAnnotation[] => {
  return tightenedAnnotations?.geometries.map((item, index) => {
    const points = item.coordinates

    return scaleRect(dimensions, canvasMeasures, {
      x: points[0][0],
      y: points[0][1],
      width: points[1][0] - points[0][0],
      height: points[2][1] - points[0][1],
      frameStart: tightenedAnnotations.timeline_marker,
      frameEnd: tightenedAnnotations.timeline_marker,
      type: 'annotation',
      id: `${index}-${points[0][0]}-${points[0][1]}`,
    } satisfies VideoAnnotation)
  })
}
