/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react'
import { Stage, Layer } from 'react-konva'
import { Button, Icon, useSnackbar } from '@htaic/cue'
import { Dimensions, EventFor } from '@training/types'
import VideoPlayer from '@training/components/VideoPlayer'
import { Stage as KonvaStage } from 'konva/lib/Stage'
import { includes, isNil, isUndefined, map, noop, round } from 'lodash'
import {
  useSyncVideoPlayerInstances,
  useVideoPlayer,
  useVideoPlayerInstance,
} from '@training/hooks/useVideoPlayer'
import {
  VideoAnnotation,
  useSaveVideoAnnotations,
  useVideoAnnotations,
} from '@training/hooks/useVideoAnnotations'
import { scaleRect } from '@training/utils/scaleRect'
import { normalizeRectangle } from '@training/utils/cropImage'
import { frameToSeconds } from '@training/utils/videoTimeConverter'
import { twMerge } from 'tailwind-merge'
import { useAppState } from '@training/hooks/useAppState'
import { annotationType, projectViewModes } from '@training/constants'
import { useCallback } from 'react'
import { useGetTightenAnnotations } from '@training/apis/assisted/requests'
import { BoxifyPayload, Foi } from '@training/apis/types'
import { tightenedAnnotationsList } from '@training/utils/tightenedAnnotationsList'

import { ConfirmSaveModal } from '@training/components/Modals/ConfirmSaveModal'
import { useModalState } from '@training/hooks/useModal'
import { Rect } from 'konva/lib/shapes/Rect'
import { startMeasure } from '@training/utils/performance'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { convertFramesToProgress } from '@training/utils/convertFramesToProgress'
import { TimelinePlayer } from './TimeLinePlayer/TimeLinePlayer'
import { AnnotationsList } from './AnnotationsList'
import { useAnnotationFrameFilter } from './useAnnotationFrameFilter'

export type GeneratedAnnotation = {
  [key: string]: Array<{
    location: {
      xmin: number
      ymin: number
      xmax: number
      ymax: number
    }
    confidence: number
    class_id: number
  }>
}

export interface CurrentFrameAnnotations {
  combinedCaptureUrl: string
  combinedImage: HTMLImageElement
  snippets: string[]
}

export interface TimelineMarker {
  id: string
  start: number
  end: number
  time: number
  frame: number
}

const defaultDimensions = {
  width: 605,
  height: 1076,
}

const hasValidAnnotationDimensions = ({ width, height }: Dimensions) => {
  return Math.abs(width) > 10 && Math.abs(height) > 10
}

const disabledAnnotationTypes = [annotationType.INCORRECT, annotationType.INFERENCE] as const

interface SuggestedFrameJumpButtonProps extends React.ComponentProps<typeof Button> {
  suggestedFramesNumbers: number[]
  orientation: 'left' | 'right'
}

const SuggestedFrameJumpButton = (props: SuggestedFrameJumpButtonProps) => {
  const { suggestedFramesNumbers, orientation = 'left', onClick, className, ...rest } = props

  const currentFrame = useVideoPlayer()((state) => state.currentFrame)

  const iconName = orientation === 'left' ? 'ChevronLeft' : 'ChevronRight'

  const shouldHide =
    orientation === 'left'
      ? currentFrame.rounded === suggestedFramesNumbers[0]
      : currentFrame.rounded === suggestedFramesNumbers[suggestedFramesNumbers.length - 1]

  return (
    <Button
      color='neutral'
      className={twMerge(
        'w-8 p-0 min-w-0 h-24 absolute top-1/2 -translate-y-1/2',
        shouldHide ? 'invisible' : '',
        className,
        orientation === 'right' ? '-right-10' : '-left-10'
      )}
      onClick={onClick}
      {...rest}
    >
      <Icon name={iconName} className='text-icon-default' />
    </Button>
  )
}

const VideoViewer = (props: {
  playerOptions: React.ComponentProps<typeof VideoPlayer>['options']
  isTrained?: boolean
  initialAnnotations?: VideoAnnotation[]
  dimensions?: Dimensions
  frameRate: number
  isComparisonMode?: boolean
  disableDrawMode?: boolean
  thumbnails?: Array<{ timeSeconds: number; url: string }>
  suggestedFrames?: Foi[]
  projectId?: string
  videoFileId?: string
}) => {
  const {
    isTrained,
    playerOptions,
    initialAnnotations = [],
    dimensions = defaultDimensions,
    frameRate = 20,
    isComparisonMode = false,
    disableDrawMode = false,
    thumbnails = [],
    suggestedFrames,
    projectId,
    videoFileId,
  } = props

  const stageRef = React.useRef<KonvaStage>(null)

  const currentAnnotationsStore = useVideoAnnotations(isComparisonMode ? 'current' : 'candidate')

  const annotations = currentAnnotationsStore((state) => state.annotations)
  const setAnnotations = currentAnnotationsStore((state) => state.setAnnotations)
  const addAnnotation = currentAnnotationsStore((state) => state.addAnnotation)
  const removeAnnotation = currentAnnotationsStore((state) => state.removeAnnotation)

  const setAnnotationsToTighten = useVideoAnnotations()((state) => state.setAnnotationsToTighten)

  const [newAnnotation, setNewAnnotation] = React.useState<VideoAnnotation[]>([])

  const [selectedId, selectAnnotation] = React.useState<string | null>(null)
  const [canvasMeasures, setCanvasMeasures] = React.useState({
    width: dimensions.height,
    height: dimensions.width,
  })

  const instanceRef = useVideoPlayerInstance()((state) => state.instanceRef)
  const { pause, play, forwardTen, replayTen, setCurrentTime, setPlaybackRate } =
    useSyncVideoPlayerInstances()

  const isReady = useVideoPlayer()((state) => state.isReady)
  const isMetadataReady = useVideoPlayer()((state) => state.isMetadataReady)

  const isPlaying = useVideoPlayer()((state) => state.isPlaying)

  const confidenceLevelValue = useAppState((state) => state.confidenceLevelValue)

  const previousCanvasMeasuresRef = React.useRef(canvasMeasures)

  const loadInitialAnnotations = React.useCallback(() => {
    if (initialAnnotations.length > 0) {
      const scaledAnnotations = map(initialAnnotations, (annotation) =>
        // we need a ref to avoid firing the useEffect on size change
        scaleRect(dimensions, previousCanvasMeasuresRef.current, annotation)
      )
      setAnnotations(scaledAnnotations)
    } else {
      setAnnotations([])
    }
    // stringify for deep equality check https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialAnnotations), JSON.stringify(dimensions)])

  const setLoadInitialAnnotations = currentAnnotationsStore(
    (state) => state.setLoadInitialAnnotations
  )

  React.useLayoutEffect(() => {
    loadInitialAnnotations()
    setLoadInitialAnnotations(loadInitialAnnotations)
  }, [loadInitialAnnotations, setLoadInitialAnnotations])

  const onDiscard = React.useCallback(() => {
    loadInitialAnnotations()
  }, [loadInitialAnnotations])

  const getCurrentFrame = useVideoPlayer()((state) => state.getCurrentFrame)
  const getCurrentTime = useVideoPlayerInstance()((state) => state.getCurrentTime)

  const handleMouseMove = useCallback<EventFor<typeof Stage, 'onMouseMove'>>(
    (event) => {
      const stage = event.target.getStage()

      if (selectedId === null && newAnnotation.length === 1 && stage) {
        const sx = newAnnotation[0].x
        const sy = newAnnotation[0].y
        const { x, y } = event.target?.getStage()?.getPointerPosition() ?? { x: 0, y: 0 }
        const id = Math.floor(Math.random() * 1000000)
        setNewAnnotation([
          {
            x: sx,
            y: sy,
            width: x - sx,
            height: y - sy,
            frameStart: getCurrentFrame().rounded,
            frameEnd: getCurrentFrame().rounded,
            timelineInSeconds: getCurrentTime(),
            id: id.toString(),
            type: 'annotation',
          },
        ])
      }
    },
    [selectedId, newAnnotation, getCurrentFrame, getCurrentTime]
  )

  const handleMouseUp = () => {
    if (selectedId === null && newAnnotation.length === 1) {
      const hasValidDimensions = hasValidAnnotationDimensions(newAnnotation[0])
      const currentFrameNumber = getCurrentFrame().rounded

      if (newAnnotation[0].width < 0) {
        newAnnotation[0].x += newAnnotation[0].width
        newAnnotation[0].width = Math.abs(newAnnotation[0].width)
      }
      if (newAnnotation[0].height < 0) {
        newAnnotation[0].y += newAnnotation[0].height
        newAnnotation[0].height = Math.abs(newAnnotation[0].height)
      }

      addAnnotation({
        frameStart: currentFrameNumber,
        frameEnd: currentFrameNumber,
        ...newAnnotation[0],
        ...(hasValidDimensions ? {} : { height: 40, width: 40 }),
      })
      selectAnnotation(newAnnotation[0].id)
      setNewAnnotation([])
      setAnnotationsToTighten(newAnnotation)
    }
  }

  const handleMouseLeave = () => {
    handleMouseUp()
  }

  const handleMouseDown = useCallback<EventFor<typeof Stage, 'onMouseDown'>>(
    (event) => {
      const stage = event.target.getStage()

      const emptySpace = event.target === stage

      if (emptySpace && selectedId !== null) {
        selectAnnotation(null)
        setNewAnnotation([])
      }

      // if the click is not on an annotation, create a new one
      if (!(event.target instanceof Rect) && stage) {
        const { x, y } = stage.getPointerPosition() ?? { x: 0, y: 0 }

        const id = Math.floor(Math.random() * 1000000)

        setNewAnnotation([
          {
            x,
            y,
            width: 0,
            height: 0,
            id: id.toString(),
            type: 'annotation',
            timelineInSeconds: getCurrentTime(),
          },
        ])
      }
    },
    [getCurrentTime, selectedId]
  )

  const clearAnnotationsToTighten = useVideoAnnotations()(
    (state) => state.clearAnnotationsToTighten
  )

  const annotationsToDraw = React.useMemo(() => {
    return [...annotations.values(), ...newAnnotation]
  }, [annotations, newAnnotation])

  const filterAnnotationsInFrame = useAnnotationFrameFilter({ liveUpdate: false })

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Esc') {
      setNewAnnotation([])
    }

    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (selectedId === null) {
        return
      }

      const selectedAnnotation = annotations.get(selectedId)

      removeAnnotation(selectedId)

      if (
        selectedAnnotation &&
        includes(disabledAnnotationTypes, selectedAnnotation.type) &&
        isTrained
      ) {
        addAnnotation({
          ...selectedAnnotation,
          type: 'incorrect',
        })
      } else {
        selectAnnotation(null)
        if (!stageRef.current) return
        stageRef.current.container().style.cursor = 'crosshair'
      }

      setNewAnnotation([])

      const annotationsDrawnInFrame = filterAnnotationsInFrame({
        annotations: annotationsToDraw,
        confidenceLevelValue,
        projectViewMode,
        isComparisonMode,
      })

      if (annotationsDrawnInFrame.length === 1) {
        clearAnnotationsToTighten()
      }
    }
  }

  const onPlaybackRateChange = useCallback(
    (newPlaybackRate: number) => {
      setPlaybackRate(newPlaybackRate)
    },
    [setPlaybackRate]
  )

  const [progressBarMarkers, setProgressBarMarkers] = React.useState<TimelineMarker[]>([])

  const projectViewMode = useAppState((state) => state.projectViewMode)

  // set initial markers of user generated annotations
  React.useLayoutEffect(() => {
    if (!isReady || projectViewMode === projectViewModes.REVIEW || !isMetadataReady) {
      if (progressBarMarkers.length > 0) {
        setProgressBarMarkers([])
      }
      return
    }
    const userGeneratedAnnotations = annotationsToDraw.filter(
      (annotation) => annotation.type === 'annotation' || annotation.type === 'incorrect'
    )

    const markers = convertFramesToProgress(
      userGeneratedAnnotations,
      frameRate,
      instanceRef.current?.duration() ?? 0
    )

    setProgressBarMarkers(markers)
  }, [
    annotationsToDraw,
    frameRate,
    isReady,
    instanceRef,
    projectViewMode,
    progressBarMarkers.length,
    isMetadataReady,
  ])

  const desiredTime = useVideoPlayer()((state) => state.desiredTime)
  const setDesiredTime = useVideoPlayer()((state) => state.setDesiredTime)

  React.useLayoutEffect(() => {
    if (!isReady || isNil(desiredTime)) {
      return
    }

    instanceRef.current?.currentTime(desiredTime)
    setDesiredTime(null)
  }, [isReady, instanceRef, desiredTime, setDesiredTime])

  const onSizeChange = React.useCallback(
    (width: number, height: number) => {
      const previousCanvasMeasures = previousCanvasMeasuresRef.current

      const newAnnotations = annotationsToDraw.map((annotation) =>
        scaleRect(previousCanvasMeasures, { width, height }, annotation)
      )

      previousCanvasMeasuresRef.current = { width, height }

      setCanvasMeasures({ width, height })

      setAnnotations(newAnnotations)
    },
    [annotationsToDraw, setAnnotations]
  )

  const modifiedAnnotations = useVideoAnnotations()((state) => state.modifiedAnnotations)
  const clearModifiedAnnotations = useVideoAnnotations()((state) => state.clearModifiedAnnotations)

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const { triggerSave, requestSave } = useSaveVideoAnnotations()

  const checkUnsavedAnnotations = React.useCallback(() => {
    if (modifiedAnnotations.size > 0) {
      openModal({
        size: 'sm',
        content: (
          <ConfirmSaveModal
            data-testid='confirm-save-modal'
            onDiscard={() => {
              onDiscard()
              clearModifiedAnnotations()
              clearAnnotationsToTighten()
              closeModal()
            }}
            onSave={() => {
              startMeasure('save-annotations-payload')
              triggerSave(true)
              closeModal()
            }}
          />
        ),
      })
      return true
    }
    return false
  }, [
    clearModifiedAnnotations,
    clearAnnotationsToTighten,
    closeModal,
    modifiedAnnotations.size,
    onDiscard,
    openModal,
    triggerSave,
  ])

  // Function to handle the progress change in the TimelinePlayer
  const onProgressChange = useCallback(
    (newProgress: number) => {
      const hasUnsavedAnnotations = checkUnsavedAnnotations()
      if (hasUnsavedAnnotations) return

      clearAnnotationsToTighten()
      const videoDuration = instanceRef.current?.duration() ?? 0
      const time = (newProgress / 100) * videoDuration

      setCurrentTime(round(time, 3))
    },
    [checkUnsavedAnnotations, clearAnnotationsToTighten, instanceRef, setCurrentTime]
  )

  const orgId = useMinimeState((state) => state.orgId)

  const onPlayPress = React.useCallback(async () => {
    const hasUnsavedAnnotations = checkUnsavedAnnotations()
    if (hasUnsavedAnnotations) return
    if (isPlaying) {
      pause()
      return
    }

    await play()
  }, [isPlaying, pause, play, checkUnsavedAnnotations])

  const suggestedFramesNumbers = React.useMemo(
    () => map(suggestedFrames, (item) => item.frameNumber),
    [suggestedFrames]
  )

  const onNextSuggestedFrame = React.useCallback(() => {
    const hasUnsavedAnnotations = checkUnsavedAnnotations()
    if (hasUnsavedAnnotations) return

    const currentFrameNumber = getCurrentFrame().rounded
    const nextSuggestedFrame = suggestedFramesNumbers.find((frame) => frame > currentFrameNumber)

    if (nextSuggestedFrame) {
      setCurrentTime(frameToSeconds(nextSuggestedFrame, frameRate, 3))
      clearAnnotationsToTighten()
    }
  }, [
    getCurrentFrame,
    suggestedFramesNumbers,
    setCurrentTime,
    frameRate,
    checkUnsavedAnnotations,
    clearAnnotationsToTighten,
  ])

  const onPreviousSuggestedFrame = React.useCallback(() => {
    const hasUnsavedAnnotations = checkUnsavedAnnotations()
    if (hasUnsavedAnnotations) return

    const currentFrameNumber = getCurrentFrame().rounded
    const previousSuggestedFrame = suggestedFramesNumbers
      .toReversed()
      .find((frame) => frame < currentFrameNumber)

    if (!isUndefined(previousSuggestedFrame)) {
      setCurrentTime(frameToSeconds(previousSuggestedFrame, frameRate, 3))
      clearAnnotationsToTighten()
    }
  }, [
    getCurrentFrame,
    suggestedFramesNumbers,
    setCurrentTime,
    frameRate,
    checkUnsavedAnnotations,
    clearAnnotationsToTighten,
  ])

  const showSnackBar = useSnackbar()
  const videoFilename = useVideoPlayer()((state) => state.videoFilename)
  const getDimensions = useVideoPlayerInstance()((state) => state.getDimensions)

  const { mutateAsync: tightenMutateAsync } = useGetTightenAnnotations(orgId ?? '', projectId ?? '')

  const handleTightenUpAnnotations = useCallback(async () => {
    const annotationsDrawnInFrame = filterAnnotationsInFrame({
      annotations: annotationsToDraw,
      confidenceLevelValue,
      projectViewMode,
      isComparisonMode,
    })

    const videoDimensions = getDimensions()

    const scaledFrameAnnotations = annotationsDrawnInFrame.map((annotation) =>
      scaleRect(videoDimensions.displayedSize, videoDimensions.resolution, annotation)
    )

    const transformedGeometries = scaledFrameAnnotations.map((annotation) => {
      const { x, y, width, height } = normalizeRectangle(annotation)

      return {
        type: 'polygon',
        coordinates: [
          [x, y], // bottom left
          [x + width, y], // bottom right
          [x + width, y + height], // top right
          [x, y + height], // top left
        ],
      }
    })

    const payload: BoxifyPayload = {
      video_filename: videoFilename,
      timeline_marker: getCurrentFrame().rounded,
      geometries: transformedGeometries,
    }

    try {
      const tightenedAnnotationsResult = await tightenMutateAsync(payload)

      annotationsDrawnInFrame.forEach((annotInFrame) => {
        removeAnnotation(annotInFrame.id)
      })

      const tightenedToAnnotations = tightenedAnnotationsList(
        dimensions,
        canvasMeasures,
        tightenedAnnotationsResult
      )

      tightenedToAnnotations.map((tightenedAndScaledAnnotation) =>
        addAnnotation(tightenedAndScaledAnnotation)
      )
      setNewAnnotation([])
      clearAnnotationsToTighten()
    } catch (error) {
      showSnackBar({
        message: 'Tightening of boxes failed',
        status: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterAnnotationsInFrame,
    annotationsToDraw,
    confidenceLevelValue,
    projectViewMode,
    isComparisonMode,
    videoFilename,
    getCurrentFrame,
    tightenMutateAsync,
    dimensions,
    canvasMeasures,
    clearAnnotationsToTighten,
    removeAnnotation,
    addAnnotation,
    getDimensions,
  ])

  const userGeneratedAnnotations = React.useMemo(
    () => annotationsToDraw.filter((annotation) => annotation.type === 'annotation'),
    [annotationsToDraw]
  )
  return (
    <>
      <div
        className={twMerge(
          `relative col-span-1`,
          requestSave && !isComparisonMode ? 'cursor-wait' : ''
        )}
        data-testid='video-annotation-container'
      >
        {projectViewMode === projectViewModes.IMPROVE ? (
          <SuggestedFrameJumpButton
            suggestedFramesNumbers={suggestedFramesNumbers}
            orientation='left'
            onClick={onPreviousSuggestedFrame}
            title='Previous Suggested Frame'
            data-testid='previous-suggested-frame-button'
          />
        ) : null}
        <VideoPlayer
          options={playerOptions}
          className='w-full [&_*]:max-h-[75svh] overflow-clip'
          onSizeChange={onSizeChange}
          frameRate={frameRate}
          videoType={isComparisonMode ? 'current' : 'candidate'}
        />
        {projectViewMode === projectViewModes.IMPROVE ? (
          <SuggestedFrameJumpButton
            suggestedFramesNumbers={suggestedFramesNumbers}
            orientation='right'
            onClick={onNextSuggestedFrame}
            title='Next Suggested Frame'
            data-testid='next-suggested-frame-button'
          />
        ) : null}
        <div
          tabIndex={0}
          onKeyDown={handleKeyDown}
          role='button'
          className={twMerge(
            `absolute top-0 left-0 aspect-video z-10`,
            disableDrawMode ? 'pointer-events-none' : 'pointer-events-auto',
            !isPlaying ? '' : 'pointer-events-none',
            requestSave ? 'pointer-events-none' : 'cursor-crosshair'
          )}
        >
          <Stage
            width={canvasMeasures.width}
            height={canvasMeasures.height}
            onMouseDown={disableDrawMode ? noop : handleMouseDown}
            onMouseMove={disableDrawMode ? noop : handleMouseMove}
            onMouseUp={disableDrawMode ? noop : handleMouseUp}
            onMouseLeave={disableDrawMode ? noop : handleMouseLeave}
            ref={stageRef}
            style={{ position: 'absolute' }}
          >
            <Layer>
              <AnnotationsList
                annotations={annotationsToDraw}
                isComparisonMode={isComparisonMode}
                confidenceLevelValue={confidenceLevelValue}
                onSelectedAnnotation={selectAnnotation}
                selectedAnnotationId={selectedId}
                suggestedFramesNumbers={suggestedFramesNumbers}
              />
            </Layer>
          </Stage>
        </div>
      </div>

      {!isComparisonMode && (
        <TimelinePlayer
          className='col-span-full'
          isPlaying={isPlaying}
          onPlay={onPlayPress}
          duration={instanceRef.current?.duration() ?? 0}
          onReplay={() => {
            const hasUnsavedAnnotations = checkUnsavedAnnotations()
            if (hasUnsavedAnnotations) return

            replayTen()
          }}
          onForward={() => {
            const hasUnsavedAnnotations = checkUnsavedAnnotations()
            if (hasUnsavedAnnotations) return

            forwardTen()
          }}
          onProgressChange={onProgressChange}
          onPlaybackRateChange={onPlaybackRateChange}
          initialMarkers={progressBarMarkers}
          thumbnailsList={thumbnails}
          videoFileId={videoFileId ?? ''}
          hideControls={projectViewMode === projectViewModes.IMPROVE}
          userGeneratedAnnotations={userGeneratedAnnotations}
          suggestedFrames={suggestedFrames}
          onNextSuggestedFrame={onNextSuggestedFrame}
          onPreviousSuggestedFrame={onPreviousSuggestedFrame}
          onTighten={handleTightenUpAnnotations}
        />
      )}
    </>
  )
}

export default VideoViewer
