import { Icon, Slider, Switch, TextInput, Typography, Button } from '@htaic/cue'
import { IconButton } from '@mui/material'
import { useMemo, useState, useCallback } from 'react'
import { useAppState } from '@training/hooks/useAppState'
import { DEFAULT_CONFIDENCE_LEVEL, isImproveViewMode, projectViewModes } from '@training/constants'
import { isNaN, isNull } from 'lodash'
import { twMerge } from 'tailwind-merge'
import { frameToSeconds } from '@training/utils/videoTimeConverter'
import { useSyncVideoPlayerInstances, useVideoPlayer } from '@training/hooks/useVideoPlayer'
import { AnnotationMarkersResponseV2 } from '@training/apis/types'
import { useSaveVideoAnnotations, useVideoAnnotations } from '@training/hooks/useVideoAnnotations'
import { useModalState } from '@training/hooks/useModal'
import { ConfirmSaveModal } from '@training/components/Modals/ConfirmSaveModal'
import { startMeasure } from '@training/utils/performance'
import { ProgressIndicator } from './ProgressIndicator'

interface AnnotateAreaActionProps {
  isComparisonMode: boolean
  title: string
  suggestedFrames?: AnnotationMarkersResponseV2['foi']
  isPlaceholder?: boolean
  hasInferenceErrors: boolean
  onReapplyModel?: () => void
}

interface SuggestedFramesProgressProps {
  suggestedFrameNumbers: { frameNumber: number; isCompleted: boolean }[]
  onProgressIndicatorClick: (frameNumber: number) => void
}

const SuggestedFramesProgress = (props: SuggestedFramesProgressProps) => {
  const { suggestedFrameNumbers, onProgressIndicatorClick } = props
  const currentFrame = useVideoPlayer()((state) => state.currentFrame.rounded)

  return (
    <div className='flex gap-2'>
      {suggestedFrameNumbers.map((value, index) => {
        return (
          <ProgressIndicator
            data-testid={`suggested-frame-${value.frameNumber}`}
            onClick={() => onProgressIndicatorClick(value.frameNumber)}
            key={`${value}-${index}`}
            completed={value.isCompleted}
            selected={currentFrame === value.frameNumber}
          />
        )
      })}
    </div>
  )
}

export const AnnotateAreaActions = (props: AnnotateAreaActionProps) => {
  const {
    isComparisonMode,
    title,
    suggestedFrames,
    isPlaceholder,
    hasInferenceErrors,
    onReapplyModel,
  } = props
  const [lastConfidenceLevel, setLastConfidenceLevel] = useState<number | null>(null)
  const confidenceLevelValue = useAppState((state) => state.confidenceLevelValue)
  const setConfidenceLevelValue = useAppState((state) => state.setConfidenceLevelValue)

  const projectViewMode = useAppState((state) => state.projectViewMode)
  const setProjectViewMode = useAppState((state) => state.setProjectViewMode)

  const displayConfidenceLevel = useMemo(
    () =>
      typeof confidenceLevelValue === 'number'
        ? confidenceLevelValue
        : (lastConfidenceLevel ?? DEFAULT_CONFIDENCE_LEVEL),
    [confidenceLevelValue, lastConfidenceLevel]
  )

  const renderConfidenceLevel = () => {
    return (
      <div
        className='flex flex-col py-2 mr-2 w-[443px] self-end'
        data-testid='confidence-level-slider'
      >
        <Typography variant='caption' className='dark:text-semantic-primary'>
          Minimum Confidence Level
        </Typography>
        <div className='flex gap-2 items-center w-full'>
          <Switch
            checkedText='On'
            variant='contained'
            uncheckedText='Off'
            checked={!isNull(confidenceLevelValue)}
            onChange={() => {
              if (typeof confidenceLevelValue === 'number') {
                setLastConfidenceLevel(confidenceLevelValue)
                setConfidenceLevelValue(null)

                return
              }

              setConfidenceLevelValue(lastConfidenceLevel ?? DEFAULT_CONFIDENCE_LEVEL)
            }}
          />
          <IconButton
            onClick={() => {
              setConfidenceLevelValue(Math.max(0, Number(confidenceLevelValue) - 1))
            }}
          >
            <Icon name='Exception' size='xsmall' className='shrink-0 text-icon-default' />
          </IconButton>
          <Slider
            disabled={isNull(confidenceLevelValue)}
            size='small'
            track='inverted'
            className='w-full'
            classes={{
              rail: !isNull(confidenceLevelValue) ? 'bg-primary' : '',
              track: 'bg-neutral-grey-12',
            }}
            value={displayConfidenceLevel}
            min={0}
            max={100}
            onChange={(_, newValue) => {
              setConfidenceLevelValue(newValue as number)
            }}
          />
          <IconButton
            onClick={() => {
              setConfidenceLevelValue(Math.min(100, Number(confidenceLevelValue) + 1))
            }}
          >
            <Icon name='Add' size='xsmall' className='shrink-0 text-icon-default' />
          </IconButton>
          <div className='flex'>
            <TextInput
              disabled={isNull(confidenceLevelValue)}
              containerClassName={twMerge(
                'bg-transparent w-8 border-0 border-b-2 pl-0 pr-1',
                isNull(confidenceLevelValue)
                  ? 'hover:border-neutral-grey-12'
                  : 'hover:border-primary'
              )}
              className='bg-transparent text-sm text-icon-default text-right'
              maxLength={3}
              value={displayConfidenceLevel}
              onChange={(e) => {
                const value = Number(e.target.value)
                if (!isNaN(value) && value <= 100) {
                  setConfidenceLevelValue?.(value)
                }
              }}
              type='text'
              pattern='[0-9]*'
              inputMode='numeric'
            />
            <Typography className='font-medium text-semantic-secondary pt-1' variant='body2'>
              %
            </Typography>
          </div>
        </div>
      </div>
    )
  }
  const renderComparisonMode = () => {
    return (
      <>
        <div className='flex justify-between items-end'>
          <Typography variant='subhead1'>{title}: Previous</Typography>
        </div>
        <div className='flex justify-between items-end'>
          <Typography variant='subhead1'>{title}: Current</Typography>
          {hasInferenceErrors && !isImproveViewMode(projectViewMode) ? (
            <div className='flex justify-between'>
              <Button
                color='secondary'
                onClick={handleReapplyClick}
                className='min-w-40 dark:bg-neutral-grey-12 dark:border-neutral-grey-10 mb-1'
              >
                Reapply model
              </Button>
            </div>
          ) : null}
        </div>
      </>
    )
  }

  const suggestedFrameNumbers = useMemo(
    () =>
      suggestedFrames?.map((frame) => ({
        frameNumber: frame.frameNumber,
        isCompleted: frame.isSavedToAnnotations,
      })) ?? [],
    [suggestedFrames]
  )
  const frameRate = useVideoPlayer()((state) => state.frameRate)
  const { setCurrentTime } = useSyncVideoPlayerInstances()

  const clearAnnotationsToTighten = useVideoAnnotations()(
    (state) => state.clearAnnotationsToTighten
  )

  const { modifiedAnnotations, clearModifiedAnnotations, loadInitialAnnotations } =
    useVideoAnnotations()((state) => ({
      modifiedAnnotations: state.modifiedAnnotations,
      clearModifiedAnnotations: state.clearModifiedAnnotations,
      loadInitialAnnotations: state.loadInitialAnnotations,
    }))

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const triggerSave = useSaveVideoAnnotations((state) => state.triggerSave)

  const checkUnsavedAnnotations = useCallback(() => {
    if (modifiedAnnotations.size > 0) {
      openModal({
        size: 'sm',
        content: (
          <ConfirmSaveModal
            onDiscard={() => {
              loadInitialAnnotations()
              clearAnnotationsToTighten()
              clearModifiedAnnotations()
              closeModal()
            }}
            onSave={() => {
              startMeasure('save-annotations-payload')
              triggerSave(true)
              closeModal()
            }}
          />
        ),
      })
      return true
    }
    return false
  }, [
    clearModifiedAnnotations,
    clearAnnotationsToTighten,
    closeModal,
    loadInitialAnnotations,
    modifiedAnnotations.size,
    openModal,
    triggerSave,
  ])

  const onProgressIndicatorClick = useCallback(
    (frameNumber: number) => {
      const hasUnsavedAnnotations = checkUnsavedAnnotations()

      if (hasUnsavedAnnotations) return

      clearAnnotationsToTighten()
      const timestamp = frameToSeconds(frameNumber, frameRate, 3)
      setCurrentTime(timestamp)
    },
    [frameRate, setCurrentTime, clearAnnotationsToTighten, checkUnsavedAnnotations]
  )

  const handleSwitchModeClick = useCallback(() => {
    setProjectViewMode(
      projectViewMode === projectViewModes.ADD_MORE
        ? projectViewModes.IMPROVE
        : projectViewModes.ADD_MORE
    )
  }, [projectViewMode, setProjectViewMode])

  const handleReapplyClick = () => {
    onReapplyModel?.()
  }

  const isMarkedAllInitalSuggestedFrames = suggestedFrameNumbers.every((frame) => frame.isCompleted)

  const renderNormalMode = () => {
    return (
      <div className='flex flex-col self-end justify-end py-2'>
        {projectViewMode === projectViewModes.REVIEW ? renderConfidenceLevel() : null}

        <div className='flex justify-between'>
          <Typography variant='heading2' className='dark:text-semantic-secondary'>
            {title}
          </Typography>
          {isImproveViewMode(projectViewMode) && !isPlaceholder ? (
            <div className='flex gap-4 items-center'>
              {projectViewMode === projectViewModes.ADD_MORE ? null : (
                <SuggestedFramesProgress
                  suggestedFrameNumbers={suggestedFrameNumbers}
                  onProgressIndicatorClick={onProgressIndicatorClick}
                />
              )}
              <Button
                color='neutral'
                variant='outlined'
                onClick={handleSwitchModeClick}
                className='min-w-40'
                disabled={!isMarkedAllInitalSuggestedFrames}
                data-testid='switch-mode-button'
              >
                {projectViewMode === projectViewModes.ADD_MORE ? 'Suggested frames' : 'Add more'}
              </Button>
            </div>
          ) : null}
          {hasInferenceErrors && !isImproveViewMode(projectViewMode) ? (
            <div className='flex justify-between'>
              <Button
                color='secondary'
                onClick={handleReapplyClick}
                className='min-w-40 dark:bg-neutral-grey-12 dark:border-neutral-grey-10'
                data-testid='reapply-button'
              >
                Reapply model
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  return isComparisonMode ? (
    <>
      <div className='flex col-span-full justify-end'>{renderConfidenceLevel()}</div>
      {renderComparisonMode()}
    </>
  ) : (
    renderNormalMode()
  )
}
