import { useEffect, useCallback } from 'react'
import { Icon, Typography, ModalContent, ModalHeader } from '@htaic/cue'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'

interface KeyBoardLedgeProps {
  onPlay?: () => void
  onReplay?: () => void
  onForward?: () => void
  onSave?: () => void
  onForwardReferencePoint?: () => void
  onReverseReferencePoint?: () => void
  onNextSuggestedFrame?: () => void
  onPreviousSuggestedFrame?: () => void
  enabledEvents?: Readonly<KeyBoardLedgeEvents[]>
  onTighten?: () => void
}

const keyBoardLedgeEvents = [
  'play',
  'replay',
  'forward',
  'save',
  'forwardReferencePoint',
  'reverseReferencePoint',
  'nextSuggestedFrame',
  'previousSuggestedFrame',
] as const

type KeyBoardLedgeEvents = (typeof keyBoardLedgeEvents)[number]

export const KeyBoardLedgeModal = () => {
  const showReferencePointFeature = useFeatureFlags().REFERENCE_POINTS

  return (
    <ModalContent data-testid='key-board-modal'>
      <ModalHeader closable>Keyboard shortcuts</ModalHeader>
      <div className='pt-5 grid grid-rows-3 gap-x-2 justify-items-start md:grid-cols-1 grid-cols-[max-content_max-content] w-full xl:grid-cols-2 lg:grid-cols-2 '>
        <div className='flex items-end p-2 border border-neutral-grey-14 dark:border-neutral-grey-8 border-solid w-full'>
          <div className='w-[190px]'>
            <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded pt-6 pl-1 text-center '>
              <Typography variant='body2' className='text-semantic-primary'>
                space
              </Typography>
            </div>
          </div>
          <Typography variant='body2' className='text-semantic-primary w-1/2'>
            Play/pause video
          </Typography>
        </div>
        <div className='flex items-end content-between p-2 border border-neutral-grey-14 dark:border-neutral-grey-8 border-solid w-full'>
          <div className='w-[190px]'>
            <div className='flex items-center gap-x-2'>
              <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded text-center pt-5 '>
                <Icon name='Previous' className='ml-4 text-semantic-primary' />
              </div>
              <Typography variant='body2' className='text-semantic-primary'>
                or
              </Typography>
              <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded text-center pt-5  '>
                <Icon name='Next' className='ml-4 text-semantic-primary' />
              </div>
            </div>
          </div>

          <Typography variant='body2' className='text-semantic-primary break-words w-1/2'>
            Go backwards/forwards 10 seconds
          </Typography>
        </div>

        {showReferencePointFeature && (
          <>
            <div className='flex items-end content-between p-2 border border-neutral-grey-14 dark:border-neutral-grey-8 border-solid w-full'>
              <div className='w-[190px] '>
                <div className='flex items-center gap-x-2'>
                  <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded  text-center pt-5 '>
                    <Typography variant='body2' className='text-semantic-primary'>
                      shift
                    </Typography>
                  </div>
                  <Typography variant='body2' className='text-semantic-primary'>
                    +
                  </Typography>
                  <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded text-center pt-5'>
                    <Icon name='Previous' className='ml-4 text-semantic-primary' />
                  </div>
                </div>
              </div>
              <Typography variant='body2' className='text-semantic-primary w-1/2'>
                Jump to previous reference point
              </Typography>
            </div>
            <div className='flex items-end content-between p-2 border border-neutral-grey-14 dark:border-neutral-grey-8 border-solid w-full'>
              <div className='w-[190px] '>
                <div className='flex items-center gap-x-2'>
                  <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded text-center pt-5 '>
                    <Typography variant='body2' className='text-semantic-primary'>
                      shift
                    </Typography>
                  </div>
                  <Typography variant='body2' className='text-semantic-primary'>
                    +
                  </Typography>
                  <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded  text-center pt-5 '>
                    <Icon name='Next' className='ml-4 text-semantic-primary' />
                  </div>
                </div>
              </div>
              <Typography variant='body2' className='text-semantic-primary w-1/2'>
                Jump to next reference point
              </Typography>
            </div>
          </>
        )}

        <div className='flex items-end p-2  border border-neutral-grey-14 dark:border-neutral-grey-8 border-solid w-full'>
          <div className='w-[190px]'>
            <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded p-6'>
              <Typography variant='body2' className='text-semantic-primary'>
                t
              </Typography>
            </div>
          </div>
          <Typography variant='body2' className='text-semantic-primary w-1/2'>
            Tighten all bounding boxes on current screen
          </Typography>
        </div>
        <div className='flex items-end p-2  border border-neutral-grey-14 dark:border-neutral-grey-8 border-solid w-full'>
          <div className='w-[190px]'>
            <div className='w-14 h-14 bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded p-6 '>
              <Typography variant='body2' className='text-semantic-primary'>
                s
              </Typography>
            </div>
          </div>
          <Typography variant='body2' className='text-semantic-primary w-1/2'>
            Save bounding box
          </Typography>
        </div>
        <div className='flex items-end content-between p-2 border border-neutral-grey-14 dark:border-neutral-grey-8 border-solid w-full'>
          <div className='w-[190px]'>
            <div className='flex items-center gap-x-2'>
              <div className='w-14 h-14 flex items-center justify-center bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded text-center'>
                <Icon name='KeypadDeleteInput' size='large' className='text-semantic-primary' />
              </div>
              <Typography variant='body2' className='text-semantic-primary'>
                or
              </Typography>
              <div className='w-14 h-14 flex items-center justify-center bg-neutral-grey-15 dark:bg-neutral-grey-11 rounded text-center text-[#2F323A]'>
                <Typography variant='body2' className='text-semantic-primary'>
                  delete
                </Typography>
              </div>
            </div>
          </div>
          <Typography variant='body2' className='text-semantic-primary break-words w-1/2'>
            Remove selected bounding box
          </Typography>
        </div>
      </div>
    </ModalContent>
  )
}

export const KeyBoardLedgeListener = ({
  onPlay,
  onReplay,
  onForward,
  onSave,
  onForwardReferencePoint,
  onReverseReferencePoint,
  onNextSuggestedFrame,
  onPreviousSuggestedFrame,
  enabledEvents = keyBoardLedgeEvents,
  onTighten,
}: KeyBoardLedgeProps) => {
  const showReferencePointFeature = useFeatureFlags().REFERENCE_POINTS

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const inputElement = event.target as HTMLElement
      if (inputElement?.tagName === 'INPUT' || inputElement?.tagName === 'TEXTAREA') {
        return
      }
      event.preventDefault()
      if (event.key === ' ' && enabledEvents.includes('play')) {
        onPlay?.()
      }

      if (
        event.shiftKey &&
        event.key === 'ArrowRight' &&
        showReferencePointFeature &&
        enabledEvents.includes('forwardReferencePoint')
      ) {
        onForwardReferencePoint?.()
      }

      if (
        event.shiftKey &&
        event.key === 'ArrowLeft' &&
        showReferencePointFeature &&
        enabledEvents.includes('reverseReferencePoint')
      ) {
        onReverseReferencePoint?.()
      }

      if (!event.shiftKey && event.key === 'ArrowRight' && enabledEvents.includes('forward')) {
        onForward?.()
      }

      if (!event.shiftKey && event.key === 'ArrowLeft' && enabledEvents.includes('forward')) {
        onReplay?.()
      }

      if (event.key === 's' && enabledEvents.includes('save')) {
        onSave?.()
      }

      if (
        event.shiftKey &&
        event.key === 'ArrowRight' &&
        enabledEvents.includes('nextSuggestedFrame')
      ) {
        onNextSuggestedFrame?.()
      }

      if (
        event.shiftKey &&
        event.key === 'ArrowLeft' &&
        enabledEvents.includes('previousSuggestedFrame')
      ) {
        onPreviousSuggestedFrame?.()
      }
      if (event.key === 't') {
        onTighten?.()
      }
    },
    [
      onForward,
      onPlay,
      onReplay,
      onSave,
      onForwardReferencePoint,
      onReverseReferencePoint,
      showReferencePointFeature,
      enabledEvents,
      onNextSuggestedFrame,
      onPreviousSuggestedFrame,
      onTighten,
    ]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])
  return null
}
