import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayjsTimezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)
dayjs.extend(dayjsTimezone)
dayjs.extend(advancedFormat)

export function getTimeWithTimezone(date: Date | number, timezone?: string): string {
  const timezoneName = timezone || dayjs.tz.guess()
  const localizedTime = dayjs(date).tz(timezoneName).format('LTS')

  const getShortTimezone = () => {
    try {
      return new Intl.DateTimeFormat('en-us', {
        timeZone: timezoneName,
        timeZoneName: 'short',
      })
        .format(date)
        .split(' ')[1]
    } catch (error) {
      return dayjs(date).tz(timezoneName).format('z')
    }
  }

  return `${localizedTime} ${getShortTimezone()}`
}
