import { useEffect, memo, useState } from 'react'
import {
  open,
  LoggerConfig,
  LogLevel,
  LogSink,
  getLogger,
} from '@htaic/standard-logging-library-typescript-browser'
import { endpoints, cloudPlatformLoginUrls } from './constants'

// eslint-disable-next-line react-refresh/only-export-components
export const useGetRedirectUrl = () => {
  const currentFlexAiUrl = window.location.hostname
  const currentCloudPlatformLoginUrl = cloudPlatformLoginUrls.find(
    (url) => url.flexaiUrl === currentFlexAiUrl
  )
  if (currentCloudPlatformLoginUrl) {
    return currentCloudPlatformLoginUrl.name
  }
  return cloudPlatformLoginUrls[0].name
}

const OpenStandardLogging = () => {
  const [envName, setEnvName] = useState('')

  // const defaultLoggerConfig = getDefaultLoggerConfig()

  /*
   * This is an example of setting up a LoggerConfig object for use with DataDog
   */

  const enviName = useGetRedirectUrl()

  useEffect(() => {
    setEnvName(enviName)
  }, [enviName])

  useEffect(() => {
    if (envName === 'dev') {
      return
    }

    if (envName) {
      const loggerConfig: LoggerConfig = {
        logSink: LogSink.DATADOG,
        logLevel: LogLevel.DEBUG,
        isExtendConsole: true,
        datadogClientToken: endpoints.VITE_DATADOG_CLIENT_TOKEN,
        datadogSite: endpoints.VITE_DATADOG_URL,

        // Leave this set to true for forward errors to DataDog
        datadogForwardErrorsToLogs: false,

        // Leave this set to 100 to forward logs to DataDog for all user sessions
        datadogSessionSampleRate: 100,

        datadogServiceName: 'anaheim-portal',

        datadogEnvName: envName,
      }

      const openLogger = async () => {
        // Calling open with no args uses the defaultLoggerConfig object.
        // REACT_APP_ environment variable such as REACT_APP_WEBSOCKETHOST can be used to override the default values.
        try {
          await open(loggerConfig)
        } catch (err) {
          // Do something with the error, this is a fatal error if the logger cannot be opened
          // eslint-disable-next-line no-console
          console.error(`OpenStandardLogging: fatal error - cannot open standard logger`)
        }
        getLogger()
      }
      openLogger()
    }

    // eslint-disable-next-line consistent-return
    return () => {
      // this is called when the component unmounts. We don't call the logger.close method, we just keep the websocket open.
      // This works fine since we only call open once by checking isOpenLoggerCalled in the library.
    }
  }, [envName])

  return null
}

const MemoizedOpenStandardLogging = memo(OpenStandardLogging)

export default MemoizedOpenStandardLogging
