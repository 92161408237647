import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Button, Icon, ScrollBar, Modal, ModalHeader, ModalContent } from '@htaic/cue'
import { VideoFile, ProjectVersion, EventFor } from '@training/types'
import ClipList from '@training/components/ClipList'
import { useVideoPlayer } from '@training/hooks/useVideoPlayer'
import { twMerge } from 'tailwind-merge'
import { useGetProjectVideos } from '@training/apis/videoFiles/requests'
import { useGetThumbnailsAndAnnotation } from '@training/apis/training-assets/requests'
import { flatMap, head, isEmpty, isNil, keyBy, orderBy } from 'lodash'
import { frameToFormattedTime, frameToSeconds } from '@training/utils/videoTimeConverter'
import { IconButton } from '@mui/material'
import { usePopper } from '@training/hooks/usePopper'
import { useAppState } from '@training/hooks/useAppState'
import { isImproveViewMode, projectViewModes } from '@training/constants'
import AnnotateArea from './AnnotateArea'
import { AnnotationThumbnails } from './AnnotationThumbnails'
import AnnotationImage from '../AnnotationImage'

interface AnnotationsOverviewModalProps {
  open: boolean
  onClose: () => void
  onAnnotationEditClick: ({ clip, time }: { clip: VideoFile; time: number }) => void
}

export const AnnotationsOverviewModal = (props: AnnotationsOverviewModalProps) => {
  const { open, onClose, onAnnotationEditClick } = props

  const { id } = useParams()

  const { data: projectThumbnails } = useGetThumbnailsAndAnnotation({
    projectId: id as string,
  })

  const { data: projectVideos } = useGetProjectVideos(id as string)

  const projectThumbnailsImages = useMemo(
    () => flatMap(projectThumbnails, (projectThumbnail) => projectThumbnail.images),
    [projectThumbnails]
  )

  const projectThumbnailsSorted = useMemo(
    () =>
      orderBy(projectThumbnails, [
        (projectThumbnail) => projectThumbnail.videoId,
        (projectThumbnail) => head(projectThumbnail.images)?.timeOffset,
      ]),
    [projectThumbnails]
  )

  const projectThumbnailsImagesCount = useMemo(
    () => projectThumbnailsImages.length,
    [projectThumbnailsImages]
  )

  const projectVideoLookup = useMemo(
    () => keyBy(projectVideos, (projectVideo) => projectVideo.id),
    [projectVideos]
  )

  if (!open) {
    return null
  }

  return (
    <Modal
      id='modal-annotations-overview'
      open={open}
      data-testid='modal-annotations-overview'
      aria-modal='true'
      size='xl'
      onClose={onClose}
    >
      <ModalContent>
        <ModalHeader closable className='pb-3'>
          Training Collection
        </ModalHeader>
        <Typography>
          All detection boxes to be included in the AI model. Detection boxes can be edited on the
          main page.
        </Typography>
        <Typography tag='span' className='text-icon-default invisible'>
          {projectThumbnailsImagesCount} Total Detection Boxes
        </Typography>
        <ScrollBar className='h-[80svh] pb-2.5'>
          {isEmpty(projectThumbnails) ? (
            <div className='flex items-center justify-center h-full border border-dashed border-border-default rounded-2xl'>
              <Typography className='text-center text-icon-default' variant='caption'>
                The Training Collection is currently empty.
              </Typography>
            </div>
          ) : (
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
              {flatMap(projectThumbnailsSorted, (projectThumbnail) => {
                return projectThumbnail.images.map((image, index) => {
                  const projectVideo = projectVideoLookup[projectThumbnail.videoId]

                  return (
                    <div
                      key={`${image.timeOffset}-${index}`}
                      className='bg-neutral-grey-15 p-3 pt-2 pb-12'
                      data-testid='thumbnail-preview-container'
                    >
                      <div className='flex mb-8 items-center gap-1'>
                        <Typography className='flex'>
                          {projectThumbnail.name}&nbsp;-&nbsp;
                          {!isNil(image.timeOffset)
                            ? frameToFormattedTime(image.timeOffset, projectThumbnail.frameRate)
                            : ''}
                        </Typography>
                        {!projectVideo?.verifiedJsonUrl && (
                          <IconButton
                            data-testid='edit-annotation-button'
                            className='h-10 w-10'
                            title='Go to frame'
                            onClick={() => {
                              const timestamp = frameToSeconds(
                                image.timeOffset,
                                projectVideo.frameRate,
                                3
                              )

                              onAnnotationEditClick?.({
                                clip: projectVideo,
                                time: timestamp,
                              })
                            }}
                          >
                            <Icon name='Pencil' className='pointer-events-none text-icon-default' />
                          </IconButton>
                        )}
                      </div>
                      <AnnotationImage
                        className='[&_*]:hover:cursor-default'
                        thumbnail={image.image}
                        stackedEffect={false}
                      />
                    </div>
                  )
                })
              })}
            </div>
          )}
        </ScrollBar>
      </ModalContent>
    </Modal>
  )
}

interface AnnotateTabProps {
  projectVersionId?: string
  projectVersionStatus?: ProjectVersion['status']
  projectVersions: ProjectVersion[]
  objClassId?: string
  isTrained: boolean
}
const AnnotateTab = ({
  projectVersionId,
  projectVersionStatus,
  projectVersions,
  isTrained,
  objClassId,
}: AnnotateTabProps) => {
  const [clip, setClip] = useState<VideoFile>({} as VideoFile)
  const [isClipListCollapsed, setIsClipListCollapsed] = useState(false)
  const [isAnnotationsOverviewModalOpen, setIsAnnotationsOverviewModalOpen] = useState(false)
  const { id: projectId } = useParams()

  const handleToggleClipList = () => {
    setIsClipListCollapsed(!isClipListCollapsed)
  }

  const setProjectViewMode = useAppState((state) => state.setProjectViewMode)
  const projectViewMode = useAppState((state) => state.projectViewMode)

  useEffect(() => {
    if (projectViewMode === projectViewModes.ADD_MORE) {
      setProjectViewMode(projectViewModes.IMPROVE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProjectViewMode, clip.id])

  const onClipChange = useCallback((newClip: VideoFile) => {
    setClip(newClip)
  }, [])

  const isComparisonMode = useVideoPlayer()((state) => state.isComparisonMode)
  const setDesiredTime = useVideoPlayer()((state) => state.setDesiredTime)

  const onAnnotationEditClick = useCallback<
    EventFor<typeof AnnotationsOverviewModal, 'onAnnotationEditClick'>
  >(
    ({ clip: selectedClip, time }) => {
      if (selectedClip) {
        setClip(selectedClip)
      }
      setIsAnnotationsOverviewModalOpen(false)

      if (!selectedClip?.verifiedJsonUrl) {
        setDesiredTime(time)
      }
    },
    [setDesiredTime]
  )

  const { setPopper } = usePopper()
  const lastTrainedProjectVersionId = projectVersions.find(
    (projectVersion) => projectVersion.status === 'trained'
  )?.id

  const showInfoEnterHandler: React.ComponentProps<'button'>['onMouseEnter'] = (e) => {
    setPopper({
      id: 'info-popover',
      anchorEl: e.currentTarget,
      placement: 'bottom',
      content: (
        <Typography
          variant='body2'
          font='secondary'
          className='text-white text-xs'
          data-testid='mode-info-popover'
        >
          {projectViewMode === projectViewModes.IMPROVE
            ? 'Draw detection boxes around the object in the selected frame. You can add more frames once you have finished all suggested ones.'
            : 'Use the Confidence Level Slider to filter out detection boxes with lower confidence scores.'}
        </Typography>
      ),
      className: 'w-56',
      closable: false,
    })
  }

  return (
    <div className='flex gap-6'>
      <AnnotationsOverviewModal
        open={isAnnotationsOverviewModalOpen}
        onClose={() => setIsAnnotationsOverviewModalOpen(false)}
        onAnnotationEditClick={onAnnotationEditClick}
      />

      <div className='flex flex-col'>
        {isImproveViewMode(projectViewMode) || projectViewMode === projectViewModes.REVIEW ? (
          <div className='flex px-4 pt-6 pb-4'>
            <Typography
              variant='heading3'
              className='text-[28px] leading-9 dark:text-semantic-primary'
              data-testid='annotate-tab-title'
            >
              {isImproveViewMode(projectViewMode) ? 'Improve' : 'Review'}
            </Typography>
            <button
              className='flex items-center justify-center bg-transparent border-none px-2 pt-2 h-7'
              type='button'
              aria-label='Info'
              data-testid='mode-info-button'
              onMouseEnter={showInfoEnterHandler}
              onMouseLeave={() => {
                setPopper(null)
              }}
            >
              <Icon name='Info' className='text-icon-default' />
            </button>
          </div>
        ) : null}
        <div className='flex relative grow shrink-0'>
          <ClipList
            isTrained={isTrained}
            projectVersionStatus={projectVersionStatus}
            onClipChange={(newClip) => onClipChange(newClip)}
            selectedClipId={clip.id}
            onToggleClipList={handleToggleClipList}
            isCollapsed={isClipListCollapsed}
            disabled={projectVersionStatus === 'in_training'}
            lastTrainedProjectVersionId={lastTrainedProjectVersionId}
          />

          {!isComparisonMode && (
            <div
              className={twMerge(
                'flex flex-col h-[calc(100svh-7.5rem)] w-[276px] ml-[48px] relative pb-4'
              )}
            >
              <Typography className='mb-2 dark:text-semantic-secondary' variant='heading3'>
                {isImproveViewMode(projectViewMode)
                  ? 'Objects Identified'
                  : "This Frame's Detections"}
              </Typography>
              <ScrollBar
                size='small'
                className='flex flex-col grow p-4 border border-dashed rounded-lg border-neutral-grey-12 w-full'
              >
                <AnnotationThumbnails
                  clipId={clip.id}
                  isTrained={!!clip.verifiedJsonUrl}
                  projectId={projectId}
                />
              </ScrollBar>
              <Button
                color='secondary'
                onClick={() => {
                  setIsAnnotationsOverviewModalOpen(true)
                }}
                className='px-8 mx-auto mt-4 bg-inherit w-full capitalize'
              >
                See All Training Collection
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={twMerge('grow container mx-auto', !isComparisonMode && 'lg:max-w-7xl')}>
        {projectVersionId ? (
          <AnnotateArea
            objClassId={objClassId}
            projectVersionId={projectVersionId}
            clip={clip}
            projectVersions={projectVersions}
            overlayMessage={'Select a video on the left to start annotating.'}
            lastTrainedProjectVersionId={lastTrainedProjectVersionId}
          />
        ) : (
          <Typography tag='span' variant='body2' className='dark:text-semantic-secondary'>
            Loading...
          </Typography>
        )}
      </div>
    </div>
  )
}

export default AnnotateTab
