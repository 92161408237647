import { usePopper } from '@training/hooks/usePopper'
import { Popper } from '@training/components/Popper'

export const PopperProvider = () => {
  const { popper, setPopper } = usePopper()

  return popper ? (
    <Popper
      anchorEl={popper.anchorEl}
      content={popper.content}
      id={popper.id}
      className={popper.className}
      closable={popper.closable}
      placement={popper.placement}
      onClose={() => {
        popper?.onClose?.()
        setPopper(null)
      }}
    />
  ) : null
}
