import { PopperProps } from '@training/components/Popper'
import { create } from 'zustand'

type PopperStore = {
  popper: PopperProps | null
  setPopper: (popover: PopperProps | null) => void
}

export const usePopper = create<PopperStore>((set) => ({
  popper: null,
  setPopper: (popper) => set({ popper }),
}))
