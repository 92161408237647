import { useCallback, useEffect, useState, useMemo } from 'react'
import { useSnackbar, Drawer, Typography, Icon } from '@htaic/cue'
import { useBlocker, useParams } from 'react-router-dom'
import type { ProjectVersion, VideoFile, VideoFileCardData } from '@training/types'
import {
  useDeleteVideo,
  useGetProjectVideos,
  useUpdateVideo,
} from '@training/apis/videoFiles/requests'
import { useStartProjectValidation } from '@training/apis/projects/requests'
import FileUpload from '@training/pages/Project/FileUpload'
import ClipCard from '@training/pages/Project/AnnotateTab/ClipCard'
import { useTestingHelper } from '@training/hooks/useTestingHelper'
import { find, isEmpty } from 'lodash'
import { verificationLabelStatus, isImproveViewMode, clipStatusLabel } from '@training/constants'
import { usePopper } from '@training/hooks/usePopper'
import { useAppState } from '@training/hooks/useAppState'
import { useQueryClient } from '@tanstack/react-query'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { useModalState } from '@training/hooks/useModal'
import WaveSyncModal from './Modals/VmsModal/VmsModal'
import { ConfirmModal } from './Modals/ConfirmModal'

const formatVideoName = (video: VideoFile) => {
  return {
    ...video,
    name: video.originalFileName.split('.')[0],
    originalFileName: video.originalFileName.split('.')[0],
  }
}

const ClipList = ({
  onClipChange,
  isTrained,
  projectVersionStatus,
  selectedClipId,
  onToggleClipList,
  isCollapsed,
  disabled,
  lastTrainedProjectVersionId,
}: {
  onClipChange: (clip: VideoFile) => void
  isTrained: boolean
  projectVersionStatus?: ProjectVersion['status']
  selectedClipId?: string
  onToggleClipList: () => void
  isCollapsed: boolean
  disabled?: boolean
  lastTrainedProjectVersionId?: string
}) => {
  const { getTestIdProps } = useTestingHelper('clip-list')
  const { id: projectId } = useParams()

  const userId = useMinimeState((state) => state.userId)

  const { data: projectVideos = [], isPending, refetch } = useGetProjectVideos(projectId as string)

  const projectValidateMutation = useStartProjectValidation(lastTrainedProjectVersionId as string)
  const showSnackBar = useSnackbar()
  const [activeClipId, setActiveClipId] = useState(selectedClipId ?? '')

  const queryClient = useQueryClient()

  const { setVideoClipMetadata, videoClipMetadata } = useAppState((state) => ({
    videoClipMetadata: state.videoClipMetadata,
    setVideoClipMetadata: state.setVideoClipMetadata,
  }))

  const formattedClips = useMemo(() => {
    if (isEmpty(projectVideos)) return []

    return projectVideos.map(formatVideoName)
  }, [projectVideos])

  useEffect(() => {
    if (formattedClips.length === 0 || videoClipMetadata.videoFileId === undefined) return

    const hasUpdatedClip = formattedClips.find(
      (activeClip) => activeClip.id === videoClipMetadata.videoFileId
    )
    if (hasUpdatedClip) {
      refetch().then((response) => {
        if (activeClipId) {
          const activeClip = find(response.data, (clip) => clip.id === activeClipId)

          if (activeClip && onClipChange) onClipChange(formatVideoName(activeClip))
        }
      })
      setVideoClipMetadata({})
    }
  }, [activeClipId, formattedClips, onClipChange, refetch, setVideoClipMetadata, videoClipMetadata])

  const startVerification = useCallback(
    async (videoFileUUID: string) => {
      try {
        await projectValidateMutation.mutateAsync({
          action: 'start',
          videoFileName: videoFileUUID,
        })
      } catch (error) {
        showSnackBar({
          message: ` Error: Clip was not sent to validation queue in startverification. ${error} `,
          status: 'error',
        })
      }
    },
    [projectValidateMutation]
  )

  const selectCardHandler = (clipId: string) => {
    const selectedClip = formattedClips.find((clip) => clip.id === clipId)

    const clipVerificationStatus = selectedClip?.verificationLabels?.find(
      (x) => x.recency === 'current'
    )?.status

    if (clipVerificationStatus === verificationLabelStatus.INIT) {
      return
    }

    setActiveClipId(clipId)
    onClipChange(selectedClip!)
    onToggleClipList()
  }

  const toggleOpenHandler = () => {
    onToggleClipList()
  }

  const onCardNameChange = useCallback(
    (clipId: string, value: string) => {
      if (!value) {
        return
      }
      const updatedValues = formattedClips.map((clip) => {
        if (clip.id === clipId && clip.originalFileName !== value) {
          return { ...clip, name: value }
        }
        return clip
      })

      queryClient.setQueryData(['getProjectVideos', projectId], updatedValues)
    },
    [formattedClips, projectId, queryClient]
  )

  const [uploadingFiles, setUploadingFiles] = useState<{ fileName: string; progress: number }[]>([])

  const hasUploadingFiles = uploadingFiles.length > 0

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasUploadingFiles && currentLocation.pathname !== nextLocation.pathname
  )

  useEffect(() => {
    if (blocker.state === 'blocked' && hasUploadingFiles) {
      openModal({
        size: 'sm',
        onClose: () => {
          blocker.reset()
        },
        content: (
          <ConfirmModal
            id='leave-project-modal'
            title='Leave project?'
            confirmLabel='Leave anyway'
            cancelLabel='Stay in this project'
            onCancel={() => {
              blocker.reset()
              closeModal()
            }}
            onConfirm={() => {
              blocker.proceed()
              closeModal()
            }}
          >
            <>
              A video clip is currently uploading. If you leave, any video clips that haven’t
              finished uploading <span className='font-bold'>will not be added</span> to your video
              library.
            </>
          </ConfirmModal>
        ),
      })
    }
  }, [blocker, closeModal, hasUploadingFiles, openModal])

  const onUploadStartedHandler = (fileName: string) => {
    setUploadingFiles((prev) => [...prev, { fileName, progress: 0 }])
  }

  const uploadProgressHandler = (progress: number, fileName: string) => {
    setUploadingFiles((prev) =>
      prev.map((uf) => {
        if (uf.fileName === fileName) {
          return { ...uf, progress }
        }
        return uf
      })
    )
  }

  const onUploadFinishedHandler = useCallback(
    async (videoFile?: VideoFile) => {
      if (isTrained && videoFile) {
        await startVerification(`${videoFile.id}.mp4`)
      }

      setUploadingFiles((prev) => prev.filter((uf) => uf.progress < 100))

      if (uploadingFiles.every((uf) => uf.progress === 100)) {
        await refetch()
      }
    },
    [isTrained, uploadingFiles, startVerification, refetch]
  )

  const deleteClipMutation = useDeleteVideo()

  const optimisticDelete = (clipId: string) => {
    queryClient.setQueryData(['getProjectVideos', projectId], (oldData: VideoFile[]) =>
      oldData.filter((clip) => clip.id !== clipId)
    )
  }

  const confirmDeleteHandler = async (clipId: string, clipName: string) => {
    await deleteClipMutation.mutateAsync(
      { id: clipId },
      {
        onSuccess: () => {
          optimisticDelete(clipId)

          if (onClipChange && activeClipId === clipId) onClipChange({} as VideoFile)
          showSnackBar({ message: 'Clip deleted.', status: 'success' })
        },
        onError: (error) => {
          showSnackBar({
            message: String(error?.response?.data ?? '') || 'Something went wrong',
            status: 'error',
          })
        },
      }
    )
    await refetch()

    setUploadingFiles((prev) => prev.filter((uf) => uf.fileName !== clipName))
  }

  const updateClipMutation = useUpdateVideo()

  const confirmUpdateHandler = async (clipId: string, newName: string) => {
    if (newName.trim() === '') return
    const clipToUpdate = formattedClips.find((clip) => clip.id === clipId)

    if (clipToUpdate && newName && clipToUpdate.originalFileName !== newName) {
      await updateClipMutation.mutateAsync(
        { id: clipId, fileName: newName.trim(), updatedBy: userId },
        {
          onSuccess: () => {
            refetch()
            showSnackBar({ message: 'Clip name updated.', status: 'success' })
            if (onClipChange && activeClipId === clipId)
              onClipChange({ ...clipToUpdate, name: newName })
          },
          onError: (error) => {
            showSnackBar({
              message: String(error?.response?.data ?? '') || 'Something went wrong',
              status: 'error',
            })
          },
        }
      )
    }
  }

  const [isWaveSyncModalOpen, setIsWaveSyncModalOpen] = useState(false)

  const onVMSModalOpen = useCallback(() => {
    setIsWaveSyncModalOpen(true)
  }, [setIsWaveSyncModalOpen])

  const { setPopper } = usePopper()

  const showInfoEnterHandler: React.ComponentProps<'button'>['onMouseEnter'] = (e) => {
    setPopper({
      id: 'info-popover',
      anchorEl: e.currentTarget,
      placement: 'bottom',
      content: (
        <Typography variant='body2' font='secondary' className='text-white text-xs'>
          To improve model accuracy, we highly suggest using clips that come from the camera you
          plan on uploading the model to.
        </Typography>
      ),
      className: 'w-56',
      closable: false,
    })
  }

  const projectViewMode = useAppState((state) => state.projectViewMode)
  const setSuggestedFramesClipsProgress = useAppState(
    (state) => state.setsuggestedFramesClipsProgress
  )

  useEffect(() => {
    if (formattedClips.length === 0 || !isTrained) return

    const completedFoiClips = formattedClips.reduce((count, clip) => {
      if (clip?.foiAnnotationProgress?.total === 0 || !clip?.foiAnnotationProgress) return count
      return clip.foiAnnotationProgress?.annotated === clip.foiAnnotationProgress?.total
        ? count + 1
        : count
    }, 0)

    setSuggestedFramesClipsProgress(completedFoiClips / formattedClips.length)
  }, [formattedClips, setSuggestedFramesClipsProgress, isTrained])

  const renderClipItems = () => {
    if (isPending && formattedClips?.length === 0) {
      return (
        <Typography tag='span' variant='body2' className='dark:text-semantic-secondary'>
          Loading clips...
        </Typography>
      )
    }

    return formattedClips.map((card: VideoFileCardData) => {
      // init or active?
      const clipVerificationStatus =
        card.verificationLabels?.find(
          (verificationLabel) => verificationLabel.recency === 'current'
        )?.status ?? 'active'

      const isProcessing =
        clipVerificationStatus === verificationLabelStatus.INIT ||
        (projectVersionStatus === 'trained' && isEmpty(card.verificationLabels)) ||
        (isTrained && isEmpty(card.verificationLabels))

      // processing, ready to view or with error [active, pending, error]
      const status = clipStatusLabel[clipVerificationStatus]

      let cardStatus = isProcessing || card.status === 'pending' ? 'processing' : status

      const inferenceFailedNotification =
        (videoClipMetadata.status === 'inference-error' &&
          card.id === videoClipMetadata.videoFileId) ??
        false

      cardStatus = inferenceFailedNotification ? 'applying model failed' : cardStatus

      const foiAnnotated = card.foiAnnotationProgress?.annotated ?? 0
      const foiTotal = card.foiAnnotationProgress?.total ?? 0

      const foiProgress = isImproveViewMode(projectViewMode)
        ? {
            annotated: foiAnnotated,
            total: foiTotal,
            text: `${foiAnnotated < 10 ? '0' : ''}${foiAnnotated}/${foiTotal} Frames Marked`,
          }
        : undefined

      return (
        <ClipCard
          key={card.id}
          clipName={card.name}
          annotationCount={card.annotationCount}
          active={activeClipId === card.id}
          onSelect={() => selectCardHandler(card.id)}
          onNameChange={(e) => onCardNameChange(card.id, e.target.value)}
          onNameBlur={(e) => {
            e.stopPropagation()
            confirmUpdateHandler(card.id, e.target.value)
          }}
          onDelete={() => confirmDeleteHandler(card.id, card.name)}
          isProcessing={isProcessing}
          status={cardStatus}
          foiProgress={foiProgress}
        />
      )
    })
  }

  return (
    <>
      <Drawer
        open={!isCollapsed && !disabled}
        onToggle={toggleOpenHandler}
        classes={{
          paper:
            'h-full z-50 absolute top-0 p-0 dark:bg-neutral-grey-14 dark:border-neutral-grey-12',
        }}
        hideBackdrop
        {...getTestIdProps('left-panel')}
        className={disabled ? 'pointer-events-none' : ''}
      >
        <div className='p-4 pr-8'>
          <div className='flex mb-6 items-center gap-2'>
            <Typography variant='heading3' className='dark:text-semantic-secondary'>
              Video Library
            </Typography>
            <button
              className='flex items-center justify-center bg-transparent border-none m-0 p-0'
              type='button'
              aria-label='Info'
              onMouseEnter={showInfoEnterHandler}
              onMouseLeave={() => {
                setPopper(null)
              }}
            >
              <Icon name='Info' className='text-icon-default' />
            </button>
          </div>
          <div className='mb-6 px-0.5'>
            <FileUpload
              isTrained={isTrained}
              accept='video/mp4'
              clipNames={formattedClips}
              onUploadProgress={uploadProgressHandler}
              onUploadFinished={onUploadFinishedHandler}
              onUploadStarted={onUploadStartedHandler}
              onVMSModalOpen={onVMSModalOpen}
            />
          </div>
          <div className='flex flex-col gap-2'>
            {uploadingFiles.length > 0 &&
              uploadingFiles.map((file, i) => {
                return (
                  <ClipCard
                    clipName={`Uploading clip... ${file.progress.toString()}%`}
                    annotationCount={0}
                    key={i}
                    active={false}
                    onNameChange={(e) => onCardNameChange('uploading', e.target.value)}
                    isUploading
                    uploadingProgress={file.progress ?? 100}
                    {...getTestIdProps('uploading')}
                  />
                )
              })}
            {renderClipItems()}
          </div>
        </div>
      </Drawer>
      <WaveSyncModal
        isOpen={isWaveSyncModalOpen}
        isTrained={isTrained}
        onClose={() => {
          setIsWaveSyncModalOpen(false)
        }}
        onVideoUploaded={() => {
          refetch()
        }}
        onVerifyVideo={startVerification}
      />
    </>
  )
}

export default ClipList
