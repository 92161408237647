import { VideoAnnotationsResponse } from '@training/apis/types'
import { VideoAnnotation } from '@training/hooks/useVideoAnnotations'

export const convertGeneratedAnnotations = (generatedAnnotations: VideoAnnotationsResponse) => {
  const annotations: VideoAnnotation[] = []
  Object.keys(generatedAnnotations).forEach((key, index) => {
    const frame = key
    const annotation = generatedAnnotations[key]

    // to handle old and new format
    const getAnnotationList = () => (annotation instanceof Array ? annotation : annotation.bboxes)

    getAnnotationList().forEach((item) => {
      const { xmin, ymin, xmax, ymax } = item.location

      annotations.push({
        x: xmin,
        y: ymin,
        width: xmax - xmin,
        height: ymax - ymin,
        frameStart: parseFloat(frame),
        frameEnd: parseFloat(frame),
        id: `${index}-${xmin}-${ymin}`,
        annotationId: item.metadata?.annotation_id,
        confidence: item.confidence,
        type: item.metadata?.classification || 'inference',
      })
    })
  })
  return annotations
}
