import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { Button, Icon, TextCarousel, Typography, useSnackbar } from '@htaic/cue'
import { ConfirmModal } from '@training/components/Modals/ConfirmModal'
import { PROJECT_COMPILED_STATUS, isImproveViewMode, projectViewModes } from '@training/constants'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import { Project } from '@training/types'
import { useSyncVideoPlayerInstances, useVideoPlayer } from '@training/hooks/useVideoPlayer'
import { useDownloadModel } from '@training/hooks/useDownloadModel'
import { useStartProjectRecompile } from '@training/apis/projects/requests'
import { useGetProjectVideos } from '@training/apis/videoFiles/requests'
import { useAppState } from '@training/hooks/useAppState'
import { useTestingHelper } from '@training/hooks/useTestingHelper'
import { usePopper } from '@training/hooks/usePopper'
import useFormattedDateTime from '@training/hooks/useFormattedDateTime'
import { isEqual } from 'lodash'
import { useModalState } from '@training/hooks/useModal'
import ProjectModal from '../../ProjectsList/ProjectModal'
import { TrainingModal } from './TrainingModal'
import { InitialModeActions } from './InitialModeActions'
import { ReviewModeActions } from './ReviewModeActions'

interface ObjectInputHeaderProps {
  project?: Partial<Project>
  onSaveProject: (value: { name?: string; notes?: string }) => void
  onDeleteProject?: (projectId: string) => void
  onStartTraining: () => void
  disabledButtons: boolean
}

const ObjectInputHeader = ({
  project,
  onDeleteProject,
  onStartTraining,
  disabledButtons,
}: ObjectInputHeaderProps) => {
  const navigate = useNavigate()
  const showSnackBar = useSnackbar()
  const { id } = useParams<{ id: string }>()

  const showDeleteProject = useFeatureFlags().DELETE_PROJECT
  const trainButtonFoisEnabled = useFeatureFlags().TRAIN_BUTTON_FOIS_ENABLED

  const [isOpenTrainingModal, setIsOpenTrainingModal] = useState(false)

  const [isRecompiling, setIsRecompiling] = useState(false)

  const { downloadHandler, isDownloading } = useDownloadModel(project?.version || [])
  const recompileMutation = useStartProjectRecompile(project?.id as string)

  const suggestedFramesClipsProgress = useAppState((state) => state.suggestedFramesClipsProgress)

  const oneClipHasCompleteFois = trainButtonFoisEnabled ? suggestedFramesClipsProgress > 0 : true

  const { data: projectVideos = [] } = useGetProjectVideos(project?.id as string)

  const { getTestIdProps } = useTestingHelper('new-project')

  const setIsStartingTraining = useAppState((state) => state.setIsStartingTraining)

  const setProjectViewMode = useAppState((state) => state.setProjectViewMode)
  const projectViewMode = useAppState((state) => state.projectViewMode)

  const { setComparisonMode } = useVideoPlayer()()
  const isComparisonMode = useVideoPlayer()((state) => state.isComparisonMode)

  const downloadModelVersion =
    project?.version?.find((v) => v.status === 'trained') ??
    project?.version?.[1] ??
    project?.version?.[0]

  const isUsingPreviousVersion =
    isEqual(downloadModelVersion, project?.version?.[1]) &&
    project?.version?.[0].status === 'annotated'

  const formattedUpdatedAt = useFormattedDateTime(downloadModelVersion?.updatedAt, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  useEffect(() => {
    if (isComparisonMode) {
      setProjectViewMode(projectViewModes.REVIEW)
    }
  }, [isComparisonMode, setProjectViewMode])

  const hasAnnotations = useMemo(
    () => projectVideos.some((projectVideo) => projectVideo.annotationCount > 0),
    [projectVideos]
  )

  const isTraining = project?.version?.[0]?.status === 'in_training'

  useEffect(() => {
    setIsStartingTraining(isOpenTrainingModal)
    if (project?.version?.[0]?.status === 'trained') {
      setIsOpenTrainingModal(false)
    }
  }, [isOpenTrainingModal, project?.version, setIsStartingTraining])

  useEffect(() => {
    if (isRecompiling) {
      if (downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.compiled) {
        setIsRecompiling(false)
        showSnackBar({
          message: `${project?.name} is ready for download.`,
          status: 'success',
        })
      }
    } else if (downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.error) {
      setIsRecompiling(false)
      showSnackBar({
        message: `Error: Unable to compile ${project?.name}. Go to the project to retry.`,
        status: 'error',
      })
    }
  }, [downloadModelVersion?.compiledStatus])

  useEffect(() => {
    setIsRecompiling(true)

    if (
      !downloadModelVersion?.compiledStatus ||
      downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.compiled
    ) {
      setIsRecompiling(false)
    }

    const isStartingOrStarted =
      downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.starting ||
      downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.started

    if (isStartingOrStarted && !downloadModelVersion?.isRecompile) {
      setIsRecompiling(false)
    }

    if (isStartingOrStarted && downloadModelVersion?.isRecompile) {
      setIsRecompiling(true)
    }

    if (downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.error) {
      setIsRecompiling(false)
    }
  }, [isRecompiling, downloadModelVersion?.compiledStatus, downloadModelVersion?.isRecompile])

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()

    openModal({
      content: <ProjectModal onClose={() => closeModal()} isUpdate project={project} />,
    })
  }

  const { setPopper } = usePopper()

  const onRecompileClick = useCallback(async () => {
    setIsRecompiling(true)
    try {
      await recompileMutation.mutateAsync()
      showSnackBar({
        message: `Recompiling ${project?.name}`,
        status: 'info',
      })
    } catch (error: any) {
      showSnackBar({
        message: `Error: An error occured during recompile. ${error} `,
        status: 'error',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, recompileMutation])

  const handleStartTraining: React.ComponentProps<'button'>['onClick'] = (event) => {
    event.stopPropagation()
    onStartTraining()
    setIsOpenTrainingModal(true)
  }

  const onImproveTrainEnter = useCallback<NonNullable<React.ComponentProps<'div'>['onMouseEnter']>>(
    (e) => {
      e.stopPropagation()
      if (!oneClipHasCompleteFois) {
        setPopper({
          id: 'info-popover',
          anchorEl: e.currentTarget,
          placement: 'left',
          content: (
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              Please mark all 10 suggested frames on at least 1 video.
            </Typography>
          ),
          className: 'w-56',
          closable: false,
        })
      }
    },
    [oneClipHasCompleteFois, setPopper]
  )

  const renderInitialMode = () => (
    <InitialModeActions
      hasAnnotations={hasAnnotations}
      isDisabled={disabledButtons || project?.version?.[0]?.status === 'trained'}
      onTrain={handleStartTraining}
      projectId={project?.id as string}
    />
  )

  const { pause } = useSyncVideoPlayerInstances()

  const onImproveMode = () => {
    setComparisonMode(false)
    pause()
    setProjectViewMode(projectViewModes.IMPROVE)
  }

  const isCompiled = downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.compiled
  const isCompileEmpty = !downloadModelVersion?.compiledStatus
  const isCompileError = downloadModelVersion?.compiledStatus === PROJECT_COMPILED_STATUS.error

  const isTrained = useVideoPlayer()((state) => state.isTrained)

  const renderReviewMode = () => {
    return (
      <ReviewModeActions
        isDisabled={disabledButtons}
        isDownloadDisabled={isDownloading || !isCompiled}
        isDownloading={isDownloading}
        onDowloadClick={downloadHandler}
        onImproveClick={onImproveMode}
        isRecompileHidden={!isCompileError || isCompileEmpty}
        isRecompiling={isRecompiling}
        onRecompileClick={onRecompileClick}
        isUsingPreviousVersionForDownload={isUsingPreviousVersion}
      />
    )
  }

  const onBackToReview = () => {
    setProjectViewMode(projectViewModes.REVIEW)
  }

  const renderImproveMode = () => {
    return (
      <div className='flex gap-2 col-span-2 ml-auto'>
        <Button
          color='neutral'
          data-testid='back-to-review-button'
          className='min-w-40'
          onClick={onBackToReview}
        >
          Back To Review
        </Button>

        <div
          onMouseEnter={onImproveTrainEnter}
          onMouseLeave={
            oneClipHasCompleteFois && isImproveViewMode(projectViewMode)
              ? undefined
              : () => setPopper(null)
          }
          data-testid='new-project-train-button-container'
          role='button'
          tabIndex={0}
        >
          <Button
            color='primary'
            variant='contained'
            {...getTestIdProps('train-button')}
            aria-label='train'
            size='small'
            className='min-w-40'
            disabled={!oneClipHasCompleteFois || disabledButtons}
            onClick={handleStartTraining}
          >
            Train
          </Button>
        </div>
      </div>
    )
  }

  const actionsRenderModes = {
    [projectViewModes.INITIAL]: renderInitialMode,
    [projectViewModes.REVIEW]: renderReviewMode,
    [projectViewModes.IMPROVE]: renderImproveMode,
    [projectViewModes.ADD_MORE]: renderImproveMode,
  } as const

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const handleOpenDeleteModal = () => {
    if (onDeleteProject) {
      openModal({
        content: (
          <ConfirmModal
            id='delete-project-modal'
            title='Delete Project?'
            confirmLabel='Delete Project'
            cancelLabel='Cancel'
            onCancel={() => {
              closeModal()
            }}
            onConfirm={() => {
              if (id) onDeleteProject(id)
              closeModal()
            }}
            closable={false}
          >
            <span>
              Everything will be deleted, including annotations and models. You will be returned to
              the Project Home landing.
            </span>
          </ConfirmModal>
        ),
      })
    }
  }

  useEffect(() => {
    if (isOpenTrainingModal) {
      openModal({
        content: <TrainingModal />,
      })
    }
  }, [isOpenTrainingModal, openModal])

  return (
    <div
      data-testid='object-input-header-container'
      className='flex h-14 justify-between px-4 items-center'
    >
      <div className='flex items-center'>
        <IconButton
          aria-label='back'
          size='small'
          className='text-neutral-grey-9 mr-3'
          onClick={() => navigate('/')}
          data-testid='ArrowBackIcon'
        >
          <Icon name='Previous' className='h-5 w-5 text-icon-default' />
        </IconButton>
        <TextCarousel
          variant='heading3'
          className='max-w-60 truncate whitespace-nowrap group-hover:overflow-visible group-hover:animate-marquee'
        >
          {project?.name}
        </TextCarousel>
        <IconButton
          aria-label='edit'
          disabled={isTraining}
          data-testid='edit-project-button'
          size='small'
          title='Edit project'
          className='border-none min-w-fit p-1 cursor-pointer flex items-center mr-1'
          onClick={(e) => handleEditClick(e)}
        >
          <Icon name='Edit' className='text-icon-default size-5' />
        </IconButton>
        {showDeleteProject ? (
          <IconButton
            aria-label='delete'
            size='small'
            title='Delete project'
            className={` ${onDeleteProject ? 'text-black' : 'text-gray-400'}`}
            data-testid='delete-project-button'
            disabled={!onDeleteProject || isTraining}
            onClick={() => handleOpenDeleteModal()}
          >
            <Icon name='Delete' className='text-icon-default size-5' />
          </IconButton>
        ) : null}

        <p
          className='tracking-normal text-xs font-normal text-semantic-secondary whitespace-nowrap'
          data-testid='last-trained-date'
        >
          {isTrained ? `Last Trained: ${formattedUpdatedAt}` : ''}
        </p>
      </div>
      <div className='pt-2'>{actionsRenderModes[projectViewMode]?.()}</div>
    </div>
  )
}

export default ObjectInputHeader
