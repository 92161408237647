interface Dimensions {
  width: number
  height: number
}

interface Rect {
  x: number
  y: number
  width: number
  height: number
}

/**
 * Scales a rectangle from previous dimensions to new dimensions.
 *
 * @param {Dimensions} prevDimensions - The previous dimensions of the rectangle.
 * @param {Dimensions} newDimensions - The new dimensions of the rectangle.
 * @param {T} rect - The rectangle to scale.
 * @returns The scaled rectangle.
 * @throws {Error} If previous dimensions have a width or height of zero.
 */
export function scaleRect<T extends Rect>(
  prevDimensions: Dimensions,
  newDimensions: Dimensions,
  rect: T
): T {
  if (prevDimensions.width === 0 || prevDimensions.height === 0) {
    throw new Error('Previous dimensions cannot be zero.')
  }

  return {
    ...rect,
    x: (rect.x / prevDimensions.width) * newDimensions.width,
    y: (rect.y / prevDimensions.height) * newDimensions.height,
    width: (rect.width / prevDimensions.width) * newDimensions.width,
    height: (rect.height / prevDimensions.height) * newDimensions.height,
  }
}
