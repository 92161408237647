import { motion } from 'framer-motion'
import React, { memo } from 'react'

interface LazyImageProps extends React.ComponentProps<typeof motion.img> {
  isLoaded?: boolean
}

export const LazyImage = memo((props: LazyImageProps) => {
  const { isLoaded: isAlreadyLoaded, ...rest } = props

  const [isLoaded, setIsLoaded] = React.useState(isAlreadyLoaded)

  return (
    <motion.img
      {...rest}
      initial={{ opacity: isAlreadyLoaded ? 1 : 0 }}
      animate={{ opacity: isLoaded ? 1 : 0 }}
      transition={{
        duration: 0.2,
      }}
      onLoad={(e) => {
        setIsLoaded(true)
        rest.onLoad?.(e)
      }}
    />
  )
})

LazyImage.displayName = 'LazyImage'
