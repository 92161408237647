import { AnnotationMarker } from '@training/apis/types'
import { VideoAnnotation } from '@training/hooks/useVideoAnnotations'
import { isNil } from 'lodash'

export const toAnnotationConfigList = (markers: AnnotationMarker[]): VideoAnnotation[] => {
  return markers?.flatMap((marker) => {
    return marker.objectLocations.map((item) => {
      const points = item.geometry?.coordinates ?? []
      const [xmin, xmax, ymin, ymax] = points.reduce(
        ([minX, maxX, minY, maxY], [x, y]) => [
          Math.min(minX, x),
          Math.max(maxX, x),
          Math.min(minY, y),
          Math.max(maxY, y),
        ],
        [
          Number.POSITIVE_INFINITY,
          Number.NEGATIVE_INFINITY,
          Number.POSITIVE_INFINITY,
          Number.NEGATIVE_INFINITY,
        ]
      )

      const locId = item.locId ?? item.id

      // for the "Object Not Present" case
      const emptyGeometry = isNil(item.geometry)
        ? {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
          }
        : {}

      return {
        x: xmin,
        y: ymin,
        width: xmax - xmin,
        height: ymax - ymin,
        ...emptyGeometry,
        frameStart: marker.videoTimelineMarker,
        frameEnd: marker.videoTimelineMarker,
        timelineInSeconds: marker.timelineInSeconds ?? null,
        type: 'annotation',
        id: locId as string,
        annotationId: locId,
      } satisfies VideoAnnotation
    })
  })
}
