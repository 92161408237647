import { ElementRef, memo, useCallback, useEffect, useRef } from 'react'
import videojs from 'video.js'

import type VideoJs from 'video.js/index'
import 'video.js/dist/video-js.css'
import useResizeObserver from '@training/hooks/useResizeObserver'
import { delay, round } from 'lodash'
import {
  type VideoType,
  useVideoPlayer as useVideoPlayerFactory,
  useVideoPlayerInstance,
} from '@training/hooks/useVideoPlayer'

interface VideoPlayerProps {
  options: VideoJs.PlayerOptions
  className?: string
  onSizeChange?: (width: number, height: number) => void
  frameRate: number
  videoType: VideoType
}

// This approach should help in most cases where the calculated frame is very close to the next frame but it's still a bit of an approximation
// This workaround is because the video player shows the next frame when the playerTime is close to the next frame, not exactly when the playerTime is the next frame
// The currentTime is not always exactly representative of what's on the screen. Sometimes it's ahead a little bit, especially when video is paused while playing
// https://stackoverflow.com/a/24829337
function calculateFrame(playerTime: number, frameRate: number) {
  const frameNumber = Number(playerTime.toFixed(5)) * frameRate
  const fractionalPart = round(frameNumber % 1, 1)

  return {
    rounded: fractionalPart < 0.7 ? Math.floor(frameNumber) : Math.ceil(frameNumber),
    exact: frameNumber,
  }
}

type VideoSyncFrameListenerProps = {
  videoType: VideoType
  frameRate: number
}

const VideoSyncFrameListener = (props: VideoSyncFrameListenerProps) => {
  const { videoType, frameRate } = props

  const useVideoPlayer = useVideoPlayerFactory(videoType)

  const currentTime = useVideoPlayer((state) => state.currentTime)
  const isPlaying = useVideoPlayer((state) => state.isPlaying)
  const setCurrentFrame = useVideoPlayer((state) => state.setCurrentFrame)
  const setCurrentTime = useVideoPlayer((state) => state.setCurrentTime)

  const instanceRef = useVideoPlayerInstance(videoType)((state) => state.instanceRef)
  const getCurrentTime = useVideoPlayerInstance(videoType)((state) => state.getCurrentTime)

  // onVideoFrame does not get called when the video is paused sometimes so we update on pause
  useEffect(() => {
    if (!isPlaying) {
      const playerTime = getCurrentTime()

      if (playerTime !== currentTime) {
        setCurrentTime(playerTime)
        setCurrentFrame(calculateFrame(playerTime, frameRate))
      }
    }
  }, [
    isPlaying,
    frameRate,
    currentTime,
    instanceRef,
    setCurrentTime,
    setCurrentFrame,
    getCurrentTime,
  ])

  return null
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const videoElemRef = useRef<ElementRef<'video'> | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const { options, className, onSizeChange, frameRate, videoType } = props

  const useVideoPlayer = useVideoPlayerFactory(videoType)

  const setReady = useVideoPlayer((state) => state.setReady)
  const setMetadataReady = useVideoPlayer((state) => state.setMetadataReady)
  const setPlaying = useVideoPlayer((state) => state.setPlaying)
  const setCurrentFrame = useVideoPlayer((state) => state.setCurrentFrame)
  const setCurrentTime = useVideoPlayer((state) => state.setCurrentTime)
  const setSeeking = useVideoPlayer((state) => state.setSeeking)

  const instanceRef = useVideoPlayerInstance(videoType)((state) => state.instanceRef)
  const getCurrentTime = useVideoPlayerInstance(videoType)((state) => state.getCurrentTime)

  const widthRef = useRef(0)
  const heightRef = useRef(0)

  const onResize = useCallback(
    (target: HTMLElement) => {
      const width = target.clientWidth
      const height = target.clientHeight

      if (width !== widthRef.current || height !== heightRef.current) {
        widthRef.current = width
        heightRef.current = height
        if (onSizeChange) {
          onSizeChange(width, height)
        }
      }
    },
    [onSizeChange]
  )

  const resizeRef = useResizeObserver(onResize)

  const onReady = useCallback(() => {
    const player = instanceRef.current

    if (!player) {
      return
    }

    // eslint-disable-next-line no-underscore-dangle
    videoElemRef.current = player.el_.querySelector('video')
    videoElemRef.current?.setAttribute('crossOrigin', 'anonymous')

    player.src(options.sources)

    let currentFrame = 0

    // executes every frame
    const onVideoFrame: VideoFrameRequestCallback = () => {
      const playerTime = getCurrentTime()
      const newFrame = calculateFrame(playerTime, frameRate)

      if (newFrame.rounded !== currentFrame) {
        currentFrame = newFrame.rounded

        setCurrentFrame(newFrame)
        setCurrentTime(playerTime)
      }

      videoElemRef.current?.requestVideoFrameCallback?.(onVideoFrame)
    }

    videoElemRef.current?.requestVideoFrameCallback?.(onVideoFrame)

    player.on('pause', () => {
      setPlaying(false)
    })
    player.on('play', () => {
      setPlaying(true)
    })
    player.on('loadeddata', () => {
      setReady(true)
    })
    player.on('loadedmetadata', () => {
      setMetadataReady(true)
    })
    player.on('seeking', () => {
      setSeeking(true)
    })
    player.on('seeked', () => {
      delay(() => {
        setSeeking(false)
      }, 100)
    })
  }, [
    options.sources,
    instanceRef,
    setSeeking,
    setCurrentTime,
    frameRate,
    setPlaying,
    setCurrentFrame,
    setReady,
    getCurrentTime,
    setMetadataReady,
  ])

  useEffect(() => {
    if (!instanceRef.current) {
      const videoElement = document.createElement('video-js')
      videoElement.style.overflow = 'hidden'
      videoElement.classList.add('vjs-fluid')

      containerRef.current?.appendChild(videoElement)

      instanceRef.current = videojs(videoElement, options, () => {
        onReady()
      })

      // @ts-ignore
      instanceRef.current.toJSON = () => 'VideoJsPlayer'
    } else {
      const player = instanceRef.current
      player.autoplay(options.autoplay)
      player.src(options.sources)
    }
  }, [onReady, options, instanceRef])

  useEffect(() => {
    const player = instanceRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.currentTime(0)
        player.dispose()
        instanceRef.current = null
        setReady(false)
        setMetadataReady(false)
        setCurrentTime(0)
        setCurrentFrame({
          rounded: 0,
          exact: 0,
        })
      }
    }
  }, [instanceRef, setCurrentFrame, setCurrentTime, setReady, options.sources, setMetadataReady])

  return (
    <div data-vjs-player className={className}>
      <div
        data-testid='video-player-container'
        ref={(_ref) => {
          containerRef.current = _ref
          resizeRef.current = _ref
        }}
      />
      <VideoSyncFrameListener frameRate={frameRate} videoType={videoType} />
    </div>
  )
}

const VideoPlayerMemo = memo(VideoPlayer)

export default VideoPlayerMemo
