import { Skeleton, TextCarousel, Typography } from '@htaic/cue'
import { noop } from 'lodash'
import { twMerge } from 'tailwind-merge'

interface ClipVideoCardProps extends React.ComponentProps<'button'> {
  isDisabled?: boolean
  duration: string
  name: string
  thumbnailUrl: string
  isSelected?: boolean
}

export const ClipVideoCardSkeleton = () => {
  return (
    <div className='flex gap-2 rounded-xl border border-solid border-neutral-grey-14 p-4 dark:border-neutral-grey-12'>
      <Skeleton className='h-[60px] w-26' />
      <div className='flex grow flex-col justify-center gap-2'>
        <Skeleton className='h-4 w-full' />
        <Skeleton className='h-4 w-8' />
      </div>
    </div>
  )
}

export const ClipVideoCard = ({
  isDisabled,
  duration,
  name,
  thumbnailUrl,
  onMouseEnter,
  isSelected,
  ...rest
}: ClipVideoCardProps) => {
  return (
    <button
      type='button'
      className={twMerge(
        'h-[92px] bg-transparent flex items-center border border-solid border-border-default rounded-xl p-4 gap-3 text-left transition-colors',
        'dark:bg-neutral-grey-13 dark:border-neutral-grey-11',
        isDisabled
          ? 'opacity-50 bg-neutral-grey-15'
          : 'cursor-pointer dark:hover:border-primary-500',
        isSelected && !isDisabled
          ? 'bg-primary-1000 border-primary-500 dark:bg-neutral-grey-11'
          : 'hover:bg-primary-1000 hover:border-primary-500  hover:dark:bg-neutral-grey-12'
      )}
      data-testid='video-card-wavesync'
      {...rest}
      onMouseEnter={isDisabled ? onMouseEnter : noop}
    >
      <img
        width={104}
        height={60}
        src={thumbnailUrl}
        alt='thumbnail'
        className='object-cover shrink-0'
      />
      <div className='flex flex-col overflow-hidden'>
        <TextCarousel
          variant='subhead1'
          className='max-w-full truncate whitespace-nowrap group-hover:overflow-visible group-hover:animate-marquee mb-1.5'
          title={name}
        >
          {name}
        </TextCarousel>
        <Typography variant='body2' className='block'>
          {duration}
        </Typography>
      </div>
    </button>
  )
}
