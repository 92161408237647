import { Button, Typography } from '@htaic/cue'
import { useGetThumbnailsAndAnnotation } from '@training/apis/training-assets/requests'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import { usePopper } from '@training/hooks/usePopper'
import { useTestingHelper } from '@training/hooks/useTestingHelper'
import { useCallback } from 'react'

interface InitialModeActionsProps {
  hasAnnotations: boolean
  isDisabled: boolean
  onTrain: React.MouseEventHandler<HTMLButtonElement>
  projectId: string
}

const MIN_FRAMES_WITH_ANNOTATIONS = 5

export const InitialModeActions = (props: InitialModeActionsProps) => {
  const { setPopper } = usePopper()
  const { getTestIdProps } = useTestingHelper('initial-mode-actions')

  const { hasAnnotations, isDisabled, onTrain, projectId } = props

  const { data: projectThumbnails } = useGetThumbnailsAndAnnotation(
    {
      projectId,
    },
    !!projectId
  )

  const framesWithAnnotations = projectThumbnails?.length ?? 0

  const enableFiveAnnotationsTrain = useFeatureFlags().FF_ENABLE_TRAINING_ON_FIVE_ANNOTATIONS

  const tooltipText = enableFiveAnnotationsTrain
    ? 'Please add detection boxes on 5 different frames to train.'
    : 'Please draw new detection boxes to train.'

  const isTrainDisabled =
    !hasAnnotations || isDisabled || framesWithAnnotations < MIN_FRAMES_WITH_ANNOTATIONS

  const onTrainMouseEnter = useCallback<NonNullable<React.ComponentProps<'div'>['onMouseEnter']>>(
    (e) => {
      e.stopPropagation()
      if (isTrainDisabled) {
        setPopper({
          id: 'info-popover',
          anchorEl: e.currentTarget,
          placement: 'left',
          content: (
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              {tooltipText}
            </Typography>
          ),
          className: 'w-56',
          closable: false,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTrainDisabled]
  )

  return (
    <div
      onMouseEnter={onTrainMouseEnter}
      onMouseLeave={() => setPopper(null)}
      role='button'
      tabIndex={0}
    >
      <Button
        color='primary'
        variant='contained'
        {...getTestIdProps('train-button')}
        aria-label='train'
        size='small'
        className='w-[169px] h-8'
        disabled={isTrainDisabled}
        onClick={onTrain}
      >
        Train
      </Button>
    </div>
  )
}
