/* eslint-disable no-param-reassign */
import { useEffect } from 'react'
import axios, { AxiosRequestHeaders } from 'axios'
import { getRedirectUrl } from '@training/utils/getRedirectUrl'

const axiosRedirectErrorCodes: number[] = [401, 302]

/**
 * We are currently using axios interceptors to handle 401, 302 responses.
 * In the future we will use the redirect url from response headers.
 */
export const AxiosConfig = ({ orgId }: { orgId: string }) => {
  const cloudPlatformLoginUrl = getRedirectUrl()

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (!config.headers) {
          config.headers = {} as AxiosRequestHeaders
        }

        config.headers['Content-Type'] = config.headers['Content-Type'] ?? 'application/json'
        config.headers['X-Flexai-Orgid'] = orgId
        // When we run the cors-anywhere application locally we need to set the
        // x-requested-with header. It can be any value.
        config.headers['x-requested-with'] = '123'

        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axiosRedirectErrorCodes.includes(error.response.status)) {
          window.location.href = `${cloudPlatformLoginUrl}?redirect_app_url=${window.location.protocol}//${window.location.hostname}/?orgId=${orgId}`
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
      axios.interceptors.response.eject(responseInterceptor)
    }
  })

  return null
}
