import { LazyImage } from '@training/components/LazyImage'
import { secondsToFormattedTime } from '@training/utils/videoTimeConverter'
import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface TimelineThumbnailsProps {
  thumbnails: Array<{ timeSeconds: number; url: string; isPlaceholder?: boolean }>
  onThumbnailClick: (time: number) => void
  currentHoverTimeStamp?: number
}

export const TimelineThumbnails = (props: TimelineThumbnailsProps) => {
  const { thumbnails, onThumbnailClick, currentHoverTimeStamp } = props

  const loadedImagesRef = useRef<Set<string>>(new Set())

  return thumbnails.map((thumbnail, index) => (
    <div
      role='button'
      tabIndex={0}
      onClick={(event) => {
        event.stopPropagation()
        onThumbnailClick(thumbnail.timeSeconds)
      }}
      onKeyDown={(event) => {
        event.stopPropagation()
        onThumbnailClick(thumbnail.timeSeconds)
      }}
      key={index + thumbnail.timeSeconds}
      data-testid={`thumbnail-${thumbnail.timeSeconds}`}
    >
      <LazyImage
        alt={`Thumbnail at ${secondsToFormattedTime(thumbnail.timeSeconds)}`}
        title={secondsToFormattedTime(thumbnail.timeSeconds)}
        className={twMerge(
          `h-28 object-cover inline-flex  w-52 cursor-pointer`,
          currentHoverTimeStamp === thumbnail.timeSeconds
            ? 'border-solid border-4 border-orange-500'
            : '',
          thumbnail.isPlaceholder ? 'invisible' : ''
        )}
        onLoad={() => {
          loadedImagesRef.current.add(thumbnail.url)
        }}
        isLoaded={loadedImagesRef.current.has(thumbnail.url)}
        src={thumbnail.url ? `${thumbnail.url}` : undefined}
      />
    </div>
  ))
}
