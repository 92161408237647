import { useVideoAnnotations, type VideoAnnotation } from '@training/hooks/useVideoAnnotations'
import { includes, isNull } from 'lodash'
import { annotationStatusColor, annotationType, projectViewModes } from '@training/constants'
import { useAppState } from '@training/hooks/useAppState'
import { useVideoPlayer } from '@training/hooks/useVideoPlayer'
import { useCallback, useMemo } from 'react'
import { Annotation, RectWithId } from './Annotation'
import { useAnnotationFrameFilter } from './useAnnotationFrameFilter'

interface AnnotationsListProps {
  annotations: VideoAnnotation[]
  selectedAnnotationId: string | null
  confidenceLevelValue: number | null
  suggestedFramesNumbers: number[]
  onSelectedAnnotation: (annotationId: string) => void
  isComparisonMode: boolean
}

const uneditableAnnotationTypes = [annotationType.INCORRECT, annotationType.INFERENCE] as const

export const AnnotationsList = (props: AnnotationsListProps) => {
  const {
    annotations,
    selectedAnnotationId,
    confidenceLevelValue,
    suggestedFramesNumbers,
    onSelectedAnnotation,
    isComparisonMode,
  } = props

  const projectViewMode = useAppState((state) => state.projectViewMode)

  const currentFrame = useVideoPlayer()((state) => state.currentFrame)

  const { updateAnnotation, setAnnotationsToTighten } = useVideoAnnotations()((state) => ({
    setAnnotationsToTighten: state.setAnnotationsToTighten,
    updateAnnotation: state.updateAnnotation,
  }))

  const onAnnotationChange = useCallback(
    (newAttrs: RectWithId) => {
      updateAnnotation(newAttrs as VideoAnnotation)
      setAnnotationsToTighten([newAttrs] as VideoAnnotation[])
    },
    [updateAnnotation, setAnnotationsToTighten]
  )

  const filterAnnotationsInFrame = useAnnotationFrameFilter({ liveUpdate: true })

  const annotationsToDrawInFrame = useMemo(() => {
    return filterAnnotationsInFrame({
      annotations,
      confidenceLevelValue,
      projectViewMode,
      isComparisonMode,
    })
  }, [
    filterAnnotationsInFrame,
    annotations,
    confidenceLevelValue,
    projectViewMode,
    isComparisonMode,
  ])

  return annotationsToDrawInFrame.map((annotation) => (
    <Annotation
      key={String(annotation.id)}
      shapeProps={annotation}
      isSelected={annotation.id === selectedAnnotationId}
      showConfidenceLevel={!isNull(confidenceLevelValue)}
      disabled={
        includes(uneditableAnnotationTypes, annotation.type) ||
        // disable annotations made in the IMPROVE mode when in the ADD_MORE mode
        !!(
          projectViewMode === projectViewModes.ADD_MORE &&
          annotation.annotationId &&
          suggestedFramesNumbers.includes(currentFrame.rounded)
        )
      }
      confidence={annotation.type === 'inference' ? annotation.confidence : undefined}
      stroke={annotationStatusColor[annotation.type ?? 'annotation']}
      onSelect={onSelectedAnnotation}
      onChange={onAnnotationChange}
    />
  ))
}
