/**
 * Calculates the remaining characters allowed based on the given text length and limit.
 *
 * @param {number} textLength - The length of the text.
 * @param {number} limit - The character limit.
 * @returns {number} The remaining characters allowed within the limit.
 */
const charsLeft = (textLength: number, limit: number) => {
  if (textLength < limit) {
    return limit - textLength
  }
  return 0
}

export default charsLeft
