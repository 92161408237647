import { MainLayout } from '@training/layout/MainLayout'
import License from '@training/pages/licenses/License'
import NewProject from '@training/pages/NewProject'
import Projects from '@training/pages/Projects'
import { AppProvider } from '@training/providers/AppProvider'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { queryClient } from '@training/apis/query-client'
import { licenseLoader } from './loaders'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AppProvider>
        <MainLayout />
      </AppProvider>
    ),
    children: [
      {
        path: '/',
        index: true,
        element: <Navigate to='training' replace />,
      },
      {
        path: '/license',
        element: <License />,
      },
      {
        path: '/training/*',
        loader: licenseLoader(queryClient),
        async lazy() {
          const { SubscriptionRouteProvider } = await import(
            '@training/providers/SubscriptionRouteProvider'
          )
          return {
            Component: SubscriptionRouteProvider,
          }
        },
        children: [
          {
            path: '',
            element: <Projects />,
          },
          {
            path: 'project/:id',
            element: <NewProject />,
          },
        ],
      },
    ],
  },
])
