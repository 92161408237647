import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Button,
  Checkbox,
  Typography,
  Icon,
  ValidationError,
  SelectBoxItemProps,
  useSnackbar,
  usePagination,
  ScrollBar,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
  Skeleton,
} from '@htaic/cue'
import { Tab, Tabs } from '@mui/material'
import type {
  BookmarkPayload,
  WaveSyncUploadVideoResponse,
  WaveSyncVideo,
} from '@training/apis/types'
import { errorMessages, VMS_ITEMS_PER_PAGE } from '@training/constants'
import { EventFor, WaveSyncModalStep } from '@training/types'
import React, { BaseSyntheticEvent, useCallback, useMemo, useState } from 'react'
import { formatMillisecondsToMinutes } from '@training/utils/videoTimeConverter'
import { map, noop, partition } from 'lodash'
import { usePopper } from '@training/hooks/usePopper'
import {
  useGetBookmarks,
  useGetSystemIds,
  useMedia,
  useWaveSyncAuthenticate,
  useWaveSyncLogout,
} from '@training/apis/waveSync/requests'
import { queryClient } from '@training/apis/query-client'
import { useParams } from 'react-router-dom'
import { useMinimeState } from '@training/hooks/useMinimeState'

import { WaveSyncLoginForm } from './WaveSyncLoginForm'
import { useWaveSyncSession } from './useWaveSyncSession'
import { ClipVideoCard } from './ClipVideoCard'
import { OnCloudTab } from './OnCloudTab'
import { ClipsPagination } from './ClipsPagination'

const WaveSyncModal = ({
  isOpen,
  onClose,
  isTrained,
  onVerifyVideo,
  onVideoUploaded,
}: {
  isOpen: boolean
  onClose: () => void
  isTrained: boolean
  onVerifyVideo: (videoFileUUID: string) => Promise<void>
  onVideoUploaded: () => void
}) => {
  const [modalStep, setModalStep] = useState<WaveSyncModalStep | undefined>(
    WaveSyncModalStep.SYSTEMSELECTION
  )

  const { isLoggedIn, logout, switchUser, username: waveSyncUsername } = useWaveSyncSession()

  // systems
  const getSystems = useGetSystemIds({ enabled: isOpen })

  const availableSystems = useMemo(
    () =>
      getSystems.data?.systems.map((system) => ({
        id: system.systemId,
        value: system.systemName,
        label: system.systemName,
      })) ?? [],
    [getSystems.data]
  )

  const [selectedSystem, setSelectedSystem] = useState<SelectBoxItemProps>()

  const onSystemSelection = useCallback(
    (system: string, goToVideoSelection?: boolean) => {
      setSelectedSystem(availableSystems.find((option) => option.value === system))
      if (goToVideoSelection) {
        setModalStep(WaveSyncModalStep.VIDEOSELECTION)
      }
    },
    [availableSystems]
  )

  // auth
  const waveSyncAuthenticate = useWaveSyncAuthenticate()

  const onAuthenticate = async (username: string, password: string) => {
    try {
      const systems = await waveSyncAuthenticate.mutateAsync({
        username,
        password,
      })

      queryClient.setQueryData(['getSystemIds'], systems)

      switchUser(systems.email)
      setModalStep(WaveSyncModalStep.SYSTEMSELECTION)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('WaveSync login failed: ', error)
      setModalStep(undefined)
      logout()
    }
  }

  const waveSyncLogout = useWaveSyncLogout()

  const onLogout = useCallback(async () => {
    await waveSyncLogout.mutateAsync()

    logout()
    setModalStep(undefined)
  }, [waveSyncLogout, logout])

  // videos - upload
  const waveSyncMedia = useMedia()
  const isUploadingVideos = waveSyncMedia.isPending

  const videoBookmarks = useGetBookmarks(selectedSystem?.id ?? '')

  const { id: projectId } = useParams()
  const organizationId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const showSnackBar = useSnackbar()

  const onVideoSelection = useCallback(
    async (videos: WaveSyncVideo[]) => {
      const payload = {
        project_id: projectId as string,
        org_id: organizationId,
        user_id: userId,
        media_bookmark_list: videos.map((v) => {
          return {
            name: v.name,
            quality: 'high',
            device_id: v.deviceId.replace('{', '').replace('}', ''),
            system_id: selectedSystem?.id ?? '',
            pos: v.startTimeMs,
            duration: v.durationMs,
            thumbnail_url: v.thumbnailUrl,
          }
        }),
      } satisfies BookmarkPayload

      async function verifyVideos(mediaUploaded: WaveSyncUploadVideoResponse[]) {
        await Promise.all(
          mediaUploaded.map(async (item) => {
            const videoId = item.videoUploadResponse.id
            if (videoId) {
              await onVerifyVideo(`${videoId}.mp4`)
            }
          })
        )
      }

      try {
        const response = await waveSyncMedia.mutateAsync(payload)
        const [mediaWithErrors, mediaUploaded] = partition(response, (r) => r.status !== 200)

        if (mediaWithErrors.length > 0) {
          showSnackBar({
            message: `Error uploading ${mediaWithErrors.length} ${
              mediaWithErrors.length > 1 ? 'videos' : 'video'
            }`,
            status: 'error',
          })
          return
        }
        if (isTrained && mediaUploaded?.length > 0) {
          verifyVideos(mediaUploaded)
        }
        showSnackBar({
          message: `${mediaUploaded?.length} ${
            mediaUploaded?.length > 1 ? 'videos were' : 'video was'
          } uploaded successfully`,
          status: 'success',
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('WaveSync upload failed: ', e)
        showSnackBar({
          message: 'Error uploading videos',
          status: 'error',
        })
      } finally {
        onVideoUploaded()
        onClose()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isTrained,
      onClose,
      onVerifyVideo,
      onVideoUploaded,
      organizationId,
      projectId,
      selectedSystem?.id,
      userId,
    ]
  )

  const errorMessage = useMemo(() => {
    if (!getSystems.isPending && availableSystems.length === 0 && isLoggedIn) {
      return errorMessages.WAVESYNC_NO_SYSTEMS
    }
    if (waveSyncAuthenticate.isError) {
      return errorMessages.WAVESYNC_LOGIN_FAILED
    }
    if (!videoBookmarks.isPending && Number(videoBookmarks.data?.length ?? 0) === 0 && isLoggedIn) {
      return errorMessages.WAVESYNC_NO_VIDEOS
    }
    if (waveSyncAuthenticate.isError && waveSyncLogout.error?.message) {
      return waveSyncLogout.error.message
    }

    return ''
  }, [
    availableSystems.length,
    getSystems.isPending,
    isLoggedIn,
    videoBookmarks.data?.length,
    videoBookmarks.isPending,
    waveSyncAuthenticate.isError,
    waveSyncLogout.error?.message,
  ])

  const { setPopper } = usePopper()

  const [credentials, setCredentials] = useState<{ username: string; password: string }>({
    username: '',
    password: '',
  })

  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([])

  const onCredentialsChange = useCallback<
    EventFor<typeof WaveSyncLoginForm, 'onCredentialsChange'>
  >(
    (newCredentials) => {
      setCredentials(newCredentials)
    },
    [setCredentials]
  )

  const [currentTab, setCurrentTab] = useState('0')

  const availableVideoFiles = map(videoBookmarks.data, (videoFile) => {
    const minutes = formatMillisecondsToMinutes(videoFile.durationMs)

    return {
      ...videoFile,
      isDisabled: Number(minutes.substring(0, 2)) > 10,
    }
  })

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue)
  }

  const handleSelectAllCheckboxChange = useCallback(
    (e: BaseSyntheticEvent) => {
      if (e.target.checked) {
        const filteredVideos = availableVideoFiles.filter((videoFile) => !videoFile.isDisabled)
        setSelectedVideoIds(filteredVideos.map((filteredVideo) => filteredVideo.id))
      } else {
        setSelectedVideoIds([])
      }
    },
    [availableVideoFiles]
  )

  const isIndeterminate = useMemo(() => {
    const filteredVideos = availableVideoFiles.filter((videoFile) => !videoFile.isDisabled)

    return selectedVideoIds.length < filteredVideos.length && selectedVideoIds.length > 0
  }, [selectedVideoIds.length, availableVideoFiles])

  const isAllSelected = useMemo(() => {
    const filteredVideos = availableVideoFiles.filter((videoFile) => !videoFile.isDisabled)

    return selectedVideoIds.length === filteredVideos.length
  }, [selectedVideoIds.length, availableVideoFiles])

  const handleCloseModal = useCallback(() => {
    setCredentials({ username: '', password: '' })
    setSelectedVideoIds([])
    onClose()
  }, [onClose])

  const handleMouseEnter = useCallback<NonNullable<React.ComponentProps<'button'>['onMouseEnter']>>(
    (e) => {
      setPopper({
        id: 'info-popover-exceeds-ten-minutes',
        anchorEl: e.currentTarget,
        placement: 'auto',
        content: (
          <div className='flex flex-col'>
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              Only clips 10 minutes or shorter can be imported.
            </Typography>
          </div>
        ),
        closable: false,
        className: 'w-[150px]',
      })
    },
    [setPopper]
  )

  const handleMouseLeave = useCallback(() => {
    setPopper(null)
  }, [setPopper])

  const pagesNumber = useMemo(
    () => Math.ceil((videoBookmarks.data?.length ?? 0) / VMS_ITEMS_PER_PAGE),
    [videoBookmarks.data?.length]
  )

  const pages = Array.from({ length: pagesNumber }, (_, i) => i + 1)
  const [currentPage, setCurrentPage] = useState(1)
  const pageNumbers = usePagination({
    currentPage,
    siblingsCount: 1,
    totalPageCount: pagesNumber,
  })

  const filteredVids = availableVideoFiles?.slice(
    VMS_ITEMS_PER_PAGE * (currentPage - 1),
    VMS_ITEMS_PER_PAGE * currentPage
  )

  const clickVideo = useCallback(
    (videoFile: WaveSyncVideo) => {
      if (selectedVideoIds.includes(videoFile.id)) {
        setSelectedVideoIds(selectedVideoIds.filter((vid) => vid !== videoFile.id))
      } else {
        setSelectedVideoIds([...selectedVideoIds, videoFile.id])
      }
    },
    [selectedVideoIds]
  )

  return (
    <Modal
      open={isOpen}
      onClose={isUploadingVideos ? undefined : handleCloseModal}
      className='z-50 pointer-events-auto'
      size='xl'
    >
      <ModalContent>
        <ModalHeader closable>Add video from VMS</ModalHeader>
        <div>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label='Add video from VMS'
            className='border-0 border-b border-solid border-border-default'
            classes={{ scroller: '[&&]:mb-0 box-border' }}
            textColor='inherit'
          >
            <Tab
              className='normal-case px-0 w-fit text-left min-w-0 mr-12 font-semibold dark:text-semantic-primary text-base'
              label='OnCloud'
              value='0'
            />
            <Tab
              className='normal-case px-0 w-fit min-w-0 font-semibold  text-base dark:text-semantic-primary'
              label='WAVE Sync'
              value='1'
              data-testid='tab-wavesync'
            />
          </Tabs>
          {currentTab === '0' ? (
            <OnCloudTab
              onVideoUploaded={onVideoUploaded}
              onClose={handleCloseModal}
              projectId={projectId as string}
              onVerifyVideo={onVerifyVideo}
              isTrained={isTrained}
            />
          ) : (
            <>
              {!isLoggedIn ? (
                <div className='flex flex-col items-center'>
                  <WaveSyncLoginForm onCredentialsChange={onCredentialsChange} />
                </div>
              ) : (
                <div className='pt-4 pb-6 px-5'>
                  {waveSyncUsername ? (
                    <div>
                      <Typography variant='body1' className='text-xs mb-2 text-semantic-secondary'>
                        Logged into WaveSync as {waveSyncUsername}
                      </Typography>
                      <Typography variant='body1' className='text-semantic-primary'>
                        Import video clips shorter than 10 minutes.
                      </Typography>
                    </div>
                  ) : null}
                  <div className='flex justify-center py-6 mt-0.5'>
                    {!getSystems.isPending && availableSystems.length > 0 ? (
                      <Select
                        onValueChange={(value) => {
                          setSelectedVideoIds([])
                          onSystemSelection(value, modalStep === WaveSyncModalStep.VIDEOSELECTION)
                        }}
                        value={String(selectedSystem?.value ?? '')}
                        disabled={isUploadingVideos}
                      >
                        <SelectTrigger
                          disabled={isUploadingVideos}
                          className='w-80'
                          data-testid='dropdown-systems'
                          aria-label='Select system'
                        >
                          <SelectValue placeholder='Select system' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {getSystems.isPending ? (
                              <div className='mx-3.5 py-2 flex flex-col gap-4 w-3/4'>
                                <Skeleton className='h-4' />
                                <Skeleton className='h-4' />
                                <Skeleton className='h-4' />
                              </div>
                            ) : (
                              availableSystems.map((item) => (
                                <SelectItem key={item.id} value={item.value}>
                                  {item.label}
                                </SelectItem>
                              ))
                            )}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    ) : null}
                  </div>
                  {videoBookmarks.isPending && modalStep === WaveSyncModalStep.VIDEOSELECTION ? (
                    <div className='flex justify-center'>
                      <div className='flex flex-col justify-center'>
                        <Typography variant='body2' className='text-semantic-primary'>
                          Loading videos...
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                  {modalStep === WaveSyncModalStep.VIDEOSELECTION &&
                  videoBookmarks.data &&
                  videoBookmarks.data.length > 0 &&
                  !videoBookmarks.isPending ? (
                    <div className='flex flex-col pb-2'>
                      <ScrollBar className='grid grid-cols-2 gap-3 gap-x-6 mb-7 sm:max-h-[37svh] sm:pr-2'>
                        {filteredVids?.map((videoFile) => {
                          const formattedTime = formatMillisecondsToMinutes(videoFile.durationMs)

                          return (
                            <ClipVideoCard
                              key={videoFile.id}
                              duration={formattedTime}
                              name={videoFile.name}
                              thumbnailUrl={videoFile.thumbnailUrl}
                              isDisabled={videoFile.isDisabled}
                              isSelected={selectedVideoIds.includes(videoFile.id)}
                              onMouseEnter={videoFile.isDisabled ? handleMouseEnter : noop}
                              onMouseLeave={handleMouseLeave}
                              onClick={videoFile.isDisabled ? noop : () => clickVideo(videoFile)}
                            />
                          )
                        })}
                      </ScrollBar>
                      <div className='grid grid-cols-3'>
                        <div className='text-nowrap'>
                          <Checkbox
                            label='Select all'
                            disabled={isUploadingVideos}
                            checked={isAllSelected}
                            indeterminate={isIndeterminate}
                            onChange={handleSelectAllCheckboxChange}
                          />
                        </div>
                        <ClipsPagination
                          onPreviousPageClick={() =>
                            setCurrentPage((prev) => (prev - 1 === 0 ? prev : prev - 1))
                          }
                          onNextPageClick={() =>
                            setCurrentPage((prev) => (prev === pages.length ? prev : prev + 1))
                          }
                          currentPage={currentPage}
                          pageNumbers={pageNumbers}
                          onPageChange={setCurrentPage}
                        />
                        <div />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              <ValidationError
                data-testid='error-message-wavesync'
                className='mx-auto w-[400px] mt-3'
                message={errorMessage}
              />
              <ModalFooter className='justify-end'>
                {currentTab === '1' && isLoggedIn && !isUploadingVideos && (
                  <Button
                    color='secondary'
                    variant='outlined'
                    onClick={() => {
                      setCredentials({ username: '', password: '' })
                      onLogout()
                    }}
                    className='flex mr-auto'
                  >
                    Logout of WaveSync
                  </Button>
                )}
                <div className='flex gap-2'>
                  {!isUploadingVideos && (
                    <Button color='neutral' variant='contained' onClick={handleCloseModal}>
                      Cancel
                    </Button>
                  )}
                  {!isLoggedIn && (
                    <Button
                      color='primary'
                      variant='outlined'
                      data-testid='button-authenticate-wavesync'
                      disabled={
                        !credentials.username ||
                        !credentials.password ||
                        waveSyncAuthenticate.isPending
                      }
                      onClick={() => onAuthenticate(credentials.username, credentials.password)}
                    >
                      Authenticate
                    </Button>
                  )}
                  {modalStep === WaveSyncModalStep.SYSTEMSELECTION &&
                  !waveSyncAuthenticate.isPending &&
                  isLoggedIn ? (
                    <Button
                      data-testid='button-selectSystem'
                      color='primary'
                      variant='outlined'
                      disabled={!selectedSystem || getSystems.isPending}
                      onClick={() => {
                        if (selectedSystem) {
                          setSelectedVideoIds([])
                          if (selectedSystem.label) {
                            onSystemSelection(selectedSystem.label, true)
                          }
                        }
                      }}
                    >
                      Select
                    </Button>
                  ) : null}
                  {modalStep === WaveSyncModalStep.VIDEOSELECTION && !isUploadingVideos ? (
                    <Button
                      data-testid='button-selectVideos'
                      color='primary'
                      variant='outlined'
                      disabled={
                        videoBookmarks.data?.length === 0 ||
                        selectedVideoIds.length === 0 ||
                        isUploadingVideos
                      }
                      onClick={() => {
                        onVideoSelection(
                          availableVideoFiles.filter((availableVideo) =>
                            selectedVideoIds.includes(availableVideo.id)
                          )
                        )
                      }}
                    >
                      Select
                    </Button>
                  ) : null}
                </div>
                {modalStep === WaveSyncModalStep.VIDEOSELECTION && isUploadingVideos ? (
                  <div className='pt-3 text-sm font-normal w-full flex justify-center gap-2 items-center text-semantic-primary'>
                    <Icon className='animate-spin text-icon-default' name='Loading' />
                    Adding WaveSync video {selectedVideoIds.length > 1 ? 'clips' : 'clip'}...
                  </div>
                ) : null}
              </ModalFooter>
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

export default WaveSyncModal
