import { type ProjectViewMode, isImproveViewMode } from '@training/constants'
import type { VideoAnnotation } from '@training/hooks/useVideoAnnotations'
import { useVideoPlayer } from '@training/hooks/useVideoPlayer'
import { isNull, orderBy } from 'lodash'
import { useCallback } from 'react'

interface AnnotationFrameFilterOptions {
  liveUpdate?: boolean
}

interface AnnotationFilteringOptions {
  annotations: VideoAnnotation[]
  confidenceLevelValue: number | null
  projectViewMode: ProjectViewMode
  isComparisonMode: boolean
}

export const useAnnotationFrameFilter = (options: AnnotationFrameFilterOptions) => {
  const { liveUpdate = true } = options

  const frameNumber = useVideoPlayer()((state) => (liveUpdate ? state.currentFrame.rounded : -1))
  const isReady = useVideoPlayer()((state) => state.isReady)
  const getCurrentFrame = useVideoPlayer()((state) => state.getCurrentFrame)

  return useCallback(
    ({
      annotations,
      confidenceLevelValue,
      projectViewMode,
      isComparisonMode,
    }: AnnotationFilteringOptions) => {
      return orderBy(
        annotations.filter((annotation) => {
          if (annotation.frameStart !== undefined && annotation.frameEnd !== undefined) {
            const currentFrame = liveUpdate ? frameNumber : getCurrentFrame().rounded

            // Filter out annotations that are not corresponding to the current frame
            if (
              annotation.frameStart > currentFrame ||
              annotation.frameEnd < currentFrame ||
              !isReady
            ) {
              return false
            }
          } else {
            return false
          }

          // Filter out inference annotations if confidence level is set
          if (
            annotation.type === 'inference' &&
            !isNull(confidenceLevelValue) &&
            annotation.confidence &&
            annotation.confidence * 100 < confidenceLevelValue
          ) {
            return false
          }

          if (annotation.type === 'inference' && isImproveViewMode(projectViewMode)) return false

          if (isComparisonMode && annotation.type === 'annotation') return false

          return true
        }),
        (annotation) => Math.abs(annotation.width * annotation.height),
        'desc'
      )
    },
    [frameNumber, getCurrentFrame, isReady, liveUpdate]
  )
}
