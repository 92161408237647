import React, { useState, useMemo } from 'react'
import { Card, Icon, Tag, ProgressBar, TextInput, Typography, Button } from '@htaic/cue'
import type { FoiAnnotationProgress } from '@training/types'
import { useTestingHelper } from '@training/hooks/useTestingHelper'
import { usePopper } from '@training/hooks/usePopper'
import { twMerge } from 'tailwind-merge'
import { useAppState } from '@training/hooks/useAppState'
import { clipStatusLabel, isImproveViewMode, tagClasses } from '@training/constants'

const ClipCard = ({
  clipName,
  annotationCount,
  active,
  onSelect,
  onNameChange,
  onNameBlur,
  isUploading = false,
  uploadingProgress = 0,
  onDelete,
  isProcessing,
  status,
  foiProgress,
}: {
  clipName: string
  annotationCount: number
  active: boolean
  onSelect?: () => void
  onNameChange?: React.ComponentProps<typeof TextInput>['onChange']
  onNameBlur?: React.ComponentProps<typeof TextInput>['onBlur']
  onDelete?: () => void
  isUploading?: boolean
  uploadingProgress?: number
  isProcessing?: boolean
  status?: (typeof clipStatusLabel)[keyof typeof clipStatusLabel]
  foiProgress?: FoiAnnotationProgress
}) => {
  const { getTestIdProps } = useTestingHelper('clip-card')
  const [titleEditable, setTitleEditable] = useState(false)
  const projectViewMode = useAppState((state) => state.projectViewMode)

  const setPopper = usePopper((state) => state.setPopper)

  const onConfirmDelete: React.ComponentProps<'button'>['onClick'] = (event) => {
    event.stopPropagation()

    if (isProcessing) {
      setPopper({
        anchorEl: event.currentTarget,
        content: (
          <div className='flex flex-col gap-2'>
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              Your AI model is being applied to this clip. <br />
              Please wait or choose another clip.
            </Typography>
          </div>
        ),
        id: 'processing-clip',
      })
      return
    }

    if (annotationCount > 0) {
      setPopper({
        anchorEl: event.currentTarget,
        content: (
          <div className='flex flex-col gap-2'>
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              This clip cannot be deleted as it has been used to create annotations <br />
              If the annotations have not been trained into a model, you can delete annotations
              first.
            </Typography>
          </div>
        ),
        id: 'cannot-delete-clip',
        className: 'w-[400px]',
      })

      return
    }

    setPopper({
      anchorEl: event.currentTarget,
      content: (
        <div className='flex flex-col'>
          <Typography
            variant='body2'
            font='secondary'
            className='text-white text-xs text-center mb-3 px-8'
          >
            Do you really want to delete this clip?
          </Typography>
          <div className='flex grow w-full'>
            <Button
              color='primary'
              className='w-full mr-2'
              data-testid='delete-popover-confirm-button'
              onClick={() => {
                onDelete?.()
                setPopper(null)
              }}
            >
              Yes
            </Button>
            <Button
              color='tertiary'
              className='w-full'
              data-testid='delete-popover-cancel-button'
              onClick={() => setPopper(null)}
            >
              No
            </Button>
          </div>
        </div>
      ),
      id: 'delete-clip',
    })
  }

  const onSelectClip: React.ComponentProps<typeof Card>['onClick'] = (event) => {
    event.stopPropagation()

    if (isProcessing) {
      setPopper({
        anchorEl: event.currentTarget,
        content: (
          <div className='flex flex-col gap-2'>
            <Typography variant='body2' font='secondary' className='text-white text-xs'>
              Your AI model is being applied to this clip. <br />
              Please wait or choose another clip.
            </Typography>
          </div>
        ),
        id: 'verification-label-status',
      })
      return
    }

    onSelect?.()
  }

  // Styling
  const tagStatusClassKey = useMemo(() => {
    if (status === 'processing') return status
    if (status === clipStatusLabel.error) return 'partial'
    if (!foiProgress?.annotated) return 'active'
    if (foiProgress?.annotated === foiProgress?.total) return 'done'
    return 'partial'
  }, [foiProgress?.annotated, foiProgress?.total, status])

  // Wording
  const statusLabel =
    isImproveViewMode(projectViewMode) && foiProgress?.total ? foiProgress?.text : status

  const cardActions = [
    <button
      type='button'
      onClick={(e) => {
        e.stopPropagation()
        setTitleEditable(!titleEditable)
      }}
      title='Edit clip name'
      className='border-0 bg-transparent p-0'
      {...getTestIdProps('edit-clip-button')}
      disabled={isUploading}
    >
      <Icon name='Edit' size='small' className='text-icon-default' />
    </button>,
    <button
      type='button'
      onClick={onConfirmDelete}
      title='Delete clip'
      className='border-0 bg-transparent p-0'
      {...getTestIdProps('delete-clip-button')}
      disabled={isUploading}
    >
      <Icon name='Delete' size='small' className='text-icon-default' />
    </button>,
  ]

  return (
    <Card
      className={
        'relative overflow-hidden text-neutral-grey-1 border border-solid dark:bg-neutral-grey-13 dark:border-neutral-grey-12 dark:hover:bg-neutral-grey-12 dark:hover:border-neutral-grey-10 bg-neutral-grey-16 border-neutral-grey-14 hover:bg-neutral-grey-15 hover:border-neutral-grey-14'
      }
      {...getTestIdProps('container')}
      onClick={onSelectClip}
      title={clipName}
      actions={cardActions}
      selected={active}
      hovable
      border='outlined'
      titleEditable={titleEditable}
      titleMaxLength={50}
      radius='xlarge'
      spacing='normal'
      onTitleChange={onNameChange}
      onTitleBlur={(e) => {
        e.stopPropagation()
        setTitleEditable(false)
        onNameBlur?.(e)
      }}
    >
      {isUploading && <ProgressBar value={uploadingProgress} className='w-52 mt-1' />}
      {!isUploading && (
        <div className='flex items-center'>
          <Tag
            data-testid='clip-card-tag'
            label={statusLabel}
            size='large'
            className={twMerge(
              'capitalize',
              'bg-transparent border-solid border',
              tagClasses?.[tagStatusClassKey]
            )}
          />
        </div>
      )}
    </Card>
  )
}

export default ClipCard
