import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type VideoType = 'current' | 'candidate'

export interface VideoAnnotation {
  x: number
  y: number
  width: number
  height: number
  frameStart?: number
  frameEnd?: number
  confidence?: number
  timelineInSeconds?: number | null
  type?: 'inference' | 'annotation' | 'incorrect'
  annotationId?: string
  id: string
}

export interface ModifiedAnnotation {
  item: VideoAnnotation
  type: 'add' | 'update' | 'delete'
}

interface VideoAnnotationStore {
  videoType: VideoType
  loadInitialAnnotations: () => void
  annotations: Map<string, VideoAnnotation>
  setAnnotations: (annotations: VideoAnnotation[]) => void
  addAnnotation: (annotation: VideoAnnotation) => void
  removeAnnotation: (id: string) => void
  updateAnnotation: (updatedAnnotation: VideoAnnotation) => void
  // properties for autosave feature
  modifiedAnnotations: Map<string, ModifiedAnnotation>
  clearModifiedAnnotations: () => void
  annotationsToTighten: VideoAnnotation[]
  setAnnotationsToTighten: (annotationsToTighten: VideoAnnotation[]) => void
  clearAnnotationsToTighten: () => void
  setLoadInitialAnnotations: (loadInitialAnnotations: () => void) => void
}

const createVideoAnnotationsStore = (videoType: VideoType) => {
  return create<VideoAnnotationStore>()(
    devtools(
      (set, get) => ({
        videoType,
        annotations: new Map(),
        setAnnotations: (annotations) =>
          set(() => {
            const annotationsMap = new Map()
            if (annotations.length === 0) return { annotations: annotationsMap }

            annotations.forEach((annotation) => {
              annotationsMap.set(annotation.id, annotation)
            })

            return { annotations: annotationsMap }
          }),

        addAnnotation: (annotation) => {
          const state = get()

          const annotations = new Map(state.annotations).set(annotation.id, annotation)

          const modifiedAnnotations = new Map(state.modifiedAnnotations).set(annotation.id, {
            item: { ...annotation },
            type: 'add',
          })

          set(() => {
            return { annotations, modifiedAnnotations }
          })
        },
        removeAnnotation: (id) => {
          const state = get()

          const isNewAnnotation = state.modifiedAnnotations.get(id)?.type === 'add'

          const modifiedAnnotations = new Map(state.modifiedAnnotations)

          if (isNewAnnotation) {
            modifiedAnnotations.delete(id)
          } else {
            const annotation = state.annotations.get(id) as VideoAnnotation

            if (!annotation) return

            modifiedAnnotations.set(id, {
              item: { ...annotation },
              type: 'delete',
            })
          }

          const annotations = new Map(state.annotations)
          annotations.delete(id)

          set(() => {
            return { modifiedAnnotations, annotations }
          })
        },
        updateAnnotation: (updatedAnnotation) => {
          const state = get()

          // check if annotation is new, if so, keep type add
          const isNewAnnotation =
            state.modifiedAnnotations.get(updatedAnnotation.id)?.type === 'add'

          const annotations = new Map(state.annotations).set(
            updatedAnnotation.id,
            updatedAnnotation
          )

          const modifiedAnnotations = new Map(state.modifiedAnnotations).set(updatedAnnotation.id, {
            item: { ...updatedAnnotation },
            type: isNewAnnotation ? 'add' : 'update',
          })

          set(() => {
            return { annotations, modifiedAnnotations }
          })
        },
        modifiedAnnotations: new Map(),
        clearModifiedAnnotations: () =>
          set(() => {
            const modifiedAnnotations = new Map()

            return { modifiedAnnotations }
          }),
        // Tighten
        annotationsToTighten: [],
        setAnnotationsToTighten: (annotationsToTighten) => {
          // don't add placeholder annotation that are used for the Object Not Present feature
          const validAnnotations = annotationsToTighten.filter(
            (annotation) => annotation.width > 0 && annotation.height > 0
          )

          set(() => {
            return { annotationsToTighten: validAnnotations }
          })
        },
        clearAnnotationsToTighten: () => {
          const state = get()
          if (state.annotationsToTighten.length === 0) return

          set(() => {
            return { annotationsToTighten: [] }
          })
        },
        setLoadInitialAnnotations: (loadInitialAnnotations) =>
          set(() => {
            return { loadInitialAnnotations }
          }),

        loadInitialAnnotations: () => {
          const state = get()

          if (state.loadInitialAnnotations) {
            state.loadInitialAnnotations()
          }
        },
      }),
      {
        serialize: {
          options: {
            map: true,
          },
        },
      }
    )
  )
}

const stores = {
  current: createVideoAnnotationsStore('current'),
  candidate: createVideoAnnotationsStore('candidate'),
}

export const useVideoAnnotations = (videoType: VideoType = 'candidate') => {
  return stores[videoType]
}

interface SaveVideoAnnotationsStore {
  requestSave: boolean
  // used to force save even if there are no modified annotations
  forceSave?: boolean
  triggerSave: (trigger: boolean, force?: boolean) => void
}

export const useSaveVideoAnnotations = create<SaveVideoAnnotationsStore>((set) => ({
  requestSave: false,
  forceSave: false,
  triggerSave: (trigger, force) => set({ requestSave: trigger, forceSave: force }),
}))
