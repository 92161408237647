import { useSnackbar } from '@htaic/cue'
import { useProjectTraining } from '@training/apis/projects/requests'
import { useCallback } from 'react'

export const useTraining = (projectVersionId: string) => {
  const showSnackBar = useSnackbar()
  const projectTrainingMutation = useProjectTraining(projectVersionId ?? '')

  const handleStartTraining = useCallback(async () => {
    try {
      await projectTrainingMutation.mutateAsync({ action: 'start' })
    } catch (error: any) {
      showSnackBar({ message: error?.response?.data?.message || 'Error occurred', status: 'error' })
    }
  }, [projectTrainingMutation])

  return {
    handleStartTraining,
  }
}
