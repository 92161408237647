import { Card, useSnackbar, Typography, Icon } from '@htaic/cue'
import React, { useState, useRef, useEffect, ChangeEvent, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useFileUpload } from '@training/apis/videoFiles/requests'
import { VideoUploadPayload } from '@training/apis/types'
import { useTestingHelper } from '@training/hooks/useTestingHelper'
import { DatasetType, VideoFile } from '@training/types'
import { twMerge } from 'tailwind-merge'
import { usePopper } from '@training/hooks/usePopper'
import { useFeatureFlags } from '@training/hooks/useFeatureFlags'
import { useMinimeState } from '@training/hooks/useMinimeState'
import { appendExtensionToFileName, checkAndRenameFile } from '../../utils/fileUploadUtils'

interface FileUploadModalProps {
  accept?: string
  isTrained: boolean
  onUploadStarted: (fileName: string) => void
  onUploadProgress: (uploadProgress: number, name: string) => void
  onUploadFinished: (videoFile?: VideoFile) => void
  onVMSModalOpen?: () => void
  // for deduping file names
  clipNames: { name: string }[]
}

const FileUpload: React.FC<FileUploadModalProps> = ({
  accept,
  isTrained,
  onUploadProgress,
  onUploadFinished,
  onUploadStarted,
  onVMSModalOpen,
  clipNames,
}) => {
  const { getTestIdProps } = useTestingHelper('file-upload')
  const { id } = useParams()

  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  const mutationFileUpload = useFileUpload()
  const [files, setFiles] = useState<File[]>([])
  const [isDragging, setIsDragging] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [isDisabled, setIsDisabled] = useState(false)

  const allowMultipleUpload = useFeatureFlags().ALLOW_MULTIPLE_CLIP_UPLOAD
  const showSnackBar = useSnackbar()

  useEffect(() => {
    const resetInput = () => {
      if (inputFileRef.current) {
        inputFileRef.current.value = ''
      }
    }

    resetInput()
  }, [files])

  const uploadFiles = async (filesToUpload: File[]) => {
    await Promise.allSettled(
      filesToUpload.map(async (currentFile) => {
        const newFileName = checkAndRenameFile(clipNames, currentFile.name)
        const newFileNameWithExtension = appendExtensionToFileName(newFileName, currentFile.type)
        const file = new File([currentFile], newFileNameWithExtension, { type: currentFile.type })

        onUploadStarted(newFileName)
        setIsDisabled(true)
        const filePayload = {
          organizationId: orgId,
          projectId: id ?? '',
          createdBy: userId,
          datasetType: isTrained ? DatasetType.VALIDATION : DatasetType.ANNOTATION,
          file,
          progressCallback: (progress) => {
            if (progress.loaded && progress.total) {
              const loaded = progress.loaded ?? 0
              const total = progress.total ?? 0
              const currentProgress = Math.round((loaded * 100) / total)
              onUploadProgress(currentProgress, newFileName)
            }
          },
        } satisfies VideoUploadPayload
        try {
          const videoFile = await mutationFileUpload.mutateAsync(filePayload)

          showSnackBar({
            message: `${filePayload.file.name} has finished uploading`,
            status: 'success',
          })
          onUploadFinished(videoFile)
          setIsDisabled(false)
        } catch (error) {
          console.error('Error uploading video file', error)
          showSnackBar({
            message: `${filePayload.file.name} failed to upload. Please try again.`,
            status: 'error',
          })
        }

        setIsDragging(false)
      })
    )
  }

  const validateFiles = (selectedFiles: File[]) => {
    if (!selectedFiles || selectedFiles.length === 0) {
      return 'Please select a file.'
    }

    if (!allowMultipleUpload && selectedFiles.length > 1) {
      return 'Please drag and drop 1 video at a time.'
    }

    if (selectedFiles.some((file) => !file.type.startsWith('video/'))) {
      return 'Only video files allowed.'
    }

    if (selectedFiles.some((file) => !file.type.endsWith('mp4'))) {
      return 'File type not supported. Please upload a .mp4 file.'
    }

    return ''
  }

  const validateFileSize = (selectedFiles: File[]) => {
    const validFiles = selectedFiles.filter((file) => {
      const fileSizeInMB = Math.floor(file.size / 1000000)
      if (fileSizeInMB >= 500) {
        showSnackBar({
          message: `${file.name} is too big to be uploaded. Select a file <500 MB instead.`,
          status: 'error',
        })
        return false
      }
      return true
    })
    return validFiles
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const droppedFiles = Array.from(event.dataTransfer.files || [])
    const validationError = validateFiles(droppedFiles)
    const filteredFiles = validateFileSize(droppedFiles)

    if (validationError) {
      showSnackBar({ message: validationError, status: 'error' })
      return
    }

    if (filteredFiles.length === 0) {
      return
    }

    setFiles(filteredFiles)
    uploadFiles(filteredFiles)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files ?? [])
    const validationError = validateFiles(selectedFiles)
    const filteredFiles = validateFileSize(selectedFiles)

    if (validationError) {
      showSnackBar({ message: validationError, status: 'error' })
      return
    }
    setFiles(filteredFiles)
    uploadFiles(filteredFiles)
  }

  const setPopper = usePopper((state) => state.setPopper)
  const popper = usePopper((state) => state.popper)

  const showUploadingWarning = useCallback(
    (e: React.BaseSyntheticEvent) => {
      if (!isDisabled) return

      e.stopPropagation()

      setPopper({
        anchorEl: e.currentTarget,
        content: (
          <Typography variant='body2' font='secondary' className='text-white text-xs p-2'>
            Please wait for the current upload to finish, or select a clip from WaveSync
          </Typography>
        ),
        id: 'uploading-warning',
        closable: false,
        placement: 'bottom',
        className: 'w-60 z-[1001] p-2',
      })
    },
    [isDisabled, setPopper]
  )

  useEffect(() => {
    if (!isDisabled && popper?.id === 'uploading-warning') {
      setPopper(null)
    }
  }, [isDisabled, popper, setPopper])

  return (
    <Card
      className={twMerge(
        `py-2.5 pt-2 px-6 dark:outline-semantic-secondary outline-neutral-grey-12 outline-1 outline-dashed rounded-xl bg-neutral-grey-15 dark:bg-transparent`,
        isDragging ? 'bg-neutral-grey-14' : ''
      )}
      hovable={false}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      radius='large'
      contentCentered
      {...getTestIdProps('container')}
    >
      <div className='flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <Icon name='Add' size='large' className='mb-1.5 text-icon-default' />
          <Typography
            variant='caption'
            className='text-center text-neutral-grey-9 [text-wrap:balance] dark:text-semantic-secondary'
          >
            Drag MP4 clips here. Max file size: 500 MB
            <br />
            Or browse
            <label
              className='px-1 pb-4 font-bold text-primary-500 cursor-pointer'
              htmlFor='file-input'
              onMouseEnter={showUploadingWarning}
              onMouseLeave={() => {
                if (isDisabled && popper?.id === 'uploading-warning') {
                  setPopper(null)
                }
              }}
            >
              Hard Drive
              <input
                id='file-input'
                name='file-input'
                ref={inputFileRef}
                type='file'
                className='hidden'
                accept={accept}
                onChange={handleFileSelect}
                disabled={isDisabled}
                multiple={allowMultipleUpload}
                {...getTestIdProps('input')}
              />
            </label>
            or
            <button
              type='button'
              className='bg-transparent border-none cursor-pointer'
              onClick={onVMSModalOpen}
            >
              <Typography tag='span' variant='caption' className='text-primary-500 font-bold'>
                VMS
              </Typography>
            </button>
          </Typography>
        </div>
      </div>
    </Card>
  )
}

export default FileUpload
