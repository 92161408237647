import { Button, Typography, ModalContent, ModalHeader, ModalFooter } from '@htaic/cue'
import React from 'react'

interface ConfirmModalProps {
  id: string
  closable?: boolean
  title: string
  children?: React.JSX.Element
  confirmLabel: string
  cancelLabel: string
  onCancel: () => void
  onConfirm: () => void
}
export const ConfirmModal = ({
  id,
  closable = true,
  title,
  children,
  confirmLabel,
  cancelLabel = 'Cancel',
  onCancel,
  onConfirm,
}: ConfirmModalProps) => (
  <ModalContent id={`modal-${id}`} data-testid='modal-confirm'>
    <ModalHeader closable={closable}>{title}</ModalHeader>
    <Typography variant='body2'>{children}</Typography>
    <ModalFooter className='flex justify-between'>
      <Button
        color='secondary'
        aria-label='Cancel and close'
        data-testid='modal-cancel-button'
        onClick={onCancel}
      >
        {cancelLabel}
      </Button>
      <Button
        color='primary'
        variant='contained'
        aria-label={confirmLabel}
        size='small'
        onClick={onConfirm}
        data-testid='modal-confirm-button'
      >
        {confirmLabel}
      </Button>
    </ModalFooter>
  </ModalContent>
)
