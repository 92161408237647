import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { endpoints } from '@training/constants'
import axios from 'axios'
import { useMinimeState } from '@training/hooks/useMinimeState'
import type {
  GetOnCloudClipsResponse,
  GetOnCloudTagsResponse,
  PostOnCloudMediaPayload,
  PostOnCloudResponse,
} from '../types'

export const onCloudApiUrl = endpoints.VITE_ONCLOUD_API

export function useGetOncloudClips(
  {
    orgId,
    size,
    deviceId,
    updatedDate,
    page,
    locationId,
    tagId,
  }: {
    orgId: string
    size?: number
    deviceId?: string
    updatedDate?: string
    page?: number
    locationId?: string
    tagId?: string
  },
  config?: { enabled: boolean }
) {
  const getData = async () => {
    const { data } = await axios.get<GetOnCloudClipsResponse>(`${onCloudApiUrl}/clips`, {
      params: { orgId, size, deviceId, updatedDate, page, locationId, tagId },
    })
    return data
  }

  return useQuery({
    queryKey: ['getOncloudClips', orgId, deviceId, size, page, locationId],
    queryFn: () => getData(),
    enabled: !!orgId && config?.enabled,
    gcTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    placeholderData: keepPreviousData,
  })
}

export const usePostOnCloudMedia = () => {
  const orgId = useMinimeState((state) => state.orgId)
  const userId = useMinimeState((state) => state.userId)

  return useMutation({
    mutationKey: ['postOnCloudMedia', orgId, userId],
    mutationFn: async (payload: PostOnCloudMediaPayload) => {
      const { data } = await axios.post<PostOnCloudResponse>(`${onCloudApiUrl}/clips/media`, {
        orgId,
        url: payload.url,
        userId,
        projectId: payload.projectId,
      } satisfies typeof payload)
      return data
    },
  })
}

export const useGetOnCloudTags = () => {
  const orgId = useMinimeState((state) => state.orgId)

  return useQuery({
    queryKey: ['getOnCloudTags', orgId],
    queryFn: async () => {
      const { data } = await axios.get<GetOnCloudTagsResponse>(`${onCloudApiUrl}/v1/tags`, {
        params: { orgId },
      })
      return data
    },
    enabled: !!orgId,
    staleTime: 1000 * 60 * 1,
    gcTime: 1000 * 60 * 5,
  })
}
