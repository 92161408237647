import { PROJECT_COMPILED_STATUS, PROJECT_STATUS, VIDEO_STATUS } from './constants'

export interface ProjectVersion {
  id: string
  projectId: string
  createdAt: string
  updatedAt: string
  status: ProjectStatus
  compiledStatus: ProjectCompiledStatus
  isRecompile: boolean
  version: number
  objClasses: ObjectClasses[]
}

export interface ObjectClasses {
  id: string
  metadataId?: string
  objectClass: {
    name: string
  }
}
export interface Project {
  id: string
  organizationId: string
  name: string
  notes: string
  createdBy: string
  updatedBy: string
  createdAt: string
  updatedAt: string
  status: string
  version: ProjectVersion[]
}

export interface UpdateProject {
  id: string
  name: string
  objectClassName?: string
  notes?: string
  updatedBy: string
  orgId: string
}

export interface CreateProject {
  name: string
  objectClassName?: string
  notes?: string
  createdBy: string
  orgId: string
}

export interface ReferencePoint {
  id: string
  timelineInSeconds: number
  createdBy?: string | null
  createdAt?: string
  updatedAt?: string
}

type VerificationLabelStatus = 'init' | 'active' | 'error'
type VerificationLabelRecency = 'current' | 'previous'

export interface VerificationLabel {
  id: string
  url: string
  status: VerificationLabelStatus
  projectVersionId: string
  recency: VerificationLabelRecency
}

export interface FoiAnnotationProgress {
  annotated: number
  total: number
  text?: string
}
export interface VideoFile {
  id: string
  name: string
  createdAt: string
  createdBy: string
  datasetType: string
  deleted: boolean
  fileSize: number
  width: number
  height: number
  frameRate: number
  imageFiles: unknown[]
  mediaType: string
  streamUrl: string
  status: VideoFileStatus
  storageFileName: string
  storageRelativePath: string
  originalFileName: string
  __typename: string
  verificationLabels: VerificationLabel[]
  verifiedJsonUrl: string
  prevVerifiedJsonUrl?: string
  annotationCount: number
  scrubbedImageFiles?: Array<{ timeSeconds: number; url: string }>
  foiAnnotationProgress?: FoiAnnotationProgress
}

export interface VideoFileCardData extends VideoFile {
  trained?: boolean
}
export const enum DatasetType {
  ANNOTATION = 'annotation',
  VALIDATION = 'validation',
}

export const enum WaveSyncModalStep {
  SYSTEMSELECTION = 'systemSelection',
  VIDEOSELECTION = 'videoSelection',
}

export type ProjectStatus = keyof typeof PROJECT_STATUS
export type ProjectCompiledStatus = keyof typeof PROJECT_COMPILED_STATUS
export type VideoFileStatus = keyof typeof VIDEO_STATUS

export type EventFor<
  TComponent extends React.ComponentType<any>,
  TProp extends keyof React.ComponentProps<TComponent>,
> = NonNullable<React.ComponentProps<TComponent>[TProp]>

export interface Dimensions {
  width: number
  height: number
}
